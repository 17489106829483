import { useUserData } from "src/utility-features/authorization/UserDataProvider";
import GCAdminIncidentsOverview from "../../component/incident/GCAdminIncidentsOverview";
import withDivisionId, { DivisionProps } from "../../utils/withDivisionId";

const GCDivisionIncidents: React.FC<DivisionProps> = ({ divisionId }) => {
  const { userData } = useUserData();
  const emp = userData.employee;
  if (!emp) throw new Error("Employee not found ");
  return (
    <GCAdminIncidentsOverview
      organizationLevel="division"
      organizationId={divisionId}
      severityUserFilter={{ division_id: { _eq: divisionId } }}
      toInsertWith={{
        gc_id: emp.general_contractor.id,
        division_id: divisionId,
      }}
    />
  );
};
export default withDivisionId(GCDivisionIncidents);
