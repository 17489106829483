/**
 * @generated SignedSource<<b84fc5455d951c889e5447306fa5c3e5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { project_subcontractor_insert_input, project_worker_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GCNewProjectStepSubcontractorsMutation$variables = {
  project_subs: ReadonlyArray<project_subcontractor_insert_input>;
  project_workers: ReadonlyArray<project_worker_insert_input>;
};
export type GCNewProjectStepSubcontractorsMutation$data = {
  readonly insert_project_subcontractor: {
    readonly affected_rows: number;
  } | null | undefined;
  readonly insert_project_worker: {
    readonly affected_rows: number;
  } | null | undefined;
};
export type GCNewProjectStepSubcontractorsMutation = {
  response: GCNewProjectStepSubcontractorsMutation$data;
  variables: GCNewProjectStepSubcontractorsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "project_subs"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "project_workers"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "affected_rows",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "objects",
        "variableName": "project_subs"
      }
    ],
    "concreteType": "project_subcontractor_mutation_response",
    "kind": "LinkedField",
    "name": "insert_project_subcontractor",
    "plural": false,
    "selections": (v1/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "objects",
        "variableName": "project_workers"
      }
    ],
    "concreteType": "project_worker_mutation_response",
    "kind": "LinkedField",
    "name": "insert_project_worker",
    "plural": false,
    "selections": (v1/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GCNewProjectStepSubcontractorsMutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GCNewProjectStepSubcontractorsMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "efcfaf6f6bd40bf260896d6bfcf871b6",
    "id": null,
    "metadata": {},
    "name": "GCNewProjectStepSubcontractorsMutation",
    "operationKind": "mutation",
    "text": "mutation GCNewProjectStepSubcontractorsMutation(\n  $project_subs: [project_subcontractor_insert_input!]!\n  $project_workers: [project_worker_insert_input!]!\n) {\n  insert_project_subcontractor(objects: $project_subs) {\n    affected_rows\n  }\n  insert_project_worker(objects: $project_workers) {\n    affected_rows\n  }\n}\n"
  }
};
})();

(node as any).hash = "adb83a70c872f29c889e8666c453e0a0";

export default node;
