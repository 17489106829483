import withOfficeId, { OfficeProps } from "../../utils/withOfficeId";
import GCAdminObservations from "../../component/observation/GCAdminObservations";
const GCOfficeObservations: React.FC<OfficeProps> = ({ officeId }) => {
  const projWhere = { gc_office_id: { _eq: officeId } };
  return (
    <GCAdminObservations
      optionsQueryVars={{
        projWhere,
        gcBuWhere: { id: { _is_null: true } },
        gcOfficeWhere: { id: { _is_null: true } },
        gcDivisionWhere: { id: { _is_null: true } },
      }}
      projWhere={projWhere}
      organizationId={officeId}
      organizationLevel="office"
    />
  );
};
export default withOfficeId(GCOfficeObservations);
