import { General_Contractor_Employee_Bool_Exp } from "src/common/types/generated/apollo/graphQLTypes";

const getOrgEmployeeWhere = (
  organizationLevel: string,
  organizationId: string,
): General_Contractor_Employee_Bool_Exp => {
  switch (organizationLevel) {
    case "gc":
      return {
        general_contractor_id: { _eq: organizationId },
      };
    case "division":
      return {
        _or: [
          { gc_divisions: { gc_division_id: { _eq: organizationId } } },
          {
            gc_business_units: {
              gc_business_unit: { gc_division_id: { _eq: organizationId } },
            },
          },
          {
            gc_offices: {
              gc_office: { gc_division_id: { _eq: organizationId } },
            },
          },
          {
            employee_projects: {
              project: { gc_division_id: { _eq: organizationId } },
              _or: [
                { direct_project_assign: { _eq: true } },
                { direct_project_assign: { _is_null: true } },
              ],
            },
          },
        ],
      };
    case "business_unit":
      return {
        _or: [
          {
            gc_business_units: { gc_business_unit_id: { _eq: organizationId } },
          },
          {
            gc_offices: {
              gc_office: { gc_business_unit_id: { _eq: organizationId } },
            },
          },
          {
            employee_projects: {
              project: { gc_business_unit_id: { _eq: organizationId } },
              _or: [
                { direct_project_assign: { _eq: true } },
                { direct_project_assign: { _is_null: true } },
              ],
            },
          },
        ],
      };
    case "office":
      return {
        _or: [
          { gc_offices: { gc_office_id: { _eq: organizationId } } },
          {
            employee_projects: {
              project: { gc_office_id: { _eq: organizationId } },
              _or: [
                { direct_project_assign: { _eq: true } },
                { direct_project_assign: { _is_null: true } },
              ],
            },
          },
        ],
      };
    case "project":
      return {
        employee_projects: {
          _or: [
            { direct_project_assign: { _eq: true } },
            { direct_project_assign: { _is_null: true } },
          ],
        },
      };
    default:
      return { uid: { _is_null: true } };
  }
};
export default getOrgEmployeeWhere;
