import { FC, useMemo } from "react";
import { Card } from "antd";
import {
  useUpdateInsuranceClaimMutation,
  Insurance_Claim_Set_Input,
  GetIncidentByPkQuery,
  Document_Insert_Input,
} from "src/common/types/generated/apollo/graphQLTypes";
import IncidentInputField from "../basic/IncidentInputField";
import IncidentSelectField from "../basic/IncidentSelectField";
import { DeletedDocument } from "../basic/IncidentDocViewer";
import createIncidentPatch from "../../utils/createIncidentPatch";
import IncidentDocViewAndUpload from "../basic/IncidentDocViewAndUpload";
import useUploadIncidentDocuments from "../../utils/useUploadIncidentDocuments";
import useDeleteIncidentDocument from "../../utils/useDeleteIncidentDocument";
import useAuthUser from "src/common/hooks/useAuthUser";

interface InsuranceDetailProps {
  incident: NonNullable<GetIncidentByPkQuery["incident_by_pk"]>;
  injuredUserId: string;
}

const InsuranceDetail: FC<InsuranceDetailProps> = ({
  incident,
  injuredUserId,
}) => {
  const [uploadIncidentDocuments] = useUploadIncidentDocuments();
  const [deleteIncidentDocument] = useDeleteIncidentDocument();

  const insuranceData = useMemo(() => {
    const incidentUserData = incident.injured_users.find(
      (injuredUser) => injuredUser.id === injuredUserId,
    );
    return incidentUserData;
  }, [incident.injured_users])?.injury_detail?.insurance_claim;

  const [updateInsuranceClaim, { loading: updateInsuranceClaimLoading }] =
    useUpdateInsuranceClaimMutation();
  const authUser = useAuthUser();

  const updateInsurance = async (
    key: keyof Insurance_Claim_Set_Input,
    value: string | boolean | number,
    comment: string,
  ) => {
    if (!insuranceData || !insuranceData.id) {
      throw new Error("insurance_detail is missing");
    }

    const updatedInjuredUsers = incident.injured_users.map((injuredUser) => {
      if (
        injuredUser.id === injuredUserId &&
        injuredUser.injury_detail &&
        injuredUser.injury_detail.insurance_claim
      ) {
        return {
          ...injuredUser,
          injury_detail: {
            ...injuredUser.injury_detail,
            insurance_claim: {
              ...injuredUser.injury_detail.insurance_claim,
              [key]: value,
            },
          },
        };
      } else return injuredUser;
    });
    const updatedIncident = { ...incident, injured_users: updatedInjuredUsers };
    const patch = createIncidentPatch(updatedIncident, incident);
    await updateInsuranceClaim({
      variables: {
        id: insuranceData.id,
        _set: { [key]: value },
        objects: {
          patch: patch,
          edited_by_uid: authUser.uid,
          incident_id: incident.id,
          edit_type: "insurance-detail-edit",
          comment: comment,
        },
      },
      optimisticResponse: {
        update_insurance_claim_by_pk: {
          ...insuranceData,
          id: insuranceData.id,
          [key]: value,
        },
        insert_incident_edit: {
          affected_rows: 1,
        },
      },
    });
  };

  const deleteDocument = async (doc: DeletedDocument) => {
    await deleteIncidentDocument({
      doc,
      incidentFieldKey: "insurance_detail",
      incidentUserId: injuredUserId,
      incident,
    });
  };

  const uploadDocument = async (objects: Document_Insert_Input[]) => {
    await uploadIncidentDocuments({
      incident: incident,
      objects: objects,
      incidentFieldKey: "insurance_detail",
      incidentUserId: injuredUserId,
    });
  };

  const selectOptions = useMemo(() => {
    return [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ];
  }, []);

  const userName =
    incident.injured_users.find(
      (injuredUser) => injuredUser.id === injuredUserId,
    )?.user.name ?? "injured user";

  return (
    <div className="top-5 flex flex-col" key={insuranceData?.id}>
      <div className="text-2 mt-1 mb-1 ">Insurance Claim Information</div>
      <Card className="w-4/5">
        <IncidentSelectField
          title="Add Insurance details"
          options={selectOptions}
          value={insuranceData?.insurance_added ?? undefined}
          onChange={async (option) => {
            await updateInsurance(
              "insurance_added",
              option.value,
              `Updated Insurance Detail for user ${userName} - "Add Insurance details" to "${option.label}"`,
            );
          }}
          disabled={updateInsuranceClaimLoading}
        />
      </Card>

      {insuranceData?.insurance_added && (
        <>
          <Card className="w-4/5">
            <IncidentInputField
              label="Last four of Employees SS#"
              onSave={(val) => {
                updateInsurance(
                  "last_four_ss_number",
                  val,
                  `Updated Insurance Detail for user ${userName} - "Last four of Employees SS#" to "${val}"  `,
                );
              }}
              text={insuranceData?.last_four_ss_number ?? ""}
            />
          </Card>

          <Card className="w-4/5">
            <IncidentInputField
              label="Employer Insurance Policy Details"
              onSave={(val) => {
                updateInsurance(
                  "employer_policy_number",
                  val,
                  `Updated Insurance Detail for user ${userName} - "Employer Insurance Policy Details" to "${val}"`,
                );
              }}
              text={insuranceData?.employer_policy_number ?? ""}
            />
          </Card>

          <Card className="w-4/5">
            <IncidentInputField
              label="Current Weekly Wage"
              onSave={(val) => {
                updateInsurance(
                  "current_weekly_wage",
                  val,
                  `Updated Insurance Detail for user ${userName} - "Current Weekly Wage" to "${val}"`,
                );
              }}
              text={insuranceData?.current_weekly_wage ?? ""}
              key={"weeklyWage"}
            />
          </Card>

          <Card className="w-4/5">
            <IncidentInputField
              label="Hourly Wage"
              onSave={(val) => {
                updateInsurance(
                  "hourly_wage",
                  val,
                  `Updated Insurance Detail for user ${userName} - "Hourly Wage" to "${val}"`,
                );
              }}
              text={insuranceData?.hourly_wage ?? ""}
              key={"hourlyWage"}
            />
          </Card>

          <Card className="w-4/5">
            <IncidentInputField
              label="Hours worked per week"
              onSave={(val) => {
                updateInsurance(
                  "hours_worked_per_week",
                  val,
                  `Updated Insurance Detail for user ${userName} - "Hours worked per week" to "${val}"`,
                );
              }}
              text={insuranceData?.hours_worked_per_week ?? ""}
              key={"hoursPerWeek"}
            />
          </Card>

          <Card className="w-4/5">
            <IncidentSelectField
              options={selectOptions}
              title="Was Salary Continued"
              onChange={async (option) => {
                await updateInsurance(
                  "salary_continued",
                  option.value,
                  `Updated Insurance Detail for user ${userName} - "Was Salary Continued" to "${option.label}"`,
                );
              }}
              value={insuranceData?.salary_continued ?? undefined}
              disabled={updateInsuranceClaimLoading}
            />
          </Card>
          <Card className="w-4/5">
            <IncidentInputField
              label="Employment Status"
              onSave={(val) => {
                updateInsurance(
                  "employment_status",
                  val,
                  `Updated Insurance Detail for user ${userName} - "Employment Status"to "${val}"`,
                );
              }}
              text={insuranceData?.employment_status ?? ""}
              key={"employment"}
            />
          </Card>

          <Card className="w-4/5">
            <IncidentSelectField
              options={selectOptions}
              title="Was Employee Paid in Full on Date of Injury"
              onChange={async (option) => {
                await updateInsurance(
                  "employee_paid_in_full",
                  option.value,
                  `Updated Insurance Detail for user ${userName} - "Was Employee Paid in Full on Date of Injury" to "${option.label}"`,
                );
              }}
              value={insuranceData?.employee_paid_in_full ?? undefined}
              disabled={updateInsuranceClaimLoading}
            />
          </Card>

          <Card className="w-4/5">
            <IncidentSelectField
              options={selectOptions}
              title="Any Prior Workers Comp Claims"
              onChange={async (option) => {
                await updateInsurance(
                  "prior_workers_comp_claims",
                  option.value,
                  `Updated Insurance Detail for user ${userName} - "Any Prior Workers Comp Claims" to "${option.label}"`,
                );
              }}
              value={insuranceData?.prior_workers_comp_claims ?? undefined}
              disabled={updateInsuranceClaimLoading}
            />
          </Card>

          <Card className="w-4/5">
            <IncidentInputField
              label="OSHA Reference #"
              onSave={(val) => {
                updateInsurance(
                  "osha_reference_number",
                  val,
                  `Updated Insurance Detail for user ${userName} - "OSHA Reference #" to "${val}"`,
                );
              }}
              text={insuranceData?.osha_reference_number ?? ""}
              key={"osha"}
            />
          </Card>

          <IncidentDocViewAndUpload
            deleteDocument={deleteDocument}
            documents={insuranceData.attached_files}
            groupId={insuranceData.id}
            uploadDocument={uploadDocument}
            type="incident"
          />
        </>
      )}
    </div>
  );
};

export default InsuranceDetail;
