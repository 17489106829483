/**
 * @generated SignedSource<<4052737ba15b059b6c9a820eca8f26ac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { orientation_result_insert_input, orientation_viewed_slide_insert_input, user_orientation_insert_input, user_orientation_update_column, user_universal_orientation_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useInsertSiteOrientationResultMutation$variables = {
  orientationResultsObjects: ReadonlyArray<orientation_result_insert_input>;
  userUniversalOrientationObjects: ReadonlyArray<user_universal_orientation_insert_input>;
  viewedSlidesObjects: ReadonlyArray<orientation_viewed_slide_insert_input>;
  workerOrientationObjects: ReadonlyArray<user_orientation_insert_input>;
  workerOrientationUpdateColumns: ReadonlyArray<user_orientation_update_column>;
};
export type useInsertSiteOrientationResultMutation$data = {
  readonly insert_orientation_result: {
    readonly affected_rows: number;
  } | null | undefined;
  readonly insert_orientation_viewed_slide: {
    readonly affected_rows: number;
  } | null | undefined;
  readonly insert_user_orientation: {
    readonly returning: ReadonlyArray<{
      readonly id: string;
      readonly orientated_at: string | null | undefined;
      readonly project_id: string;
      readonly user_id: string;
    }>;
  } | null | undefined;
  readonly insert_user_universal_orientation: {
    readonly affected_rows: number;
  } | null | undefined;
};
export type useInsertSiteOrientationResultMutation = {
  response: useInsertSiteOrientationResultMutation$data;
  variables: useInsertSiteOrientationResultMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orientationResultsObjects"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userUniversalOrientationObjects"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewedSlidesObjects"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workerOrientationObjects"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workerOrientationUpdateColumns"
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "affected_rows",
    "storageKey": null
  }
],
v6 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "objects",
        "variableName": "orientationResultsObjects"
      },
      {
        "kind": "Literal",
        "name": "on_conflict",
        "value": {
          "constraint": "orientation_result_pkey",
          "update_columns": [
            "total_slides",
            "viewed_slides",
            "signature_url",
            "status",
            "expired_at",
            "completed_at",
            "group_id",
            "result_inserted_by_uid"
          ]
        }
      }
    ],
    "concreteType": "orientation_result_mutation_response",
    "kind": "LinkedField",
    "name": "insert_orientation_result",
    "plural": false,
    "selections": (v5/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "objects",
        "variableName": "userUniversalOrientationObjects"
      }
    ],
    "concreteType": "user_universal_orientation_mutation_response",
    "kind": "LinkedField",
    "name": "insert_user_universal_orientation",
    "plural": false,
    "selections": (v5/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "objects",
        "variableName": "viewedSlidesObjects"
      },
      {
        "kind": "Literal",
        "name": "on_conflict",
        "value": {
          "constraint": "orientation_viewed_slide_slide_id_user_id_key",
          "update_columns": [
            "created_at"
          ]
        }
      }
    ],
    "concreteType": "orientation_viewed_slide_mutation_response",
    "kind": "LinkedField",
    "name": "insert_orientation_viewed_slide",
    "plural": false,
    "selections": (v5/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "objects",
        "variableName": "workerOrientationObjects"
      },
      {
        "fields": [
          {
            "kind": "Literal",
            "name": "constraint",
            "value": "user_orientation_user_id_project_id_key"
          },
          {
            "kind": "Variable",
            "name": "update_columns",
            "variableName": "workerOrientationUpdateColumns"
          }
        ],
        "kind": "ObjectValue",
        "name": "on_conflict"
      }
    ],
    "concreteType": "user_orientation_mutation_response",
    "kind": "LinkedField",
    "name": "insert_user_orientation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "user_orientation",
        "kind": "LinkedField",
        "name": "returning",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "user_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "project_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "orientated_at",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useInsertSiteOrientationResultMutation",
    "selections": (v6/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "useInsertSiteOrientationResultMutation",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "25af10fd2bb29d7f9c23d61f5fd3f805",
    "id": null,
    "metadata": {},
    "name": "useInsertSiteOrientationResultMutation",
    "operationKind": "mutation",
    "text": "mutation useInsertSiteOrientationResultMutation(\n  $workerOrientationObjects: [user_orientation_insert_input!]!\n  $workerOrientationUpdateColumns: [user_orientation_update_column!]!\n  $userUniversalOrientationObjects: [user_universal_orientation_insert_input!]!\n  $orientationResultsObjects: [orientation_result_insert_input!]!\n  $viewedSlidesObjects: [orientation_viewed_slide_insert_input!]!\n) {\n  insert_orientation_result(objects: $orientationResultsObjects, on_conflict: {constraint: orientation_result_pkey, update_columns: [total_slides, viewed_slides, signature_url, status, expired_at, completed_at, group_id, result_inserted_by_uid]}) {\n    affected_rows\n  }\n  insert_user_universal_orientation(objects: $userUniversalOrientationObjects) {\n    affected_rows\n  }\n  insert_orientation_viewed_slide(objects: $viewedSlidesObjects, on_conflict: {constraint: orientation_viewed_slide_slide_id_user_id_key, update_columns: [created_at]}) {\n    affected_rows\n  }\n  insert_user_orientation(objects: $workerOrientationObjects, on_conflict: {constraint: user_orientation_user_id_project_id_key, update_columns: $workerOrientationUpdateColumns}) {\n    returning {\n      id\n      user_id\n      project_id\n      orientated_at\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5396cbf28c428b729cbe821a11934a1f";

export default node;
