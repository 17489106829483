import React from "react";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import { useUpdateGcOfficeEmployeesMutation } from "src/common/types/generated/apollo/graphQLTypes";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";
import GCHierarchyTeam from "../../component/GCHierarchyTeam";
import useAuthUser from "src/common/hooks/useAuthUser";
import withOfficeId, { OfficeProps } from "../../utils/withOfficeId";
import { useParams } from "react-router-dom";
import getOrgEmployeeWhere from "../../utils/getOrgEmployeeWhere";

const GCOfficeTeam: React.FC<OfficeProps> = ({ officeId }) => {
  const { userData } = useUserData();
  const employee = userData.employee;
  if (!employee) throw new Error("Loggedā in user is not employee");
  const [updateOfficeEmp] = useUpdateGcOfficeEmployeesMutation();
  const authUser = useAuthUser();
  const { section } = useParams();

  return (
    <GCHierarchyTeam
      {...{
        getRemainingGcTeamVariables: {
          projectsToInviteWhere: {
            gc_office_id: { _eq: officeId },
          },
          gcEmployeeWhere: {
            general_contractor_id: { _eq: employee.general_contractor.id },
            ...(section === "admins"
              ? { gc_offices: { gc_office_id: { _eq: officeId } } }
              : getOrgEmployeeWhere("office", officeId)),
          },
        },
        title: "Office Team",
        onInviteSuccess: async (userIds) => {
          await updateOfficeEmp({
            variables: {
              deleteBuEmpWhere: { id: { _is_null: true } },
              deleteOfficeEmpWhere: { id: { _is_null: true } },
              deleteDivEmpWhere: { id: { _is_null: true } },
              deleteProjEmpWhere: { id: { _is_null: true } },
              insertOfficeObjs: userIds.map((u) => ({
                gc_office_id: officeId,
                created_by_uid: authUser.uid,
                user_id: u,
              })),
            },
          });
        },
        isAdmin: (employee) =>
          employee.gc_offices.some(
            (gcOffice) => gcOffice.gc_office_id === officeId,
          ),

        onRemoveFromTeam: async (userId, projects) => {
          await updateOfficeEmp({
            variables: {
              deleteOfficeEmpWhere: {
                user_id: { _eq: userId },
                gc_office_id: { _eq: officeId },
              },
              deleteDivEmpWhere: { id: { _is_null: true } },
              deleteBuEmpWhere: { id: { _is_null: true } },
              deleteProjEmpWhere: {
                project_id: { _in: projects },
                employee_id: { _eq: userId },
                direct_project_assign: { _eq: false },
              },
              insertOfficeObjs: [],
            },
          });
        },
        excludeEmployeeWhere: {
          _or: [
            { is_corporate_admin: { _eq: true } },
            { gc_offices: { gc_office_id: { _eq: officeId } } },
            {
              gc_business_units: {
                gc_business_unit: { gc_offices: { id: { _eq: officeId } } },
              },
            },
            {
              gc_divisions: {
                gc_division: { gc_offices: { id: { _eq: officeId } } },
              },
            },
          ],
        },
      }}
    />
  );
};

export default withCustomSuspense(withOfficeId(GCOfficeTeam));
