import { useSuspenseQuery } from "@apollo/client";
import { Table } from "antd";
import dayjs from "dayjs";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import StyledContent from "src/common/components/layouts/StyledContent";

import {
  Certificates_To_Verify_Select_Column,
  GetWorkersCertificateToVerifyDocument,
  GetWorkersCertificateToVerifyQuery,
  GetWorkersCertificateToVerifyQueryVariables,
} from "src/common/types/generated/apollo/graphQLTypes";

const SFShowWorkerTable: FC = () => {
  const data = useSuspenseQuery<
    GetWorkersCertificateToVerifyQuery,
    GetWorkersCertificateToVerifyQueryVariables
  >(GetWorkersCertificateToVerifyDocument, {
    variables: {
      where: {
        verified_at: { _is_null: true },
        document: { _eq: "certificate" },
        status: { _eq: "submitted" },
      },
      distinct_on: [Certificates_To_Verify_Select_Column.WorkerId],
    },
    fetchPolicy: "network-only",
  });
  const navigate = useNavigate();
  const dataSource:
    | Array<{
        worker: string;
        project_id: string | null | undefined;
        worker_id: string;
        key: string;
        project: string;
        date: string;
      }>
    | undefined = [];
  data.data?.certificates_to_verify.forEach((c) => {
    let obj = {
      worker: c.worker.user.name,
      project_id: c.project_id,
      worker_id: c.worker_id,
      key: c.id,
      project: c.project?.name ?? "",
      date: dayjs(c.created_at).format("MMM DD, YYYY h:mm a"),
    };
    dataSource.push(obj);
  });
  dataSource.sort((a, b) => dayjs(b.date).valueOf() - dayjs(a.date).valueOf());
  const columns = [
    {
      title: "Worker Name",
      key: "worker",
      dataIndex: "worker",
      sorter: (a: any, b: any) =>
        !!a.worker ? a.worker.localeCompare(b.worker) : 1,
    },
    {
      title: "Project Name",
      key: "project",
      dataIndex: "project",
      sorter: (a: any, b: any) =>
        !!a.project ? a.project.localeCompare(b.project) : 1,
    },
    {
      title: "Submitted on",
      key: "date",
      dataIndex: "date",
      sorter: (a: any, b: any) => (!!a.date ? a.date.localeCompare(b.date) : 1),
    },
  ];
  return (
    <>
      <div className="m-1 flex justify-center items-center">
        Certificates Verification Table
      </div>
      <div className="rounded-2">
        <StyledContent>
          <Table
            dataSource={dataSource}
            columns={columns}
            bordered={true}
            pagination={{ pageSize: 20 }}
            onRow={(record) => ({
              onClick: () =>
                navigate(`/sfe/verify/certificate/${record.worker_id}`),
            })}
          />
        </StyledContent>
      </div>
    </>
  );
};
export default SFShowWorkerTable;
