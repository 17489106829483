import {
  useInsertIncidentTypeLinkMutation,
  GetIncidentByPkQuery,
  GetIncidentByPkQueryVariables,
  GetIncidentByPkDocument,
} from "src/common/types/generated/apollo/graphQLTypes";

const useInsertIncidentType = () => {
  const [insertIncidentTypeLink, { loading: insertIncidentTypeLinkLoading }] =
    useInsertIncidentTypeLinkMutation();
  const insertIncidentType = async (
    incident: NonNullable<GetIncidentByPkQuery["incident_by_pk"]>,
    incidentId: string,
    type: string,
  ) => {
    if (
      incident.incident_types.findIndex(
        (incidentType) => incidentType.type_value === type,
      ) === -1
    ) {
      await insertIncidentTypeLink({
        variables: {
          object: { incident_id: incidentId, type_value: type },
        },
        update(cache, result) {
          const resultData = result.data?.insert_incident_type_link_one;
          const incidentTypes = incident.incident_types;
          if (resultData) {
            cache.writeQuery<
              GetIncidentByPkQuery,
              GetIncidentByPkQueryVariables
            >({
              data: {
                __typename: "query_root",
                incident_by_pk: {
                  ...incident,
                  incident_types: [...incidentTypes, resultData],
                },
              },
              query: GetIncidentByPkDocument,
            });
          }
        },
      });
    }
  };
  return [
    insertIncidentType,
    { loading: insertIncidentTypeLinkLoading },
  ] as const;
};

export default useInsertIncidentType;
