import hasApolloErrorCode from "src/utility-features/error-handling/hasApolloErrorCode";

export const PROCORE_ERROR_CODES = {
  INTEGRATION_DISABLED: "procore-integration-disabled",
  SUBCONTRACTOR_SETUP_NOT_DONE: "procore-subcontractor-setup-not-done",
  PROJECT_SETUP_NOT_DONE: "procore-project-setup-not-done",
} as const;

export const isProcoreError = (error: unknown) => {
  return Object.values(PROCORE_ERROR_CODES).some((code) =>
    hasApolloErrorCode(error, code),
  );
};
