import { DatePicker } from "antd";
import { Dayjs } from "dayjs";
import React, { FC } from "react";
import FilterControllerInput from "../FilterControllerInput";
import { StateFunctions } from "./CustomFilterController";

export type CustomRadioFilterProps = {
  title: string;
  removeFilter: () => void;
  onOk: (from: Dayjs | null, to: Dayjs | null) => void;
  initialClose?: boolean;
} & StateFunctions;
const CustomRadioFilter: FC<CustomRadioFilterProps> = ({
  onOk,
  removeFilter,
  title,
  setRemovalFilters,
  setAppliedFilter,
  initialClose,
}) => {
  return (
    <>
      <FilterControllerInput
        title={title}
        initialClose={initialClose}
        removeFilter={() => {
          setAppliedFilter((prev) => {
            return {
              ...prev,
              [title + "%" + "dateRange"]: false,
            };
          });
          removeFilter();
        }}
        component={
          <div className="-m-0.25 w-full flex">
            <DatePicker.RangePicker
              // value={}
              onChange={(val) => {
                if (val) {
                  onOk(...val);
                  setAppliedFilter((prev) => ({
                    ...prev,
                    [title + "%" + "dateRange"]: val,
                  }));
                  setRemovalFilters((prev) => ({
                    ...prev,
                    [title + "%" + "dateRange"]: () => {
                      setAppliedFilter((prev) => {
                        return {
                          ...prev,
                          [title + "%" + "dateRange"]: false,
                        };
                      });

                      removeFilter();
                    },
                  }));
                }
              }}
            />
          </div>
        }
      />
    </>
  );
};
export default CustomRadioFilter;
