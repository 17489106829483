/**
 * @generated SignedSource<<6e6b4657a38ae0c93a627682cd3a35d7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { order_by, worker_certification_bool_exp, worker_certification_order_by } from './types';
export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorkerCertificationTableQuery$variables = {
  after?: string | null | undefined;
  first: number;
  includeSettings: boolean;
  order_by: ReadonlyArray<worker_certification_order_by>;
  where: worker_certification_bool_exp;
};
export type WorkerCertificationTableQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"WorkerCertificationTable_total" | "WorkerCertificationTable_worker_certifications">;
};
export type WorkerCertificationTableQuery = {
  response: WorkerCertificationTableQuery$data;
  variables: WorkerCertificationTableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeSettings"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order_by"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "where"
},
v5 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v6 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v7 = {
  "kind": "Variable",
  "name": "order_by",
  "variableName": "order_by"
},
v8 = {
  "kind": "Variable",
  "name": "where",
  "variableName": "where"
},
v9 = [
  (v8/*: any*/)
],
v10 = [
  (v5/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/),
  (v8/*: any*/)
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "WorkerCertificationTableQuery",
    "selections": [
      {
        "args": [
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "kind": "Variable",
            "name": "includeSettings",
            "variableName": "includeSettings"
          },
          (v7/*: any*/),
          (v8/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "WorkerCertificationTable_worker_certifications"
      },
      {
        "args": (v9/*: any*/),
        "kind": "FragmentSpread",
        "name": "WorkerCertificationTable_total"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "WorkerCertificationTableQuery",
    "selections": [
      {
        "alias": null,
        "args": (v10/*: any*/),
        "concreteType": "worker_certificationConnection",
        "kind": "LinkedField",
        "name": "worker_certification_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "worker_certificationEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "worker_certification",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": "pk",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": "pk",
                    "args": null,
                    "filters": null,
                    "handle": "pk",
                    "key": "",
                    "kind": "ScalarHandle",
                    "name": "id"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "created_at",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "expires_on",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "issued_on",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "privacy_setting_id",
                    "storageKey": null
                  },
                  {
                    "condition": "includeSettings",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "privacy_setting",
                        "kind": "LinkedField",
                        "name": "privacy_setting",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "manager",
                            "storageKey": null
                          },
                          (v11/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "employer",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "supervisor",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "certification",
                    "kind": "LinkedField",
                    "name": "certification",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "order_by",
                        "value": {
                          "description": "desc_nulls_last"
                        }
                      }
                    ],
                    "concreteType": "image",
                    "kind": "LinkedField",
                    "name": "images",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": null
                      },
                      (v11/*: any*/)
                    ],
                    "storageKey": "images(order_by:{\"description\":\"desc_nulls_last\"})"
                  },
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v10/*: any*/),
        "filters": [
          "where",
          "order_by"
        ],
        "handle": "connection",
        "key": "WorkerCertificationTable_worker_certification_connection",
        "kind": "LinkedHandle",
        "name": "worker_certification_connection"
      },
      {
        "alias": "allCertsConnection",
        "args": (v9/*: any*/),
        "concreteType": "worker_certificationConnection",
        "kind": "LinkedField",
        "name": "worker_certification_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "worker_certificationEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "worker_certification",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v11/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "56fe9aa508d6e7602a0d243dcaa8eef3",
    "id": null,
    "metadata": {},
    "name": "WorkerCertificationTableQuery",
    "operationKind": "query",
    "text": "query WorkerCertificationTableQuery(\n  $first: Int!\n  $after: String\n  $where: worker_certification_bool_exp!\n  $includeSettings: Boolean!\n  $order_by: [worker_certification_order_by!]!\n) {\n  ...WorkerCertificationTable_worker_certifications_1ZZXcb\n  ...WorkerCertificationTable_total_3FC4Qo\n}\n\nfragment WorkerCertificationTable_total_3FC4Qo on query_root {\n  allCertsConnection: worker_certification_connection(where: $where) {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n}\n\nfragment WorkerCertificationTable_worker_certifications_1ZZXcb on query_root {\n  worker_certification_connection(first: $first, after: $after, where: $where, order_by: $order_by) {\n    edges {\n      node {\n        pk: id\n        created_at\n        expires_on\n        issued_on\n        privacy_setting_id\n        privacy_setting @include(if: $includeSettings) {\n          manager\n          id\n          employer\n          supervisor\n        }\n        certification {\n          name\n          id\n        }\n        images(order_by: {description: desc_nulls_last}) {\n          url\n          id\n        }\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "05931b0ec09859a654edd52ac5737d1e";

export default node;
