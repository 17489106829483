import { SearchOutlined } from "@ant-design/icons";
import { Button, DatePicker, Input, Space, Table, Tag } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import "src/common/components/tables/colors.css";
import isNullOrUndefined from "src/common/functions/isNullOrUndefined";
import { DailyReportTableAllForSubAdminQuery } from "src/common/types/generated/relay/DailyReportTableAllForSubAdminQuery.graphql";

const query = graphql`
  query DailyReportTableAllForSubAdminQuery($where: daily_work_log_bool_exp!) {
    daily_work_log_connection(where: $where, order_by: { date: desc }) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          date
          submitted_at
          created_at
          project {
            name
          }
          daily_work_log_tags {
            id
            tag {
              name {
                en
                es
                id
                lang
                original
                score
                pt
              }
              color_hex
            }
          }
          daily_work_log_comments {
            text {
              en
              es
              id
              lang
              original
              pt
              score
            }
          }
          worker_hours
          worker_count
          subcontractor {
            name
            id
          }
          daily_weather_observations {
            ground {
              type
              name {
                en
              }
            }
            sky {
              type
              name {
                en
              }
            }
            wind {
              type
              name {
                en
              }
            }
          }
          submitted_at
          created_by_project_user {
            project_worker {
              user {
                name
              }
              title {
                translation {
                  en
                }
              }
            }
            employee {
              user {
                name
              }
            }
          }
          daily_work_log_workers {
            hours
          }
          description {
            en
          }
        }
      }
    }
  }
`;

type WholeDataType = {
  allTags: any;
};

const SearchDropdown = ({ onSubmit }: { onSubmit: (str: string) => void }) => {
  const [inputValue, setInputValue] = useState("");

  const handleSearch = (str: string) => {
    onSubmit(str);
  };

  return (
    <div
      style={{
        padding: 8,
      }}
    >
      <Input
        placeholder="Search..."
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onPressEnter={() => {
          handleSearch(inputValue);
        }}
        style={{ width: 188, marginBottom: 8, display: "block" }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => {
            handleSearch(inputValue);
          }}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button
          onClick={() => {
            setInputValue("");
            handleSearch("");
          }}
          size="small"
          style={{ width: 90 }}
        >
          Clear
        </Button>
      </Space>
    </div>
  );
};

const WholeDate: React.FC<WholeDataType> = (props: any) => {
  return (
    <>
      {props.allTags.map((thisTag: any) => {
        return (
          <Tag
            style={{ marginTop: "9px" }}
            key={thisTag.id}
            color={thisTag.tag.color_hex}
          >
            {thisTag.tag.name.en}
          </Tag>
        );
      })}
    </>
  );
};
const { RangePicker } = DatePicker;
type DailyReportTableAllForSubAdminType = {
  dataSource?: any;
  totalCount?: any;
  projectId?: string;
  subcontractorId: any;
  onRowClick: (params: { date: string } | { id: string }) => void;
};

type RowKeysType = {
  key: string;
  date: any;
  project: string;
  created_By: string;
  workers: number;
  man_hours: number;
  comments: string;
  tags: string | number;
  tagsCount?: number;
  color?: string;
  weather: string | number;
  weatherCount?: number;
};

const DailyReportTableAll: React.FC<DailyReportTableAllForSubAdminType> = ({
  projectId,
  subcontractorId,
  onRowClick,
}) => {
  const [startDate, setStartDate] = useState(
    dayjs("2019-01-01").format("YYYY-MM-DD"),
  );
  const [endDate, setEndDate] = useState(dayjs().format("YYYY-MM-DD"));
  let data: any = useLazyLoadQuery<DailyReportTableAllForSubAdminQuery>(
    query,

    {
      where: {
        ...(projectId ? { project_id: { _eq: projectId } } : {}),
        subcontractor_id: { _eq: subcontractorId },
        date: { _lte: endDate, _gte: startDate },
      },
    },
  );
  data = data["daily_work_log_connection"].edges.filter(
    (log: any) => !!log.node.submitted_at,
  );
  //console.log(data);
  let dataSource: any = [];
  for (let i = 0; i < data.length; i++) {
    let cur: any = data[i];
    let sum = 0;
    for (let i = 0; i < cur.node.daily_work_log_workers.length; i++) {
      sum += cur.node.daily_work_log_workers[i].hours;
    }
    let tagString: any = (
      <WholeDate allTags={cur.node.daily_work_log_tags}></WholeDate>
    );
    const wea = cur.node.daily_weather_observations;
    let curWeather = "";
    let weatherCount = 0;
    if (!isNullOrUndefined(wea[0])) {
      if (!isNullOrUndefined(wea[0].ground)) {
        curWeather += wea[0].ground.type + ": " + wea[0].ground.name.en + ",\n";
        weatherCount += 1;
      }
      if (!isNullOrUndefined(wea[0].sky)) {
        curWeather += wea[0].sky.type + ": " + wea[0].sky.name.en + ",\n";
        weatherCount += 1;
      }
      if (!isNullOrUndefined(wea[0].wind)) {
        curWeather += wea[0].wind.type + ": " + wea[0].wind.name.en;
        weatherCount += 1;
      }
    }

    let newObj: RowKeysType = {
      key: cur.node.pk,
      date: cur.node.date,
      project: cur.node.project.name,
      created_By:
        (cur.node.created_by_project_user.employee ??
          cur.node.created_by_project_user.project_worker)!.user!.name +
        (!!cur.node.created_by_project_user.project_worker?.title?.translation
          .en
          ? ", " +
            cur.node.created_by_project_user.project_worker?.title?.translation
              .en
          : "") +
        ", " +
        dayjs((cur.node.submitted_at ?? cur.node.created_at) as string)
          .tz("America/New_York")
          .format("MMMM Do YYYY, h:mma z"),
      workers: !isNullOrUndefined(cur.node.worker_count)
        ? cur.node.worker_count
        : cur.node.daily_work_log_workers.length,
      man_hours: !isNullOrUndefined(cur.node.worker_hours)
        ? cur.node.worker_hours! * cur.node.worker_count!
        : cur.node.daily_work_log_workers.reduce(
            (sum: any, w: { hours: any }) => sum + w.hours,
            0,
          ),
      comments: cur.node.description.en,
      tags: tagString,
      tagsCount: cur.node.daily_work_log_tags.length,
      weather: curWeather,
      weatherCount: weatherCount,
    };
    dataSource.push(newObj);
  }
  dataSource = dataSource.map((cur: any) => {
    if (cur.tags?.length > 80) {
      cur.tags = cur.tags.substr(0, 80) + "...";
    }
    if (cur.comments?.length > 110) {
      cur.comments = cur.comments.substr(0, 110) + "...";
    }
    return cur;
  });

  const [keySearches, setKeySearches] = useState<{
    [key: string]: string | null;
  }>({});
  dataSource = dataSource.filter((curObj: any) => {
    if (curObj.subcontractor == "Daily Summary Report") {
      return true;
    }
    let isOkay: boolean = true;
    Object.entries(keySearches).map(([key, val]) => {
      if (curObj[key].toLowerCase().indexOf(val?.toLowerCase()) == -1) {
        isOkay = false;
      }
    });
    return isOkay;
  });
  let newData: RowKeysType[] = [];
  let lastDate: string = "",
    tagsCount = 0,
    workersCount = 0,
    manHoursSum = 0,
    weatherCount = 0;
  for (let i = dataSource.length - 1; i >= 0; i--) {
    if (lastDate != dataSource[i].date && i != dataSource.length - 1) {
      let newObj: RowKeysType = {
        key: dataSource[i].key + "a",
        date: lastDate,
        project: "Daily Summary Report",
        created_By: "",
        workers: workersCount,
        man_hours: manHoursSum,
        comments: "",
        tags: tagsCount,
        color: "#ddd",
        weather: weatherCount,
      };
      newData.push(newObj);
      tagsCount = workersCount = manHoursSum = weatherCount = 0;
      lastDate = "";
    }
    tagsCount += dataSource[i].tagsCount;
    workersCount += dataSource[i].workers;
    weatherCount += dataSource[i].weatherCount;
    manHoursSum += dataSource[i].man_hours;
    lastDate = dataSource[i].date;

    newData.push(dataSource[i]);
    dataSource[i].date = "temp";
  }
  if (newData.length != 0) {
    let newObj: RowKeysType = {
      key: "aba",
      date: lastDate,
      project: "Daily Summary Report",
      created_By: "",
      workers: workersCount,
      man_hours: manHoursSum,
      comments: "",
      tags: tagsCount,
      color: "#ddd",
      weather: weatherCount,
    };
    newData.push(newObj);
    newData.reverse();
  }
  let dayNumber: number = 0;
  for (let i = 0; i < newData.length; i++) {
    if (newData[i].project == "Daily Summary Report") {
      dayNumber = 0;
    } else {
      newData[i].date = ++dayNumber;
    }
  }

  const FilterData = (dataIndex: string) => ({
    filterMultiple: true,
    filterDropdown: ({ confirm }: any) => (
      <SearchDropdown
        onSubmit={(str) => {
          confirm();
          setKeySearches({ ...keySearches, [dataIndex]: str });
        }}
      />
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  let columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Project",
      dataIndex: "project",
      key: "project",
      ...FilterData("project"),
      width: "12%",
    },
    {
      title: "Created_By",
      dataIndex: "created_By",
      key: "created_By",
      ...FilterData("created_By"),
    },
    {
      title: "Workers",
      dataIndex: "workers",
      key: "workers",
    },
    {
      title: "Man_hours",
      dataIndex: "man_hours",
      key: "man_hours",
    },
    {
      title: "Comments",
      dataIndex: "comments",
      key: "comments",
      width: "15%",
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      width: "15%",
    },
    {
      title: "Weather",
      dataIndex: "weather",
      key: "weather",
      width: "12%",
    },
  ];
  return (
    <>
      <span
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >
        <RangePicker
          style={{ width: "270px", margin: "12px" }}
          onChange={(e) => {
            if (!isNullOrUndefined(e?.[0]) && !isNullOrUndefined(e?.[1])) {
              setStartDate(dayjs(e?.[0]).format());
              setEndDate(dayjs(e?.[1]).format());
            }
          }}
        />
      </span>
      <Table
        size="large"
        title={() => (
          <h3>
            {"Daily Reports "}
            {!isNullOrUndefined(dataSource.length)
              ? `(${dataSource.length})`
              : ""}
          </h3>
        )}
        columns={columns}
        dataSource={newData}
        onRow={(current) => ({
          onClick: () => {
            if (current.project == "Daily Summary Report") {
              onRowClick({ date: current.date });
            } else {
              onRowClick({ id: current.key });
            }
          },
        })}
        rowClassName={(record) =>
          record.color ? record.color.replace("#", "") : ""
        }
        bordered
        scroll={{ x: 1000 }}
        pagination={false}
      ></Table>
    </>
  );
};

export default DailyReportTableAll;
