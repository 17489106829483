import { Project_Bool_Exp } from "src/common/types/generated/apollo/graphQLTypes";

const getOrgProjectsWhere = (
  organizationLevel: string,
  unitId: string,
): Project_Bool_Exp => {
  switch (organizationLevel) {
    case "gc":
      return {
        general_contractor_id: { _eq: unitId },
      };
    case "division":
      return {
        gc_division_id: { _eq: unitId },
      };

    case "business_unit":
      return {
        gc_business_unit_id: { _eq: unitId },
      };
    case "office":
      return {
        gc_office_id: { _eq: unitId },
      };
    case "project":
      return { id: { _eq: unitId } };
    default:
      throw new Error("proper org level not found");
  }
};
export default getOrgProjectsWhere;
