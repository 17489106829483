/**
 * @generated SignedSource<<264f4ee0c32f9f0e640e792c5ee9be0d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { safety_plan_bool_exp } from './types';
export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SafetyPlanTotalRefetchableQuery$variables = {
  where: safety_plan_bool_exp;
};
export type SafetyPlanTotalRefetchableQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SafetyPlanTable_total">;
};
export type SafetyPlanTotalRefetchableQuery = {
  response: SafetyPlanTotalRefetchableQuery$data;
  variables: SafetyPlanTotalRefetchableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SafetyPlanTotalRefetchableQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "SafetyPlanTable_total"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SafetyPlanTotalRefetchableQuery",
    "selections": [
      {
        "alias": "allsafety_planConnection",
        "args": (v1/*: any*/),
        "concreteType": "safety_planConnection",
        "kind": "LinkedField",
        "name": "safety_plan_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "safety_planEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "safety_plan",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0add20c975b0b42efcabbd7f5e8d2cfe",
    "id": null,
    "metadata": {},
    "name": "SafetyPlanTotalRefetchableQuery",
    "operationKind": "query",
    "text": "query SafetyPlanTotalRefetchableQuery(\n  $where: safety_plan_bool_exp!\n) {\n  ...SafetyPlanTable_total_3FC4Qo\n}\n\nfragment SafetyPlanTable_total_3FC4Qo on query_root {\n  allsafety_planConnection: safety_plan_connection(where: $where) {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "faab2c25e73221578c18e1b31d9f1847";

export default node;
