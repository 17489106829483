/**
 * @generated SignedSource<<3ad24f91ea9fafd0cada70b2e0451d04>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { inspection_instance_qr_code_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type InspectionQREditMutation$variables = {
  newName: string;
  qrId: string;
  toDeleteInstanceQRs?: ReadonlyArray<string> | null | undefined;
  toInsert: ReadonlyArray<inspection_instance_qr_code_insert_input>;
};
export type InspectionQREditMutation$data = {
  readonly delete_inspection_instance_qr_code: {
    readonly returning: ReadonlyArray<{
      readonly id: string;
    }>;
  } | null | undefined;
  readonly insert_inspection_instance_qr_code: {
    readonly affected_rows: number;
  } | null | undefined;
  readonly update_inspection_qr_code_by_pk: {
    readonly id: string;
    readonly inspection_qr_code_instances: ReadonlyArray<{
      readonly id: string;
      readonly inspection_instance: {
        readonly deleted_at: string | null | undefined;
        readonly id: string;
        readonly inspection_results_aggregate: {
          readonly aggregate: {
            readonly count: number;
          } | null | undefined;
        };
        readonly name: {
          readonly en: string;
        };
        readonly pk: string;
      };
    }>;
    readonly name: string;
  } | null | undefined;
};
export type InspectionQREditMutation = {
  response: InspectionQREditMutation$data;
  variables: InspectionQREditMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "newName"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "qrId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "toDeleteInstanceQRs"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "toInsert"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": [
    {
      "fields": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "_in",
              "variableName": "toDeleteInstanceQRs"
            }
          ],
          "kind": "ObjectValue",
          "name": "inspection_instance_id"
        },
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "_eq",
              "variableName": "qrId"
            }
          ],
          "kind": "ObjectValue",
          "name": "inspection_qr_code_id"
        }
      ],
      "kind": "ObjectValue",
      "name": "where"
    }
  ],
  "concreteType": "inspection_instance_qr_code_mutation_response",
  "kind": "LinkedField",
  "name": "delete_inspection_instance_qr_code",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "inspection_instance_qr_code",
      "kind": "LinkedField",
      "name": "returning",
      "plural": true,
      "selections": [
        (v4/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "objects",
      "variableName": "toInsert"
    },
    {
      "kind": "Literal",
      "name": "on_conflict",
      "value": {
        "constraint": "inspection_instance_qr_code_inspection_qr_code_id_inspection_in",
        "update_columns": ([]/*: any*/)
      }
    }
  ],
  "concreteType": "inspection_instance_qr_code_mutation_response",
  "kind": "LinkedField",
  "name": "insert_inspection_instance_qr_code",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "affected_rows",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "newName"
      }
    ],
    "kind": "ObjectValue",
    "name": "_set"
  },
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "qrId"
      }
    ],
    "kind": "ObjectValue",
    "name": "pk_columns"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "inspection_instance": {
        "deleted_at": {
          "_is_null": true
        }
      }
    }
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deleted_at",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "inspection_result_aggregate",
  "kind": "LinkedField",
  "name": "inspection_results_aggregate",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "inspection_result_aggregate_fields",
      "kind": "LinkedField",
      "name": "aggregate",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "en",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "InspectionQREditMutation",
    "selections": [
      (v5/*: any*/),
      (v6/*: any*/),
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "inspection_qr_code",
        "kind": "LinkedField",
        "name": "update_inspection_qr_code_by_pk",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": (v9/*: any*/),
            "concreteType": "inspection_instance_qr_code",
            "kind": "LinkedField",
            "name": "inspection_qr_code_instances",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "inspection_instance",
                "kind": "LinkedField",
                "name": "inspection_instance",
                "plural": false,
                "selections": [
                  {
                    "alias": "pk",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__id_pk",
                    "storageKey": null
                  },
                  (v10/*: any*/),
                  (v4/*: any*/),
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "text_translation",
                    "kind": "LinkedField",
                    "name": "name",
                    "plural": false,
                    "selections": [
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "inspection_qr_code_instances(where:{\"inspection_instance\":{\"deleted_at\":{\"_is_null\":true}}})"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "InspectionQREditMutation",
    "selections": [
      (v5/*: any*/),
      (v6/*: any*/),
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "inspection_qr_code",
        "kind": "LinkedField",
        "name": "update_inspection_qr_code_by_pk",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": (v9/*: any*/),
            "concreteType": "inspection_instance_qr_code",
            "kind": "LinkedField",
            "name": "inspection_qr_code_instances",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "inspection_instance",
                "kind": "LinkedField",
                "name": "inspection_instance",
                "plural": false,
                "selections": [
                  {
                    "alias": "pk",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": "pk",
                    "args": null,
                    "filters": null,
                    "handle": "pk",
                    "key": "",
                    "kind": "ScalarHandle",
                    "name": "id"
                  },
                  (v10/*: any*/),
                  (v4/*: any*/),
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "text_translation",
                    "kind": "LinkedField",
                    "name": "name",
                    "plural": false,
                    "selections": [
                      (v12/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "inspection_qr_code_instances(where:{\"inspection_instance\":{\"deleted_at\":{\"_is_null\":true}}})"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8ba304d50faf6ea315df1fcf634dff2c",
    "id": null,
    "metadata": {},
    "name": "InspectionQREditMutation",
    "operationKind": "mutation",
    "text": "mutation InspectionQREditMutation(\n  $qrId: uuid!\n  $newName: String!\n  $toInsert: [inspection_instance_qr_code_insert_input!]!\n  $toDeleteInstanceQRs: [uuid!]\n) {\n  delete_inspection_instance_qr_code(where: {inspection_qr_code_id: {_eq: $qrId}, inspection_instance_id: {_in: $toDeleteInstanceQRs}}) {\n    returning {\n      id\n    }\n  }\n  insert_inspection_instance_qr_code(objects: $toInsert, on_conflict: {constraint: inspection_instance_qr_code_inspection_qr_code_id_inspection_in, update_columns: []}) {\n    affected_rows\n  }\n  update_inspection_qr_code_by_pk(pk_columns: {id: $qrId}, _set: {name: $newName}) {\n    name\n    id\n    inspection_qr_code_instances(where: {inspection_instance: {deleted_at: {_is_null: true}}}) {\n      id\n      inspection_instance {\n        pk: id\n        deleted_at\n        id\n        inspection_results_aggregate {\n          aggregate {\n            count\n          }\n        }\n        name {\n          en\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5b4116e02de1ac4b13f9bdaa4627da48";

export default node;
