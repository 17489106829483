import GCProjectIncidentDashboard from "../GCProjectIncidentDashboard";
import GcIncidentManagement from "./GcIncidentManagement";
import GCIncidentTabs from "./GCIncidentTabs";

import { Navigate } from "react-router-dom";
import GCProjectIncidentsTable from "./GCProjectIncidentsTable";

const gcIncidentRoute = [
  {
    path: "incidents",
    element: <GCIncidentTabs />,
    children: [
      { path: "", element: <Navigate to="table" /> },
      { path: "charts", element: <GCProjectIncidentDashboard /> },
      {
        path: "table",
        element: <GCProjectIncidentsTable />,
        children: [{ path: ":incidentId", element: <GcIncidentManagement /> }],
      },
    ],
  },
];

export default gcIncidentRoute;
