import { useNavigate } from "react-router-dom";
import React, { FC, useRef, useState } from "react";
import { GCSubcontractorQuery$data } from "src/common/types/generated/relay/GCSubcontractorQuery.graphql";
import { Modal, Select, Table } from "antd";
import StyledContent from "src/common/components/layouts/StyledContent";
import AnonProjectWorkerTable from "src/common/components/tables/AnonProjectWorkerTable";
import ProjectCrewWorkerDetails from "../../components/ProjectCrewWorkerDetails";
import PerformWorkerFunctionsButton from "src/common/components/modals/PerformWorkerFunctionsButton";
import { DataScrollTableRef } from "src/common/components/tables/basic/DataScrollTable";
import { useUpdateProjectWorkerTitleMutation } from "src/common/types/generated/apollo/graphQLTypes";

const GCSubcontractorWorkersAndCrews: FC<{
  data: GCSubcontractorQuery$data;
  subcontractorId: string;
  projectId: string;
}> = ({ data, subcontractorId, projectId }) => {
  const navigate = useNavigate();
  const tableRef = useRef<DataScrollTableRef>(null);
  const removedWorkers = data.prevSubs.edges
    .filter(
      (x) =>
        x?.node?.worker?.worker_projects[0]?.project_id === projectId &&
        x?.node?.worker?.subcontractor_id !== subcontractorId,
    )
    .map((pw) => pw.node);
  const workerTitle = data.worker_title_connection.edges;
  const workerTitleList = workerTitle.map((title) => {
    return { value: title.node.pk, label: title.node.translation.en };
  });

  return (
    <div className="w-full">
      <StyledContent padding backgroundColor="white">
        <ProjectCrewWorkerDetails
          projectId={projectId}
          workersDataKey={data}
          subcontractorId={subcontractorId}
          isGcAccount={true}
        ></ProjectCrewWorkerDetails>
      </StyledContent>
      <StyledContent padding backgroundColor="white">
        <AnonProjectWorkerTable
          editableTitle
          excludedKeys={["subcontractor", "project", "corporateDate"]}
          workerTitleList={workerTitleList}
          headerComponent={
            <PerformWorkerFunctionsButton
              isGC
              type="noData"
              projWorkerWhere={{
                project_id: { _eq: projectId },
                subcontractor_id: { _eq: subcontractorId },
                subcontractor_worker: {},
              }}
              refetch={() => tableRef.current?.refetch()}
            />
          }
          where={{
            project_id: {
              _eq: projectId,
            },
            deleted_at: { _is_null: true },
            subcontractor_id: {
              _eq: subcontractorId,
            },
            subcontractor_worker: {},
          }}
          onRowClick={({ pk }) => {
            navigate(`/gce/projects/${projectId}/workers/worker/${pk}`);
          }}
        />
      </StyledContent>
      {removedWorkers.length > 0 && (
        <StyledContent padding backgroundColor="white">
          <h3 style={{ fontSize: "14px", marginBottom: "5px" }}>
            Removed Workers ({removedWorkers.length})
          </h3>
          <Table
            bordered={true}
            rowKey={(row) => row.pk}
            columns={[
              {
                key: "name",
                dataIndex: ["worker", "user", "name"],
                title: "Name",
              },
              {
                key: "hardhat",
                dataIndex: ["worker", "worker_projects"],
                title: "Hard Hat Number",
                width: "20%",
                render: (v, row) =>
                  row.worker?.worker_projects[0]?.hard_hat_number || "",
              },
              {
                key: "title",
                dataIndex: ["title"],
                title: "Title",
                render: (v, row) =>
                  row.worker?.worker_projects[0]
                    ? row.worker.worker_projects[0].title?.translation?.en
                    : "",
              },
              {
                key: "role",
                dataIndex: "role",
                title: "Role",
                render: (v, row) =>
                  row.worker?.worker_projects[0]
                    ? row.worker.worker_projects[0].worker_role
                    : "",
              },
            ]}
            dataSource={removedWorkers}
            onRow={(row) => {
              return {
                onClick: () => {
                  const project_worker = row.worker
                    ? row.worker.worker_projects[0]
                    : undefined;
                  if (project_worker)
                    navigate(
                      `/gce/projects/${projectId}/workers/worker/${project_worker.pk}`,
                    );
                },
              };
            }}
          />
        </StyledContent>
      )}
    </div>
  );
};

export default GCSubcontractorWorkersAndCrews;
