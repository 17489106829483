import { useMemo, useState } from "react";
import useSelectedIndicies from "src/common/hooks/useSelectedIndices";
import { GetIncidentReportAndChartOptionsQuery } from "src/common/types/generated/apollo/graphQLTypes";
import { GCAdminSelectFilterProps } from "../../filter-inputs/GCAdminSelectFilter";
import dayjs from "dayjs";

const useGetIncidentFilters = (
  options: GetIncidentReportAndChartOptionsQuery | undefined,
) => {
  const [injuryCauseFilter, handleInjuryCauseChange, setInjuryCauseFilter] =
    useSelectedIndicies<string>();
  const [injuryTypesFilter, handleInjuryTypeChange, setInjuryTypesFilter] =
    useSelectedIndicies<string>();
  const [
    incidentTypesFilter,
    handleIncidentTypeChange,
    setIncidentTypesFilter,
  ] = useSelectedIndicies<string>();
  const [bodyPartsFilter, handleBodyPartChange, setBodyPartsFilter] =
    useSelectedIndicies<string>();
  const [severityFilter, handleSeverityChange, setseverityFilter] =
    useSelectedIndicies<string>();
  const [companiesFilter, handleCompaniesChange, setCompaniesFilter] =
    useSelectedIndicies<string>();
  const [dateFilter, setDateFilter] = useState<
    [dayjs.Dayjs | null, dayjs.Dayjs | null] | null
  >(null);

  const selectFilters: GCAdminSelectFilterProps[] = [
    {
      options: (options?.subcontractor || []).map((p) => ({
        value: p.id,
        label: p.name,
      })),
      title: "Companies",
      placeholder: "All Companies Included",
      selectedItems: companiesFilter,
      handleItemChange: handleCompaniesChange,
      setSelectedItems: setCompaniesFilter,
    },
    {
      options: (options?.incident_type || []).map((p) => ({
        value: p.value,
        label: p.translation.en,
      })),
      title: "Incident Types",
      placeholder: "All Incident Types Included",
      selectedItems: incidentTypesFilter,
      handleItemChange: handleIncidentTypeChange,
      setSelectedItems: setIncidentTypesFilter,
    },
    {
      options: (options?.injury_cause || []).map((c) => ({
        value: c.value,
        label: c.translation.en,
      })),
      selectedItems: injuryCauseFilter,
      title: "Injury Causes",
      placeholder: "All Injury Causes Included",
      handleItemChange: handleInjuryCauseChange,
      setSelectedItems: setInjuryCauseFilter,
    },
    {
      options: (options?.injury_type || []).map((c) => ({
        value: c.value,
        label: c.translation.en,
      })),
      selectedItems: injuryTypesFilter,
      handleItemChange: handleInjuryTypeChange,
      title: "Injury Types",
      placeholder: "All Injury Types Included",
      setSelectedItems: setInjuryTypesFilter,
    },
    {
      options: (options?.injury_body_part_affected || []).map((c) => ({
        value: c.value,
        label: c.translation.en,
      })),
      selectedItems: bodyPartsFilter,
      title: "Body Parts Affected",
      placeholder: "All Body Parts Included",
      handleItemChange: handleBodyPartChange,
      setSelectedItems: setBodyPartsFilter,
    },
    {
      options: (options?.incident_severity || []).map((c) => ({
        value: c.id,
        label: c.name.en,
      })),
      selectedItems: severityFilter,
      title: "Injury Classification",
      placeholder: "All Injury Classifications Included",
      handleItemChange: handleSeverityChange,
      setSelectedItems: setseverityFilter,
    },
  ];

  return {
    selectFilters,
    bodyPartsFilter,
    severityFilter,
    injuryTypesFilter,
    injuryCauseFilter,
    incidentTypesFilter,
    companiesFilter,
    dateFilter,
    setDateFilter,
  };
};
export default useGetIncidentFilters;
