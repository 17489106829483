import { useGetIncidentReportAndChartOptionsQuery } from "src/common/types/generated/apollo/graphQLTypes";
import useGetIncidentOptionsQueryVars from "./utils/useGetIncidentOptionsQueryVars";
import { OrganizationLevelName } from "../../utils/hierarchyTypes";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";
import GCIncidentsPage from "src/domain-features/incident-management/entryRoutes/gc/components/GCIncidentsPage";
import getOrgProjectsWhere from "../../utils/getOrgProjectsWhere";

const GCAdminIncidentsTable: React.FC<{
  organizationLevel: OrganizationLevelName;
  organizationId: string;
}> = ({ organizationId, organizationLevel }) => {
  const projWhere = getOrgProjectsWhere(organizationLevel, organizationId);
  const optionsQueryVars = useGetIncidentOptionsQueryVars(
    organizationLevel,
    organizationId,
  );
  const { data: options, error: optionsError } =
    useGetIncidentReportAndChartOptionsQuery({
      variables: optionsQueryVars,
      fetchPolicy: "cache-first",
    });
  if (optionsError) throw optionsError;
  const { userData } = useUserData();
  const officeLevelName =
    userData.employee?.general_contractor.hierarchy_office_name;
  return (
    <GCIncidentsPage
      title="Incidents"
      optionsData={options}
      officeLevelName={officeLevelName}
      where={{ deleted_at: { _is_null: true }, project: projWhere }}
    />
  );
};
export default GCAdminIncidentsTable;
