/**
 * @generated SignedSource<<009e1f9af68a2cd809c507d38658c562>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { auto_email_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SCProjectAutoEmailTables_InsertMutation$variables = {
  objects: ReadonlyArray<auto_email_insert_input>;
};
export type SCProjectAutoEmailTables_InsertMutation$data = {
  readonly insert_auto_email: {
    readonly affected_rows: number;
  } | null | undefined;
};
export type SCProjectAutoEmailTables_InsertMutation = {
  response: SCProjectAutoEmailTables_InsertMutation$data;
  variables: SCProjectAutoEmailTables_InsertMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "objects"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "objects",
        "variableName": "objects"
      },
      {
        "kind": "Literal",
        "name": "on_conflict",
        "value": {
          "constraint": "auto_email_sub_id_event_email_project_id_key",
          "update_columns": [
            "id"
          ]
        }
      }
    ],
    "concreteType": "auto_email_mutation_response",
    "kind": "LinkedField",
    "name": "insert_auto_email",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "affected_rows",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SCProjectAutoEmailTables_InsertMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SCProjectAutoEmailTables_InsertMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "86db2449da671b3ea7fb8c0e30036ae0",
    "id": null,
    "metadata": {},
    "name": "SCProjectAutoEmailTables_InsertMutation",
    "operationKind": "mutation",
    "text": "mutation SCProjectAutoEmailTables_InsertMutation(\n  $objects: [auto_email_insert_input!]!\n) {\n  insert_auto_email(objects: $objects, on_conflict: {constraint: auto_email_sub_id_event_email_project_id_key, update_columns: [id]}) {\n    affected_rows\n  }\n}\n"
  }
};
})();

(node as any).hash = "e9532b0ff8b03ec44d2afb91dc6b428f";

export default node;
