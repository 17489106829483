/**
 * @generated SignedSource<<d96b2bbfe4560439d073d040e79a9e39>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { project_subcontractor_location_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ProjectSubcontractorTableNew_InsertProjSubLocation_Mutation$variables = {
  object: project_subcontractor_location_insert_input;
};
export type ProjectSubcontractorTableNew_InsertProjSubLocation_Mutation$data = {
  readonly insert_project_subcontractor_location_one: {
    readonly id: string;
    readonly is_sub_active: boolean;
    readonly pk: string;
    readonly project_location_id: string;
  } | null | undefined;
};
export type ProjectSubcontractorTableNew_InsertProjSubLocation_Mutation = {
  response: ProjectSubcontractorTableNew_InsertProjSubLocation_Mutation$data;
  variables: ProjectSubcontractorTableNew_InsertProjSubLocation_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "object"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "object",
    "variableName": "object"
  },
  {
    "kind": "Literal",
    "name": "on_conflict",
    "value": {
      "constraint": "project_subcontractor_location_project_location_id_subcontracto",
      "update_columns": [
        "is_sub_active"
      ]
    }
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "project_location_id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "is_sub_active",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectSubcontractorTableNew_InsertProjSubLocation_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "project_subcontractor_location",
        "kind": "LinkedField",
        "name": "insert_project_subcontractor_location_one",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": "pk",
            "args": null,
            "kind": "ScalarField",
            "name": "__id_pk",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProjectSubcontractorTableNew_InsertProjSubLocation_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "project_subcontractor_location",
        "kind": "LinkedField",
        "name": "insert_project_subcontractor_location_one",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": "pk",
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": "pk",
            "args": null,
            "filters": null,
            "handle": "pk",
            "key": "",
            "kind": "ScalarHandle",
            "name": "id"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cf4baf594e81bafae26d4f7375f04d20",
    "id": null,
    "metadata": {},
    "name": "ProjectSubcontractorTableNew_InsertProjSubLocation_Mutation",
    "operationKind": "mutation",
    "text": "mutation ProjectSubcontractorTableNew_InsertProjSubLocation_Mutation(\n  $object: project_subcontractor_location_insert_input!\n) {\n  insert_project_subcontractor_location_one(object: $object, on_conflict: {constraint: project_subcontractor_location_project_location_id_subcontracto, update_columns: [is_sub_active]}) {\n    id\n    project_location_id\n    is_sub_active\n    pk: id\n  }\n}\n"
  }
};
})();

(node as any).hash = "24e6c81ff3bafe0c644d34a60efea4db";

export default node;
