import { useUserData } from "src/utility-features/authorization/UserDataProvider";
import GCAdminIncidentsOverview from "../../component/incident/GCAdminIncidentsOverview";
import withBusinessUnitId, {
  BusinessUnitProps,
} from "../../utils/withBusinessUnitId";

const GCBusinessUnitIncidents: React.FC<BusinessUnitProps> = ({
  businessUnitId,
}) => {
  const { userData } = useUserData();
  return (
    <GCAdminIncidentsOverview
      organizationLevel="business_unit"
      organizationId={businessUnitId}
      severityUserFilter={{ business_unit_id: { _eq: businessUnitId } }}
      toInsertWith={{
        gc_id: userData.employee?.general_contractor.id,
        business_unit_id: businessUnitId,
      }}
    />
  );
};
export default withBusinessUnitId(GCBusinessUnitIncidents);
