/**
 * @generated SignedSource<<18a49fd24c2b974e964f5c3af75cf275>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { gc_disabled_project_subcontractor_bool_exp, gc_disabled_project_subcontractor_order_by, order_by } from './types';
export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GCDisabledProjectSubcontractorTableQuery$variables = {
  after?: string | null | undefined;
  first: number;
  order_by: ReadonlyArray<gc_disabled_project_subcontractor_order_by>;
  where: gc_disabled_project_subcontractor_bool_exp;
};
export type GCDisabledProjectSubcontractorTableQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"GCDisabledProjectSubcontractorTable_total" | "GCDisabledProjectSubcontractorTable_workers">;
};
export type GCDisabledProjectSubcontractorTableQuery = {
  response: GCDisabledProjectSubcontractorTableQuery$data;
  variables: GCDisabledProjectSubcontractorTableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order_by"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "where"
},
v4 = {
  "kind": "Variable",
  "name": "where",
  "variableName": "where"
},
v5 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "order_by",
    "variableName": "order_by"
  },
  (v4/*: any*/)
],
v6 = [
  (v4/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GCDisabledProjectSubcontractorTableQuery",
    "selections": [
      {
        "args": (v5/*: any*/),
        "kind": "FragmentSpread",
        "name": "GCDisabledProjectSubcontractorTable_workers"
      },
      {
        "args": (v6/*: any*/),
        "kind": "FragmentSpread",
        "name": "GCDisabledProjectSubcontractorTable_total"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "GCDisabledProjectSubcontractorTableQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "gc_disabled_project_subcontractorConnection",
        "kind": "LinkedField",
        "name": "gc_disabled_project_subcontractor_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "gc_disabled_project_subcontractorEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "gc_disabled_project_subcontractor",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  {
                    "alias": "pk",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": "pk",
                    "args": null,
                    "filters": null,
                    "handle": "pk",
                    "key": "",
                    "kind": "ScalarHandle",
                    "name": "id"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "project_subcontractor",
                    "kind": "LinkedField",
                    "name": "project_subcontractor",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "subcontractor",
                        "kind": "LinkedField",
                        "name": "subcontractor",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "subcontractor_trade",
                            "kind": "LinkedField",
                            "name": "trade",
                            "plural": false,
                            "selections": [
                              (v8/*: any*/),
                              (v7/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "filters": [
          "where",
          "order_by"
        ],
        "handle": "connection",
        "key": "GCDisabledProjectSubcontractorTable_gc_disabled_project_subcontractor_connection",
        "kind": "LinkedHandle",
        "name": "gc_disabled_project_subcontractor_connection"
      },
      {
        "alias": "allSubsConnection",
        "args": (v6/*: any*/),
        "concreteType": "gc_disabled_project_subcontractorConnection",
        "kind": "LinkedField",
        "name": "gc_disabled_project_subcontractor_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "gc_disabled_project_subcontractorEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "gc_disabled_project_subcontractor",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "908c3da6d3b0373472820878e62d16ec",
    "id": null,
    "metadata": {},
    "name": "GCDisabledProjectSubcontractorTableQuery",
    "operationKind": "query",
    "text": "query GCDisabledProjectSubcontractorTableQuery(\n  $first: Int!\n  $after: String\n  $where: gc_disabled_project_subcontractor_bool_exp!\n  $order_by: [gc_disabled_project_subcontractor_order_by!]!\n) {\n  ...GCDisabledProjectSubcontractorTable_workers_4g04uc\n  ...GCDisabledProjectSubcontractorTable_total_3FC4Qo\n}\n\nfragment GCDisabledProjectSubcontractorTable_total_3FC4Qo on query_root {\n  allSubsConnection: gc_disabled_project_subcontractor_connection(where: $where) {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n}\n\nfragment GCDisabledProjectSubcontractorTable_workers_4g04uc on query_root {\n  gc_disabled_project_subcontractor_connection(first: $first, after: $after, where: $where, order_by: $order_by) {\n    edges {\n      node {\n        id\n        pk: id\n        project_subcontractor {\n          subcontractor {\n            name\n            trade {\n              name\n              id\n            }\n            id\n          }\n          id\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ac30a894eea8e16eb746f6ae69d56858";

export default node;
