/**
 * @generated SignedSource<<3b7ac5d85d008cb6e5de8695c773ddba>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { delivery_bool_exp } from './types';
export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
export type SiteBoardDeliveriesListQuery$variables = {
  projectId: string;
  where: delivery_bool_exp;
};
export type SiteBoardDeliveriesListQuery$data = {
  readonly delivery_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly calendars_connection: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly calendar: {
                readonly color_hex: string;
                readonly id: string;
                readonly name: {
                  readonly original: string;
                };
              };
            };
          }>;
        };
        readonly detail: {
          readonly original: string;
        } | null | undefined;
        readonly duration: number;
        readonly id: string;
        readonly name: {
          readonly original: string;
        };
        readonly pk: string;
        readonly start_at: string;
        readonly user: {
          readonly employee: {
            readonly general_contractor: {
              readonly id: string;
              readonly name: string;
            };
          } | null | undefined;
          readonly name: string;
          readonly role: string;
          readonly subcontractor_employee: {
            readonly subcontractor: {
              readonly id: string;
              readonly name: string;
            };
          } | null | undefined;
          readonly worker: {
            readonly subcontractor: {
              readonly id: string;
              readonly name: string;
            } | null | undefined;
          } | null | undefined;
        };
      };
    }>;
  };
  readonly project_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly timezone: string;
      };
    }>;
  };
};
export type SiteBoardDeliveriesListQuery = {
  response: SiteBoardDeliveriesListQuery$data;
  variables: SiteBoardDeliveriesListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "where"
},
v2 = [
  {
    "kind": "Variable",
    "name": "_eq",
    "variableName": "projectId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": [
    {
      "fields": [
        {
          "fields": (v2/*: any*/),
          "kind": "ObjectValue",
          "name": "id"
        }
      ],
      "kind": "ObjectValue",
      "name": "where"
    }
  ],
  "concreteType": "projectConnection",
  "kind": "LinkedField",
  "name": "project_connection",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "projectEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "project",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "timezone",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "original",
  "storageKey": null
},
v7 = [
  (v6/*: any*/)
],
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "text_translation",
  "kind": "LinkedField",
  "name": "name",
  "plural": false,
  "selections": (v7/*: any*/),
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v11 = [
  (v3/*: any*/),
  (v9/*: any*/)
],
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "general_contractor",
  "kind": "LinkedField",
  "name": "general_contractor",
  "plural": false,
  "selections": (v11/*: any*/),
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "subcontractor",
  "kind": "LinkedField",
  "name": "subcontractor",
  "plural": false,
  "selections": (v11/*: any*/),
  "storageKey": null
},
v14 = [
  (v13/*: any*/)
],
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "start_at",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "duration",
  "storageKey": null
},
v17 = [
  {
    "fields": [
      {
        "fields": [
          {
            "fields": [
              {
                "fields": (v2/*: any*/),
                "kind": "ObjectValue",
                "name": "project_id"
              }
            ],
            "kind": "ObjectValue",
            "name": "projects"
          }
        ],
        "kind": "ObjectValue",
        "name": "calendar"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "color_hex",
  "storageKey": null
},
v19 = [
  (v6/*: any*/),
  (v3/*: any*/)
],
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "text_translation",
  "kind": "LinkedField",
  "name": "name",
  "plural": false,
  "selections": (v19/*: any*/),
  "storageKey": null
},
v21 = [
  (v13/*: any*/),
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SiteBoardDeliveriesListQuery",
    "selections": [
      (v4/*: any*/),
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "deliveryConnection",
        "kind": "LinkedField",
        "name": "delivery_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "deliveryEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "delivery",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": "pk",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__id_pk",
                    "storageKey": null
                  },
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "user",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "general_contractor_employee",
                        "kind": "LinkedField",
                        "name": "employee",
                        "plural": false,
                        "selections": [
                          (v12/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "subcontractor_employee",
                        "kind": "LinkedField",
                        "name": "subcontractor_employee",
                        "plural": false,
                        "selections": (v14/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "worker",
                        "kind": "LinkedField",
                        "name": "worker",
                        "plural": false,
                        "selections": (v14/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "text_translation",
                    "kind": "LinkedField",
                    "name": "detail",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  },
                  (v15/*: any*/),
                  (v16/*: any*/),
                  {
                    "alias": null,
                    "args": (v17/*: any*/),
                    "concreteType": "delivery_calendarConnection",
                    "kind": "LinkedField",
                    "name": "calendars_connection",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "delivery_calendarEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "delivery_calendar",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "calendar",
                                "kind": "LinkedField",
                                "name": "calendar",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  (v18/*: any*/),
                                  (v8/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SiteBoardDeliveriesListQuery",
    "selections": [
      (v4/*: any*/),
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "deliveryConnection",
        "kind": "LinkedField",
        "name": "delivery_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "deliveryEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "delivery",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": "pk",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": "pk",
                    "args": null,
                    "filters": null,
                    "handle": "pk",
                    "key": "",
                    "kind": "ScalarHandle",
                    "name": "id"
                  },
                  (v20/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "user",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v3/*: any*/),
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "general_contractor_employee",
                        "kind": "LinkedField",
                        "name": "employee",
                        "plural": false,
                        "selections": [
                          (v12/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "subcontractor_employee",
                        "kind": "LinkedField",
                        "name": "subcontractor_employee",
                        "plural": false,
                        "selections": (v21/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "worker",
                        "kind": "LinkedField",
                        "name": "worker",
                        "plural": false,
                        "selections": (v21/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "text_translation",
                    "kind": "LinkedField",
                    "name": "detail",
                    "plural": false,
                    "selections": (v19/*: any*/),
                    "storageKey": null
                  },
                  (v15/*: any*/),
                  (v16/*: any*/),
                  {
                    "alias": null,
                    "args": (v17/*: any*/),
                    "concreteType": "delivery_calendarConnection",
                    "kind": "LinkedField",
                    "name": "calendars_connection",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "delivery_calendarEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "delivery_calendar",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "calendar",
                                "kind": "LinkedField",
                                "name": "calendar",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  (v18/*: any*/),
                                  (v20/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "53edb5f892d2c5a366385bc0a4eb6a1c",
    "id": null,
    "metadata": {},
    "name": "SiteBoardDeliveriesListQuery",
    "operationKind": "query",
    "text": "query SiteBoardDeliveriesListQuery(\n  $where: delivery_bool_exp!\n  $projectId: uuid!\n) {\n  project_connection(where: {id: {_eq: $projectId}}) {\n    edges {\n      node {\n        id\n        timezone\n      }\n    }\n  }\n  delivery_connection(where: $where) {\n    edges {\n      node {\n        id\n        pk: id\n        name {\n          original\n          id\n        }\n        user {\n          name\n          id\n          role\n          employee {\n            general_contractor {\n              id\n              name\n            }\n            id\n          }\n          subcontractor_employee {\n            subcontractor {\n              id\n              name\n            }\n            id\n          }\n          worker {\n            subcontractor {\n              id\n              name\n            }\n            id\n          }\n        }\n        detail {\n          original\n          id\n        }\n        start_at\n        duration\n        calendars_connection(where: {calendar: {projects: {project_id: {_eq: $projectId}}}}) {\n          edges {\n            node {\n              calendar {\n                id\n                color_hex\n                name {\n                  original\n                  id\n                }\n              }\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9c8c2919ee2415b30e5e41f69cbe9289";

export default node;
