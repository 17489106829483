/**
 * @generated SignedSource<<eb3c5a5b5b330426e5c1e02e56ad008c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { project_worker_insert_input, project_worker_set_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateProjectWorkersForWorkerRegistrationWithHardHat_Mutation$variables = {
  _set: project_worker_set_input;
  newSubId: string;
  newUpsertObjects: ReadonlyArray<project_worker_insert_input>;
  projectIds: ReadonlyArray<string>;
  workerId: string;
};
export type UpdateProjectWorkersForWorkerRegistrationWithHardHat_Mutation$data = {
  readonly u1: {
    readonly affected_rows: number;
  } | null | undefined;
  readonly u2: {
    readonly returning: ReadonlyArray<{
      readonly id: string;
      readonly pk: string;
    }>;
  } | null | undefined;
};
export type UpdateProjectWorkersForWorkerRegistrationWithHardHat_Mutation = {
  response: UpdateProjectWorkersForWorkerRegistrationWithHardHat_Mutation$data;
  variables: UpdateProjectWorkersForWorkerRegistrationWithHardHat_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "_set"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "newSubId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "newUpsertObjects"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectIds"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workerId"
},
v5 = {
  "alias": "u1",
  "args": [
    {
      "kind": "Variable",
      "name": "_set",
      "variableName": "_set"
    },
    {
      "fields": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "_in",
              "variableName": "projectIds"
            }
          ],
          "kind": "ObjectValue",
          "name": "project_id"
        },
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "_neq",
              "variableName": "newSubId"
            }
          ],
          "kind": "ObjectValue",
          "name": "subcontractor_id"
        },
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "_eq",
              "variableName": "workerId"
            }
          ],
          "kind": "ObjectValue",
          "name": "worker_id"
        }
      ],
      "kind": "ObjectValue",
      "name": "where"
    }
  ],
  "concreteType": "project_worker_mutation_response",
  "kind": "LinkedField",
  "name": "update_project_worker",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "affected_rows",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = [
  {
    "kind": "Variable",
    "name": "objects",
    "variableName": "newUpsertObjects"
  },
  {
    "kind": "Literal",
    "name": "on_conflict",
    "value": {
      "constraint": "project_worker_project_id_worker_id_subcontractor_id_key",
      "update_columns": [
        "title_id",
        "worker_role",
        "archived_at",
        "is_last",
        "deleted_at",
        "hard_hat_number"
      ]
    }
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateProjectWorkersForWorkerRegistrationWithHardHat_Mutation",
    "selections": [
      (v5/*: any*/),
      {
        "alias": "u2",
        "args": (v6/*: any*/),
        "concreteType": "project_worker_mutation_response",
        "kind": "LinkedField",
        "name": "insert_project_worker",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_worker",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v7/*: any*/),
              {
                "alias": "pk",
                "args": null,
                "kind": "ScalarField",
                "name": "__id_pk",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "UpdateProjectWorkersForWorkerRegistrationWithHardHat_Mutation",
    "selections": [
      (v5/*: any*/),
      {
        "alias": "u2",
        "args": (v6/*: any*/),
        "concreteType": "project_worker_mutation_response",
        "kind": "LinkedField",
        "name": "insert_project_worker",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_worker",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v7/*: any*/),
              {
                "alias": "pk",
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": "pk",
                "args": null,
                "filters": null,
                "handle": "pk",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2137b2d337bdd8a6380c160049bde199",
    "id": null,
    "metadata": {},
    "name": "UpdateProjectWorkersForWorkerRegistrationWithHardHat_Mutation",
    "operationKind": "mutation",
    "text": "mutation UpdateProjectWorkersForWorkerRegistrationWithHardHat_Mutation(\n  $projectIds: [uuid!]!\n  $workerId: uuid!\n  $newSubId: uuid!\n  $_set: project_worker_set_input!\n  $newUpsertObjects: [project_worker_insert_input!]!\n) {\n  u1: update_project_worker(where: {project_id: {_in: $projectIds}, worker_id: {_eq: $workerId}, subcontractor_id: {_neq: $newSubId}}, _set: $_set) {\n    affected_rows\n  }\n  u2: insert_project_worker(objects: $newUpsertObjects, on_conflict: {constraint: project_worker_project_id_worker_id_subcontractor_id_key, update_columns: [title_id, worker_role, archived_at, is_last, deleted_at, hard_hat_number]}) {\n    returning {\n      id\n      pk: id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1799d0dac59dfb7762804ec3910172f6";

export default node;
