/**
 * @generated SignedSource<<c81c281911788556786e709101e40164>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { report_bool_exp } from './types';
export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PreTaskPlansTableTotalRefetchableQuery$variables = {
  where: report_bool_exp;
};
export type PreTaskPlansTableTotalRefetchableQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PreTaskPlansTable_total">;
};
export type PreTaskPlansTableTotalRefetchableQuery = {
  response: PreTaskPlansTableTotalRefetchableQuery$data;
  variables: PreTaskPlansTableTotalRefetchableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PreTaskPlansTableTotalRefetchableQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "PreTaskPlansTable_total"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PreTaskPlansTableTotalRefetchableQuery",
    "selections": [
      {
        "alias": "allReportsConnection",
        "args": (v1/*: any*/),
        "concreteType": "reportConnection",
        "kind": "LinkedField",
        "name": "report_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "reportEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "report",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0033581f2b23f0c1045ae515a9be8f3a",
    "id": null,
    "metadata": {},
    "name": "PreTaskPlansTableTotalRefetchableQuery",
    "operationKind": "query",
    "text": "query PreTaskPlansTableTotalRefetchableQuery(\n  $where: report_bool_exp!\n) {\n  ...PreTaskPlansTable_total_3FC4Qo\n}\n\nfragment PreTaskPlansTable_total_3FC4Qo on query_root {\n  allReportsConnection: report_connection(where: $where) {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "462fdbc53803d2e188f91e53e565b61e";

export default node;
