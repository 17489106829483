import React from "react";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import { useUpdateGcEmployeeMutation } from "src/common/types/generated/apollo/graphQLTypes";
import GCHierarchyTeam from "../../component/GCHierarchyTeam";
import withCorporateAdminWrapper, {
  CorporateAdminWrapperProps,
} from "../withCorporateAdminWrapper";
import useAuthUser from "src/common/hooks/useAuthUser";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import { useParams } from "react-router-dom";
import { useUpdateUserHierarchyTeamsMutation } from "src/common/types/generated/apollo/graphQLTypes";
import { useApolloClient } from "@apollo/client";
import getOrgEmployeeWhere from "../../utils/getOrgEmployeeWhere";

const GCCorporateTeam: React.FC<CorporateAdminWrapperProps> = ({
  employee,
}) => {
  const [updateGcEmployee] = useUpdateGcEmployeeMutation();
  const section = useParams().section as string;
  const [updateUserHierarchyTeams] = useUpdateUserHierarchyTeamsMutation();

  return (
    <BasicWrapper>
      <GCHierarchyTeam
        {...{
          getRemainingGcTeamVariables: {
            projectsToInviteWhere: {},
            gcEmployeeWhere:
              section === "admins"
                ? {
                    is_corporate_admin: { _eq: true },
                    general_contractor_id: {
                      _eq: employee.general_contractor.id,
                    },
                  }
                : getOrgEmployeeWhere("gc", employee.general_contractor.id),
          },

          title: "Corporate Team",
          onInviteSuccess: async (invitedUserIds) => {
            await updateGcEmployee({
              variables: {
                where: { uid: { _in: invitedUserIds } },
                _set: { is_corporate_admin: true },
              },
              optimisticResponse: {
                update_general_contractor_employee: {
                  affected_rows: invitedUserIds.length,
                  returning: invitedUserIds.map((uid) => ({
                    uid,
                    is_corporate_admin: true,
                    __typename: "general_contractor_employee",
                  })),
                },
              },
            });
          },
          isAdmin: (employee) => employee.is_corporate_admin,
          onRemoveFromTeam: async (userId) => {
            await updateGcEmployee({
              variables: {
                where: { uid: { _eq: userId } },
                _set: { is_corporate_admin: false },
              },
              optimisticResponse: {
                update_general_contractor_employee: {
                  affected_rows: 1,
                  returning: [
                    {
                      uid: userId,
                      is_corporate_admin: false,
                      __typename: "general_contractor_employee",
                    },
                  ],
                },
              },
            });
          },

          excludeEmployeeWhere: {
            is_corporate_admin: { _eq: true },
          },
        }}
      />
    </BasicWrapper>
  );
};

export default withCustomSuspense(withCorporateAdminWrapper(GCCorporateTeam));
