/**
 * @generated SignedSource<<8a8f0f0a04504a5c4481c6580a5052b6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { worker_drug_test_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useInsertDTResult_InsertDrugTestMutation$variables = {
  objects: ReadonlyArray<worker_drug_test_insert_input>;
};
export type useInsertDTResult_InsertDrugTestMutation$data = {
  readonly insert_worker_drug_test: {
    readonly returning: ReadonlyArray<{
      readonly pk: string;
      readonly worker_id: string;
    }>;
  } | null | undefined;
};
export type useInsertDTResult_InsertDrugTestMutation = {
  response: useInsertDTResult_InsertDrugTestMutation$data;
  variables: useInsertDTResult_InsertDrugTestMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "objects"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "objects",
    "variableName": "objects"
  },
  {
    "kind": "Literal",
    "name": "on_conflict",
    "value": {
      "constraint": "worker_drug_test_worker_id_name_drug_test_date_key",
      "update_columns": ([]/*: any*/)
    }
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "worker_id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useInsertDTResult_InsertDrugTestMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "worker_drug_test_mutation_response",
        "kind": "LinkedField",
        "name": "insert_worker_drug_test",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "worker_drug_test",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              {
                "alias": "pk",
                "args": null,
                "kind": "ScalarField",
                "name": "__id_pk",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useInsertDTResult_InsertDrugTestMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "worker_drug_test_mutation_response",
        "kind": "LinkedField",
        "name": "insert_worker_drug_test",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "worker_drug_test",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              {
                "alias": "pk",
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": "pk",
                "args": null,
                "filters": null,
                "handle": "pk",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id"
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1055bc6cb72235146be5991a2f6a5a19",
    "id": null,
    "metadata": {},
    "name": "useInsertDTResult_InsertDrugTestMutation",
    "operationKind": "mutation",
    "text": "mutation useInsertDTResult_InsertDrugTestMutation(\n  $objects: [worker_drug_test_insert_input!]!\n) {\n  insert_worker_drug_test(objects: $objects, on_conflict: {constraint: worker_drug_test_worker_id_name_drug_test_date_key, update_columns: []}) {\n    returning {\n      pk: id\n      worker_id\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "120bffb5d901fe850764fd65aae10fea";

export default node;
