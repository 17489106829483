/**
 * @generated SignedSource<<55b66f6e2688064ee73af607a1ed1a06>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { project_setting_set_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MorningManPowerReportDownloadAuto_UpdateProjSetting_Mutation$variables = {
  projectId: string;
  projectSettingSet: project_setting_set_input;
};
export type MorningManPowerReportDownloadAuto_UpdateProjSetting_Mutation$data = {
  readonly update_project_setting_by_pk: {
    readonly auto_email_mmr: boolean;
    readonly id: string;
    readonly include_all_subs_mmr: boolean;
  } | null | undefined;
};
export type MorningManPowerReportDownloadAuto_UpdateProjSetting_Mutation = {
  response: MorningManPowerReportDownloadAuto_UpdateProjSetting_Mutation$data;
  variables: MorningManPowerReportDownloadAuto_UpdateProjSetting_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectSettingSet"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_set",
        "variableName": "projectSettingSet"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "project_id",
            "variableName": "projectId"
          }
        ],
        "kind": "ObjectValue",
        "name": "pk_columns"
      }
    ],
    "concreteType": "project_setting",
    "kind": "LinkedField",
    "name": "update_project_setting_by_pk",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "auto_email_mmr",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "include_all_subs_mmr",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MorningManPowerReportDownloadAuto_UpdateProjSetting_Mutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "MorningManPowerReportDownloadAuto_UpdateProjSetting_Mutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "88db7cc81c133dbf74c2f34d1182416f",
    "id": null,
    "metadata": {},
    "name": "MorningManPowerReportDownloadAuto_UpdateProjSetting_Mutation",
    "operationKind": "mutation",
    "text": "mutation MorningManPowerReportDownloadAuto_UpdateProjSetting_Mutation(\n  $projectSettingSet: project_setting_set_input!\n  $projectId: uuid!\n) {\n  update_project_setting_by_pk(_set: $projectSettingSet, pk_columns: {project_id: $projectId}) {\n    id\n    auto_email_mmr\n    include_all_subs_mmr\n  }\n}\n"
  }
};
})();

(node as any).hash = "0da96fadba690811685bb5b6805b5ec7";

export default node;
