/**
 * @generated SignedSource<<ecd777d9c91facdbddf5869691f3c9eb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { task_edit_insert_input, task_signature_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type InsertTaskApprovalMutation$variables = {
  taskEditObjects: ReadonlyArray<task_edit_insert_input>;
  taskSignObjects: ReadonlyArray<task_signature_insert_input>;
};
export type InsertTaskApprovalMutation$data = {
  readonly insert_task_edit: {
    readonly returning: ReadonlyArray<{
      readonly edit_type: string;
      readonly edited_at: string;
      readonly edited_by_user: {
        readonly employee: {
          readonly employee_title: {
            readonly name: {
              readonly en: string;
            };
          } | null | undefined;
          readonly general_contractor: {
            readonly name: string;
          };
        } | null | undefined;
        readonly name: string;
        readonly role: string;
        readonly subcontractor_employee: {
          readonly employee_title: {
            readonly name: {
              readonly en: string;
            };
          } | null | undefined;
          readonly subcontractor: {
            readonly name: string;
          };
        } | null | undefined;
        readonly worker: {
          readonly current_worker_role: string;
          readonly subcontractor: {
            readonly name: string;
          } | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly task_patch: any | null | undefined;
    }>;
  } | null | undefined;
  readonly insert_task_signature: {
    readonly returning: ReadonlyArray<{
      readonly created_at: string;
      readonly email_request: {
        readonly email: string;
        readonly user_id: string | null | undefined;
      } | null | undefined;
      readonly email_request_id: string | null | undefined;
      readonly id: string;
      readonly image: {
        readonly url: string;
      } | null | undefined;
      readonly pk: string;
      readonly signature_image_id: string | null | undefined;
      readonly user: {
        readonly name: string;
        readonly role: string;
        readonly subcontractor_employee: {
          readonly employee_title: {
            readonly name: {
              readonly en: string;
            };
          } | null | undefined;
          readonly subcontractor: {
            readonly name: string;
          };
        } | null | undefined;
        readonly worker: {
          readonly current_worker_role: string;
        } | null | undefined;
      } | null | undefined;
      readonly user_id: string | null | undefined;
    }>;
  } | null | undefined;
};
export type InsertTaskApprovalMutation = {
  response: InsertTaskApprovalMutation$data;
  variables: InsertTaskApprovalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "taskEditObjects"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "taskSignObjects"
},
v2 = [
  {
    "kind": "Variable",
    "name": "objects",
    "variableName": "taskSignObjects"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "signature_image_id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "user_id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v10 = [
  (v8/*: any*/)
],
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "subcontractor",
  "kind": "LinkedField",
  "name": "subcontractor",
  "plural": false,
  "selections": (v10/*: any*/),
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "en",
  "storageKey": null
},
v13 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "text_translation",
    "kind": "LinkedField",
    "name": "name",
    "plural": false,
    "selections": [
      (v12/*: any*/)
    ],
    "storageKey": null
  }
],
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "subcontractor_employee",
  "kind": "LinkedField",
  "name": "subcontractor_employee",
  "plural": false,
  "selections": [
    (v11/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "subcontractor_employee_title",
      "kind": "LinkedField",
      "name": "employee_title",
      "plural": false,
      "selections": (v13/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "current_worker_role",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email_request_id",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v18 = [
  {
    "kind": "Variable",
    "name": "objects",
    "variableName": "taskEditObjects"
  }
],
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "task_patch",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "edited_at",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "edit_type",
  "storageKey": null
},
v22 = [
  (v8/*: any*/),
  (v3/*: any*/)
],
v23 = {
  "alias": null,
  "args": null,
  "concreteType": "subcontractor",
  "kind": "LinkedField",
  "name": "subcontractor",
  "plural": false,
  "selections": (v22/*: any*/),
  "storageKey": null
},
v24 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "text_translation",
    "kind": "LinkedField",
    "name": "name",
    "plural": false,
    "selections": [
      (v12/*: any*/),
      (v3/*: any*/)
    ],
    "storageKey": null
  },
  (v3/*: any*/)
],
v25 = {
  "alias": null,
  "args": null,
  "concreteType": "subcontractor_employee",
  "kind": "LinkedField",
  "name": "subcontractor_employee",
  "plural": false,
  "selections": [
    (v23/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "subcontractor_employee_title",
      "kind": "LinkedField",
      "name": "employee_title",
      "plural": false,
      "selections": (v24/*: any*/),
      "storageKey": null
    },
    (v3/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "InsertTaskApprovalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "task_signature_mutation_response",
        "kind": "LinkedField",
        "name": "insert_task_signature",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "task_signature",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": "pk",
                "args": null,
                "kind": "ScalarField",
                "name": "__id_pk",
                "storageKey": null
              },
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "image",
                "kind": "LinkedField",
                "name": "image",
                "plural": false,
                "selections": [
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "user",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v14/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "worker",
                    "kind": "LinkedField",
                    "name": "worker",
                    "plural": false,
                    "selections": [
                      (v15/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v16/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "email_request",
                "kind": "LinkedField",
                "name": "email_request",
                "plural": false,
                "selections": [
                  (v17/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v18/*: any*/),
        "concreteType": "task_edit_mutation_response",
        "kind": "LinkedField",
        "name": "insert_task_edit",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "task_edit",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "user",
                "kind": "LinkedField",
                "name": "edited_by_user",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "general_contractor_employee",
                    "kind": "LinkedField",
                    "name": "employee",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "general_contractor",
                        "kind": "LinkedField",
                        "name": "general_contractor",
                        "plural": false,
                        "selections": (v10/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "employee_title",
                        "kind": "LinkedField",
                        "name": "employee_title",
                        "plural": false,
                        "selections": (v13/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v14/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "worker",
                    "kind": "LinkedField",
                    "name": "worker",
                    "plural": false,
                    "selections": [
                      (v15/*: any*/),
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "InsertTaskApprovalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "task_signature_mutation_response",
        "kind": "LinkedField",
        "name": "insert_task_signature",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "task_signature",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": "pk",
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": "pk",
                "args": null,
                "filters": null,
                "handle": "pk",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id"
              },
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "image",
                "kind": "LinkedField",
                "name": "image",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "user",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v25/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "worker",
                    "kind": "LinkedField",
                    "name": "worker",
                    "plural": false,
                    "selections": [
                      (v15/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v16/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "email_request",
                "kind": "LinkedField",
                "name": "email_request",
                "plural": false,
                "selections": [
                  (v17/*: any*/),
                  (v7/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v18/*: any*/),
        "concreteType": "task_edit_mutation_response",
        "kind": "LinkedField",
        "name": "insert_task_edit",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "task_edit",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "user",
                "kind": "LinkedField",
                "name": "edited_by_user",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "general_contractor_employee",
                    "kind": "LinkedField",
                    "name": "employee",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "general_contractor",
                        "kind": "LinkedField",
                        "name": "general_contractor",
                        "plural": false,
                        "selections": (v22/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "employee_title",
                        "kind": "LinkedField",
                        "name": "employee_title",
                        "plural": false,
                        "selections": (v24/*: any*/),
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v25/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "worker",
                    "kind": "LinkedField",
                    "name": "worker",
                    "plural": false,
                    "selections": [
                      (v15/*: any*/),
                      (v23/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1947395cc7f89879fc5f39341cc0b0cb",
    "id": null,
    "metadata": {},
    "name": "InsertTaskApprovalMutation",
    "operationKind": "mutation",
    "text": "mutation InsertTaskApprovalMutation(\n  $taskSignObjects: [task_signature_insert_input!]!\n  $taskEditObjects: [task_edit_insert_input!]!\n) {\n  insert_task_signature(objects: $taskSignObjects) {\n    returning {\n      id\n      created_at\n      pk: id\n      signature_image_id\n      image {\n        url\n        id\n      }\n      user_id\n      user {\n        name\n        role\n        subcontractor_employee {\n          subcontractor {\n            name\n            id\n          }\n          employee_title {\n            name {\n              en\n              id\n            }\n            id\n          }\n          id\n        }\n        worker {\n          current_worker_role\n          id\n        }\n        id\n      }\n      email_request_id\n      email_request {\n        email\n        user_id\n        id\n      }\n    }\n  }\n  insert_task_edit(objects: $taskEditObjects) {\n    returning {\n      task_patch\n      edited_at\n      edit_type\n      edited_by_user {\n        name\n        role\n        employee {\n          general_contractor {\n            name\n            id\n          }\n          employee_title {\n            name {\n              en\n              id\n            }\n            id\n          }\n          id\n        }\n        subcontractor_employee {\n          subcontractor {\n            name\n            id\n          }\n          employee_title {\n            name {\n              en\n              id\n            }\n            id\n          }\n          id\n        }\n        worker {\n          current_worker_role\n          subcontractor {\n            name\n            id\n          }\n          id\n        }\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c7b65d934f302b23c108c29da685451a";

export default node;
