import {
  Sign_In_Type_Enum,
  Submission_Image_Type_Enum,
  useInsertPreTaskPlanMutation,
  useUpsertProcoreDailyLogMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import dayjs from "dayjs";
import usePtpQr from "./ptpQrHook";
import { useCurrentLanguage } from "src/utility-features/i18n/context/languageHooks";
import useMarkSubOnsite from "./useMarkSubOnsite";
import { isProcoreError } from "src/domain-features/sitesafety/shared/constants/procoreErrors";

const useInsertPtp = () => {
  const lang = useCurrentLanguage();
  const [insertPreTaskPlan, { loading }] = useInsertPreTaskPlanMutation();
  const ptpQrContext = usePtpQr();
  const { ptpQrUser, projectId } = ptpQrContext;
  const [upsertProcoreDailyLog] = useUpsertProcoreDailyLogMutation();
  const { markSubOnsite } = useMarkSubOnsite();

  const userInfo = ptpQrUser.userInfo;
  if (!userInfo) throw new Error("userInfo not found");
  const userId = userInfo.id;
  const taskVals = Object.values(ptpQrUser.selectedTasks);
  const awarenessQuestionKeys = Object.keys(
    ptpQrUser.projectHazardAndAwareness.awarenessQuestions,
  );
  const projectHazards = Object.keys(
    ptpQrUser.projectHazardAndAwareness.projectHazards,
  );
  const signInType = ptpQrUser.signInType;
  const workersSignMap = ptpQrUser.workerSignMap;
  const reportManagement = ptpQrUser.reportManagement;
  const projectHazardsAndAwareness = ptpQrUser.projectHazardAndAwareness;
  const workersInfo = ptpQrUser.workersInfo;
  const dailyWorkLog = ptpQrUser.dailyWorkLog;
  if (!userId) throw new Error("User not found");

  const getTranslationData = (text: string) => {
    return {
      original: text,
      ...(["en", "es", "pt"].includes(lang) ? { [lang]: text } : {}),
    };
  };

  const insertPtp = async () => {
    const { data } = await insertPreTaskPlan({
      variables: {
        taskIds: taskVals.map((t) => t.id),
        reportObjs: [
          {
            submitter_project_worker_id: userInfo.projectWorkerId,
            project_crew_id: userInfo.projectCrewId,
            project_id: projectId,
            subcontractor_id: userInfo.company,
            images: {
              data: ptpQrUser.pictures.map(({ id, ...image }) => image),
            },
            sign_in_type:
              signInType === "quick"
                ? Sign_In_Type_Enum.Picture
                : Sign_In_Type_Enum.Signature,
            ...(workersSignMap[userId]
              ? {
                  submitter_signature: {
                    data: {
                      url: workersSignMap[userId],
                    },
                  },
                }
              : {}),
            report_project_locations: {
              data: ptpQrUser.selectedLocationIds.map((l) => ({
                project_location_id: l,
              })),
            },
            report_project_crews: {
              data: [...ptpQrUser.selectedProjectCrews].map((crewId) => ({
                project_crew_id: crewId,
              })),
            },

            tasks: {
              data: taskVals.map((task) => ({
                original_report_task_id: task.newlyAdded ? null : task.id,
                accepted_at_from_creation_request: task.acceptedAt,
                accepted_by_uid_from_creation_request: task.acceptedByUid,
                creation_request_id: task.creationRequestId,
                document_id: task.documentId,
                show_type: task.showType,
                project_id: projectId,
                subcontractor_id: userInfo.company,
                description: {
                  data:
                    task.description.edited === true
                      ? getTranslationData(task.description.editedValue)
                      : task.description.translation,
                },
                task_steps: {
                  data: task.steps.map((step, i) => ({
                    description: {
                      data:
                        step.description.edited === true
                          ? getTranslationData(step.description.editedValue)
                          : step.description.translation,
                    },
                    sort_index: i + 1,
                    task_step_hazards: {
                      data: step.hazards.map((haz, j) => ({
                        description: {
                          data: haz.description.edited
                            ? getTranslationData(haz.description.editedValue)
                            : haz.description.translation,
                        },
                        control: {
                          data: haz.control.edited
                            ? getTranslationData(haz.control.editedValue)
                            : haz.control.translation,
                        },
                        sort_index: j + 1,
                      })),
                    },
                  })),
                },

                task_ppe_types: {
                  data: task.ppes.map((ppe) => ({
                    ppe_type_id: ppe.id,
                  })),
                },
                task_permit_types: {
                  data: task.permits.map((permit) => ({
                    permit_type_id: permit.id,
                  })),
                },
              })),
            },

            report_awareness_questions: {
              data: reportManagement.safetyReportAwarenessDisabled
                ? []
                : awarenessQuestionKeys.map((key) => ({
                    awareness_question_id: key,
                    answer: {
                      data: getTranslationData(
                        projectHazardsAndAwareness.awarenessQuestions[key],
                      ),
                    },
                  })),
            },

            report_project_hazards: {
              data: reportManagement.safetyReportAwarenessDisabled
                ? []
                : projectHazards.map((key) => ({
                    project_hazard_id: key,
                    answer: {
                      data: getTranslationData(
                        projectHazardsAndAwareness.projectHazards[key],
                      ),
                    },
                  })),
            },

            report_project_workers: {
              data: [...workersInfo.workersPresent].map((worker) => ({
                project_worker_id: worker,
                ...(!!workersSignMap[worker]
                  ? {
                      signature_image: {
                        data: {
                          url: workersSignMap[worker],
                        },
                      },
                    }
                  : {}),
              })),
            },

            report_injuries: {
              data: [...workersInfo.injuredWorkers].map((pw) => ({
                project_worker_id: pw,
              })),
            },
          },
        ],
        tbts: reportManagement.safetyReportAwarenessDisabled
          ? []
          : ptpQrUser.pendingTbts.map((tbt) => ({
              ...(tbt.content
                ? {
                    content: {
                      data: getTranslationData(tbt.content),
                    },
                  }
                : {}),
              description: {
                data: getTranslationData(tbt.name),
              },
              images: {
                data: tbt.images.map((image) => ({ ...image })),
              },
              uploaded_toolbox_talk_id: tbt.id,
              led_by_project_worker_id: userInfo?.projectWorkerId,
              sign_in_type:
                signInType === "quick"
                  ? Sign_In_Type_Enum.Picture
                  : Sign_In_Type_Enum.Signature,
              submitted_language_code: lang,
              project_crew_id: userInfo.projectCrewId,
              subcontractor_id: userInfo.company,
              project_id: projectId,
              description_text: "_",
              submitter_project_worker_id: userInfo.projectWorkerId,
              submission_images: {
                data: ptpQrUser.pictures.map(({ id, ...img }, i) => ({
                  type: Submission_Image_Type_Enum.PictureSignIn,
                  sort_index: i,
                  image: {
                    data: { ...img, created_by_user_id: userId },
                  },
                })),
              },
              ...(signInType === "quick" && workersSignMap[userId]
                ? {
                    submitter_signature: {
                      data: {
                        url: workersSignMap[userId],
                      },
                    },
                  }
                : {}),
              toolbox_talk_project_crews: {
                data: [...ptpQrUser.selectedProjectCrews].map((pc) => ({
                  project_crew_id: pc,
                })),
              },

              toolbox_talk_project_workers: {
                data: [...workersInfo.workersPresent].map((worker) => ({
                  project_worker_id: worker,
                  ...(!!workersSignMap[worker]
                    ? {
                        signature_image: {
                          data: {
                            url: workersSignMap[worker],
                          },
                        },
                      }
                    : {}),
                })),
              },
            })),
        dailyWorkLogs: reportManagement.submitDailyReportWithPtp
          ? [
              {
                date: dayjs().toISOString(),
                project_id: projectId,
                subcontractor_id: userInfo.company,
                edited_workers: true,
                submitted_at: dayjs().toISOString(),
                description: {
                  data: getTranslationData(dailyWorkLog.description),
                },
                created_by_project_user: {
                  data: {
                    project_worker_id: userInfo.projectWorkerId,
                  },
                },
                daily_work_log_workers: {
                  data: [...workersInfo.workersPresent].map((e) => ({
                    hours: dailyWorkLog.hours,
                    project_worker_id: e,
                  })),
                },

                daily_work_log_crews: {
                  data: [...ptpQrUser.selectedProjectCrews].map((crewId) => ({
                    project_crew_id: crewId,
                  })),
                },
              },
            ]
          : [],
      },
    });

    await markSubOnsite({
      projectId: projectId,
      subcontractorId: userInfo.company,
      isMarkOnsiteEnabled: ptpQrUser.isMarkOnsiteEnabled,
      isSubOnsite: ptpQrUser.isSubOnsite,
    });

    const dailyWorkLogId = data?.insert_daily_work_log?.returning.at(0)?.id;
    if (dailyWorkLogId) {
      upsertProcoreDailyLog({
        variables: {
          input: {
            daily_work_log_id: dailyWorkLogId,
          },
        },
      }).catch((error) => {
        if (isProcoreError(error)) {
          console.log("Suppressed Procore error:", error.message);
          return;
        }
        throw error;
      });
    }
  };

  return [insertPtp, loading] as const;
};

export default useInsertPtp;
