import { IconMail } from "@tabler/icons";
import { Button, Form, notification, Select, Switch } from "antd";
import React, { useState } from "react";
import * as uuid from "uuid";
import { graphql } from "babel-plugin-relay/macro";
import Icon, { IconType } from "./general/Icon";
import { useParams } from "react-router-dom";
import { MorningManPowerReportEmailQuery } from "src/common/types/generated/relay/MorningManPowerReportEmailQuery.graphql";
import { useLazyLoadQuery } from "react-relay/hooks";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { MorningManPowerReportEmailMutation } from "src/common/types/generated/relay/MorningManPowerReportEmailMutation.graphql";
import { useEmailProjectMorningManpowerReportMutation } from "../types/generated/apollo/graphQLTypes";
import { ConnectionHandler } from "relay-runtime";
import getNormalSelectOptionsFilter from "../functions/getNormalSelectOptionsFilter";
import compareStringsIgnoreCase from "../functions/compareStringsIgnoreCase";
import compareTwoLists from "./ComparingTwoLists";
import useAuthUser from "../hooks/useAuthUser";
import withCustomSuspense from "./general/withCustomSuspense";

interface MorningManPowerEmailProps {
  message?: string;
  icon?: IconType;
}

interface UserFormValues {
  userIds: Array<string>;
  includeAllSubs?: boolean;
}

const query = graphql`
  query MorningManPowerReportEmailQuery(
    $projectId: uuid!
    $employee_id: uuid!
    $type: String!
  ) {
    project_connection(where: { id: { _eq: $projectId } }) {
      edges {
        node {
          id
          timezone
          project_employees {
            employee {
              id
              pk: id @__clientField(handle: "pk")
              user {
                id
                pk: id @__clientField(handle: "pk")
                name
                email
              }
            }
          }
        }
      }
    }

    email_project_employee_user_connection(
      first: 10000
      where: {
        project_id: { _eq: $projectId }
        employee_id: { _eq: $employee_id }
        type: { _eq: $type }
      }
    )
      @connection(
        key: "MorningManPowerReportEmailQuery_email_project_employee_user_connection"
        filters: []
      ) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          project_id
          employee_id
          user {
            id
            pk: id @__clientField(handle: "pk")
            name
          }
        }
      }
    }
  }
`;

const morningManPowerReportEmailMutation = graphql`
  mutation MorningManPowerReportEmailMutation(
    $objects: [email_project_employee_user_insert_input!]!
    $where: email_project_employee_user_bool_exp!
  ) {
    insert_email_project_employee_user(objects: $objects) {
      returning {
        id
        pk: id @__clientField(handle: "pk")
        user {
          id
          pk: id @__clientField(handle: "pk")
          name
          email
        }
      }
    }
    delete_email_project_employee_user(where: $where) {
      returning {
        id
      }
    }
  }
`;
const MorningManPowerEmail: React.FC<MorningManPowerEmailProps> = (props) => {
  const [form] = Form.useForm<UserFormValues>();
  const { projectId } = useParams();
  if (!projectId) {
    throw new Error("Project id not found");
  }
  const [loading, setLoading] = useState(false);
  const authUser = useAuthUser();

  const data = useLazyLoadQuery<MorningManPowerReportEmailQuery>(
    query,
    {
      projectId: projectId,
      employee_id: authUser.uid,
      type: "morning_man_power_report",
    },
    {
      fetchPolicy: "store-and-network",
    },
  );
  const project = data.project_connection.edges[0]?.node;
  if (!project) throw new Error("Project not found for give projectId");

  const loggedInProjEmp = project.project_employees.find(
    (node) => node.employee.user.pk === authUser.uid,
  );
  if (!loggedInProjEmp) throw new Error("Logged In Project Employee not found");
  const userEmail = loggedInProjEmp.employee.user.email;
  if (!userEmail) throw new Error("Logged In Project Employee Email not found");
  const defaultUsers = data.email_project_employee_user_connection.edges.map(
    (user) => ({
      userId: user.node.user?.pk,
      id: user.node.pk,
      name: user.node.user?.name,
    }),
  );
  if (!defaultUsers.find((user) => user.userId === authUser.uid)) {
    defaultUsers.push({
      userId: authUser.uid,
      id: authUser.uid,
      name: loggedInProjEmp.employee.user.name,
    });
  }
  const sortedOptions = [...project.project_employees].sort((nodeA, nodeB) =>
    compareStringsIgnoreCase(
      nodeA.employee.user.name,
      nodeB.employee.user.name,
    ),
  );

  const [morningManPowerReportMutation] =
    useAsyncMutation<MorningManPowerReportEmailMutation>(
      morningManPowerReportEmailMutation,
    );
  const [
    emailProjectMorningManPowerReport,
    { loading: emailProjectMorningManPowerReportLoading },
  ] = useEmailProjectMorningManpowerReportMutation();
  const confirmUsers = async () => {
    const values = await form.validateFields().catch((v) => null);
    if (!values) return;
    console.log(values);
    setLoading(true);
    try {
      const [toInsert, toDelete] = compareTwoLists(
        values.userIds,
        defaultUsers.map((u) => u.userId),
      );
      const insertEmailProjectUsers = toInsert.map((userId) => ({
        id: uuid.v4(),
        project_id: projectId,
        employee_id: authUser.uid,
        user_id: userId,
        type: "morning_man_power_report",
      }));

      await morningManPowerReportMutation({
        variables: {
          objects: insertEmailProjectUsers,
          where: { id: { _in: toDelete } },
        },
        updater: (store) => {
          const insertedEmailProjectUsers = store.getRootField(
            "insert_email_project_employee_user",
          );

          const deletedEmailProjectUser = store.getRootField(
            "delete_email_project_employee_user",
          );

          const conn = ConnectionHandler.getConnection(
            store.getRoot(),
            "MorningManPowerReportEmailQuery_email_project_employee_user_connection",
          );
          if (conn) {
            insertedEmailProjectUsers
              .getLinkedRecords("returning")
              .forEach((c) => {
                const edge = store.create(uuid.v4(), "edge");
                edge.setLinkedRecord(c, "node");
                ConnectionHandler.insertEdgeAfter(conn, edge);
              });

            deletedEmailProjectUser
              .getLinkedRecords("returning")
              .forEach((c) => {
                ConnectionHandler.deleteNode(conn, c.getDataID());
              });
          }
        },
      });
      form.resetFields();
    } finally {
      setLoading(false);
    }
  };
  return (
    <Form
      form={form}
      onFinish={async (values) => {
        await confirmUsers();
        await emailProjectMorningManPowerReport({
          variables: {
            input: {
              projectId: projectId,
              projectTimezone: project.timezone,
              employeeId: authUser.uid,
              email: userEmail,
              includeAllSubs: values.includeAllSubs,
            },
          },
        });
        notification.info({ message: "Email Sent" });
      }}
    >
      <div className="mt-1 flex flex-col">
        <Form.Item
          name="includeAllSubs"
          label="Include contractors with zero Manpower"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          name="userIds"
          label="Recipients"
          initialValue={(defaultUsers || []).map((user) => user.userId)}
        >
          <Select
            className="w-half flex flex-col"
            mode="multiple"
            showSearch
            filterOption={getNormalSelectOptionsFilter}
            options={sortedOptions.map(({ employee }) => ({
              value: employee.user.pk,
              key: employee.user.pk,
              label: employee.user.name,
              disabled: employee.user.pk === authUser.uid,
            }))}
          ></Select>
        </Form.Item>
      </div>
      <div className="flex space-x-2" style={{ marginBottom: "20px" }}>
        <Button
          htmlType="submit"
          type="primary"
          loading={emailProjectMorningManPowerReportLoading || loading}
        >
          Email Report
          <div className="ml-0.5">
            <Icon icon={IconMail} color="white" />
          </div>
        </Button>
      </div>
    </Form>
  );
};

export default withCustomSuspense(MorningManPowerEmail);
