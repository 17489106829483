import withBusinessUnitId, {
  BusinessUnitProps,
} from "../../utils/withBusinessUnitId";
import GCAdminObservations from "../../component/observation/GCAdminObservations";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";
const GCBuObservations: React.FC<BusinessUnitProps> = ({ businessUnitId }) => {
  const { userData } = useUserData();
  const projWhere = { gc_business_unit_id: { _eq: businessUnitId } };
  return (
    <GCAdminObservations
      optionsQueryVars={{
        projWhere,
        gcBuWhere: { id: { _is_null: true } },
        gcDivisionWhere: { id: { _is_null: true } },
        gcOfficeWhere: userData.employee?.general_contractor
          .hierarchy_office_name
          ? { gc_business_unit_id: { _eq: businessUnitId } }
          : { id: { _is_null: true } },
      }}
      projWhere={projWhere}
      organizationId={businessUnitId}
      organizationLevel="business_unit"
    />
  );
};
export default withBusinessUnitId(GCBuObservations);
