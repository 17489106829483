import { useUserData } from "src/utility-features/authorization/UserDataProvider";
import GCAdminIncidentsOverview from "../../component/incident/GCAdminIncidentsOverview";
import withOfficeId, { OfficeProps } from "../../utils/withOfficeId";

const GCOfficeIncidents: React.FC<OfficeProps> = ({ officeId }) => {
  const { userData } = useUserData();
  return (
    <GCAdminIncidentsOverview
      organizationLevel="office"
      organizationId={officeId}
      severityUserFilter={{ office_id: { _eq: officeId } }}
      toInsertWith={{
        gc_id: userData.employee?.general_contractor.id,
        office_id: officeId,
      }}
    />
  );
};
export default withOfficeId(GCOfficeIncidents);
