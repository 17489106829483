/**
 * @generated SignedSource<<f350e03cf93d50387a63b4ef41b8d562>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { project_employee_bool_exp } from './types';
export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
export type EmailProjectUserSettingsQuery$variables = {
  projectEmployeeWhere: project_employee_bool_exp;
  projectId: string;
  type: string;
};
export type EmailProjectUserSettingsQuery$data = {
  readonly email_project_user_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly pk: string;
        readonly project_id: string;
        readonly type: string | null | undefined;
        readonly user: {
          readonly email: string | null | undefined;
          readonly id: string;
          readonly name: string;
          readonly pk: string;
        };
      };
    }>;
  };
  readonly project_employee_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly employee: {
          readonly user: {
            readonly email: string | null | undefined;
            readonly id: string;
            readonly name: string;
            readonly pk: string;
          };
        };
      };
    }>;
  };
};
export type EmailProjectUserSettingsQuery = {
  response: EmailProjectUserSettingsQuery$data;
  variables: EmailProjectUserSettingsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectEmployeeWhere"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type"
},
v3 = {
  "user": {
    "name": "asc"
  }
},
v4 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "employee": (v3/*: any*/)
    }
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "projectEmployeeWhere"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "user",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    (v6/*: any*/),
    (v7/*: any*/),
    (v8/*: any*/)
  ],
  "storageKey": null
},
v10 = {
  "fields": [
    {
      "fields": [
        {
          "kind": "Variable",
          "name": "_eq",
          "variableName": "projectId"
        }
      ],
      "kind": "ObjectValue",
      "name": "project_id"
    },
    {
      "fields": [
        {
          "kind": "Variable",
          "name": "_eq",
          "variableName": "type"
        }
      ],
      "kind": "ObjectValue",
      "name": "type"
    }
  ],
  "kind": "ObjectValue",
  "name": "where"
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "project_id",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v16 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v17 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "user",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    (v16/*: any*/),
    (v17/*: any*/),
    (v7/*: any*/),
    (v8/*: any*/)
  ],
  "storageKey": null
},
v19 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000
  },
  {
    "kind": "Literal",
    "name": "order_by",
    "value": (v3/*: any*/)
  },
  (v10/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EmailProjectUserSettingsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "project_employeeConnection",
        "kind": "LinkedField",
        "name": "project_employee_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_employeeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project_employee",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "general_contractor_employee",
                    "kind": "LinkedField",
                    "name": "employee",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "email_project_user_connection",
        "args": [
          (v10/*: any*/)
        ],
        "concreteType": "email_project_userConnection",
        "kind": "LinkedField",
        "name": "__EmailProjectUserSettings_email_project_user_connection_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "email_project_userEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "email_project_user",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v9/*: any*/),
                  (v13/*: any*/)
                ],
                "storageKey": null
              },
              (v14/*: any*/)
            ],
            "storageKey": null
          },
          (v15/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "EmailProjectUserSettingsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "project_employeeConnection",
        "kind": "LinkedField",
        "name": "project_employee_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_employeeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project_employee",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "general_contractor_employee",
                    "kind": "LinkedField",
                    "name": "employee",
                    "plural": false,
                    "selections": [
                      (v18/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v19/*: any*/),
        "concreteType": "email_project_userConnection",
        "kind": "LinkedField",
        "name": "email_project_user_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "email_project_userEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "email_project_user",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v18/*: any*/),
                  (v13/*: any*/)
                ],
                "storageKey": null
              },
              (v14/*: any*/)
            ],
            "storageKey": null
          },
          (v15/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v19/*: any*/),
        "filters": [
          "where"
        ],
        "handle": "connection",
        "key": "EmailProjectUserSettings_email_project_user_connection",
        "kind": "LinkedHandle",
        "name": "email_project_user_connection"
      }
    ]
  },
  "params": {
    "cacheID": "cef5b278bce823135e968823b7b321fc",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "email_project_user_connection"
          ]
        }
      ]
    },
    "name": "EmailProjectUserSettingsQuery",
    "operationKind": "query",
    "text": "query EmailProjectUserSettingsQuery(\n  $projectId: uuid!\n  $projectEmployeeWhere: project_employee_bool_exp!\n  $type: String!\n) {\n  project_employee_connection(order_by: {employee: {user: {name: asc}}}, where: $projectEmployeeWhere) {\n    edges {\n      node {\n        employee {\n          user {\n            id\n            pk: id\n            name\n            email\n          }\n          id\n        }\n        id\n      }\n    }\n  }\n  email_project_user_connection(first: 10000, where: {project_id: {_eq: $projectId}, type: {_eq: $type}}, order_by: {user: {name: asc}}) {\n    edges {\n      node {\n        id\n        pk: id\n        project_id\n        type\n        user {\n          id\n          pk: id\n          name\n          email\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f22772db40ea3d3b70bcb86a11e439ef";

export default node;
