/**
 * @generated SignedSource<<daaf8524d03c009b66cf25c6aa25da26>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AnonProjectWorkerTable_project_worker$data = {
  readonly project_worker_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly created_at: string;
        readonly hard_hat_number: string | null | undefined;
        readonly pk: string;
        readonly project: {
          readonly name: string;
        };
        readonly project_crew?: {
          readonly name: string;
        } | null | undefined;
        readonly subcontractor: {
          readonly name: string;
        };
        readonly title: {
          readonly translation: {
            readonly en: string;
          };
        } | null | undefined;
        readonly title_id: string | null | undefined;
        readonly user: {
          readonly email: string | null | undefined;
          readonly name: string;
          readonly role: string;
        } | null | undefined;
        readonly user_orientation?: {
          readonly corporateResults: ReadonlyArray<{
            readonly completed_at: string | null | undefined;
            readonly status: string;
          }>;
          readonly orientated_at: string | null | undefined;
        } | null | undefined;
        readonly worker_id: string;
        readonly worker_role: string;
      };
    }>;
  };
  readonly " $fragmentType": "AnonProjectWorkerTable_project_worker";
};
export type AnonProjectWorkerTable_project_worker$key = {
  readonly " $data"?: AnonProjectWorkerTable_project_worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"AnonProjectWorkerTable_project_worker">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "project_worker_connection"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "gcId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "includeCrew"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "includeOrientation"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "order_by"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "where"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./AnonProjectWorkerTableRefetchableQuery.graphql')
    }
  },
  "name": "AnonProjectWorkerTable_project_worker",
  "selections": [
    {
      "alias": "project_worker_connection",
      "args": [
        {
          "kind": "Variable",
          "name": "order_by",
          "variableName": "order_by"
        },
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "where"
        }
      ],
      "concreteType": "project_workerConnection",
      "kind": "LinkedField",
      "name": "__AnonProjectWorkerTable_project_worker_connection_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "project_workerEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "project_worker",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": "pk",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__id_pk",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "worker_role",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hard_hat_number",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "created_at",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title_id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "worker_id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "user",
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "email",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "role",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "condition": "includeCrew",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "project_crew",
                      "kind": "LinkedField",
                      "name": "project_crew",
                      "plural": false,
                      "selections": (v2/*: any*/),
                      "storageKey": null
                    }
                  ]
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "project",
                  "kind": "LinkedField",
                  "name": "project",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "subcontractor",
                  "kind": "LinkedField",
                  "name": "subcontractor",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "worker_title",
                  "kind": "LinkedField",
                  "name": "title",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "text_translation",
                      "kind": "LinkedField",
                      "name": "translation",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "en",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "condition": "includeOrientation",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "user_orientation",
                      "kind": "LinkedField",
                      "name": "user_orientation",
                      "plural": false,
                      "selections": [
                        {
                          "alias": "corporateResults",
                          "args": [
                            {
                              "kind": "Literal",
                              "name": "order_by",
                              "value": [
                                {
                                  "completed_at": "asc_nulls_last"
                                },
                                {
                                  "created_at": "asc"
                                }
                              ]
                            },
                            {
                              "fields": [
                                {
                                  "kind": "Literal",
                                  "name": "_or",
                                  "value": [
                                    {
                                      "status": {
                                        "_eq": "completed"
                                      }
                                    },
                                    {
                                      "completed_at": {
                                        "_is_null": false
                                      }
                                    }
                                  ]
                                },
                                {
                                  "fields": [
                                    {
                                      "fields": [
                                        {
                                          "kind": "Variable",
                                          "name": "_eq",
                                          "variableName": "gcId"
                                        }
                                      ],
                                      "kind": "ObjectValue",
                                      "name": "general_contractor_id"
                                    },
                                    {
                                      "kind": "Literal",
                                      "name": "type",
                                      "value": {
                                        "_eq": "corporate"
                                      }
                                    }
                                  ],
                                  "kind": "ObjectValue",
                                  "name": "orientation"
                                }
                              ],
                              "kind": "ObjectValue",
                              "name": "where"
                            }
                          ],
                          "concreteType": "orientation_result",
                          "kind": "LinkedField",
                          "name": "all_user_orientation_results",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "completed_at",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "status",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "orientated_at",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ]
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "query_root",
  "abstractKey": null
};
})();

(node as any).hash = "7b5b85b095904149cb8f06a2881c8f1a";

export default node;
