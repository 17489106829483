import { Select } from "antd";
import React, { FC } from "react";
import FilterControllerInput from "../FilterControllerInput";
import { StateFunctions } from "./CustomFilterController";
import dayjs from "dayjs";
import getNormalSelectOptionsFilter from "src/common/functions/getNormalSelectOptionsFilter";

export type CustomRadioFilterProps = {
  title: string;
  removeFilter: () => void;
  placeholder: string;
  selectOptions: Array<{ name: string; id: string }>;
  onOk: (ids: Array<string>) => void;
  initialClose?: boolean;
} & StateFunctions;
const CustomRadioFilter: FC<CustomRadioFilterProps> = ({
  onOk,
  selectOptions,
  placeholder,
  removeFilter,
  appliedFiltersfilterMap,
  setAppliedFilter,
  setRemovalFilters,
  title,
  initialClose,
}) => {
  const appliedFilters = appliedFiltersfilterMap[title + "%select"];
  return (
    <>
      <FilterControllerInput
        title={title}
        initialClose={initialClose}
        removeFilter={() => {
          setAppliedFilter((prev) => {
            return {
              ...prev,
              [title + "%select"]: false,
              [title + "%selectApplied"]: false,
            };
          });
          removeFilter?.();
        }}
        component={
          <div>
            <div className="flex">
              <Select
                size="large"
                mode="multiple"
                showSearch
                filterOption={getNormalSelectOptionsFilter}
                value={
                  Array.isArray(appliedFilters)
                    ? appliedFilters.map((p) =>
                        p
                          ? !dayjs.isDayjs(p)
                            ? p.id
                            : p.format("YYYY-MM-DD")
                          : "",
                      )
                    : []
                }
                placeholder={placeholder}
                onChange={(val: Array<string>) => {
                  setAppliedFilter((prev) => ({
                    ...prev,
                    [title + "%select"]: selectOptions.filter(
                      (c: { id: string }) => val.some((p) => p === c.id),
                    ),
                  }));
                  onOk([...val]);
                  setRemovalFilters((prev) => ({
                    ...prev,
                    [title + "%select"]: (newList) =>
                      onOk(newList?.map((e) => e.id) ?? []),
                  }));
                }}
                className="w-16 text-1"
                options={selectOptions.map((p) => ({
                  label: p.name,
                  value: p.id,
                }))}
              />
            </div>
          </div>
        }
      />
    </>
  );
};
export default CustomRadioFilter;
