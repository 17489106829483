import { Checkbox, Input, message } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import React, { FC, forwardRef, useState, useRef } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { Navigate } from "react-router-dom";
import ImageCarousel from "src/common/components/general/images/ImageCarousel";
import BSpace from "src/common/components/layouts/BSpace";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import DataScrollTable, {
  DataScrollTableImplementorProps,
  DataScrollTableProps,
  DataScrollTableRef,
} from "src/common/components/tables/basic/DataScrollTable";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { auth } from "src/common/functions/firebase";
import { SFVerifyProfilePhotoReasonsQuery } from "src/common/types/generated/relay/SFVerifyProfilePhotoReasonsQuery.graphql";
import { SFVerifyProfilePhotoTableQuery } from "src/common/types/generated/relay/SFVerifyProfilePhotoTableQuery.graphql";
import { SFVerifyProfilePhotoTable_certificates_to_verify$data } from "src/common/types/generated/relay/SFVerifyProfilePhotoTable_certificates_to_verify.graphql";
import { VerifyCertsQuery } from "src/common/types/generated/relay/VerifyCertsQuery.graphql";
import ProfilPicButton from "./components/ProfilePicButton";
import RejectAndArchiveButtons from "./components/RejectAndArchiveButtons";
import { SFVerifyProfilePhotoMutation } from "src/common/types/generated/relay/SFVerifyProfilePhotoMutation.graphql";

export type ColumnKeys = "worker" | "project" | "date" | "action" | "image";
const CONNECTION_NAME = "certificates_to_verify_connection";
type DConnection =
  SFVerifyProfilePhotoTable_certificates_to_verify$data[typeof CONNECTION_NAME];
type Props = DataScrollTableImplementorProps<
  DConnection,
  ColumnKeys,
  SFVerifyProfilePhotoTableQuery,
  { worker_id: string | unknown; project_id: string | unknown }
> & {
  onRowClickPK: (pk: string) => void;
  searchString?: string;
  loggedInUserId: string;
  reasons: VerifyCertsQuery["response"]["reason_to_reject_connection"]["edges"];
  extraColumns?: DataScrollTableProps<
    DConnection,
    ColumnKeys,
    SFVerifyProfilePhotoTableQuery
  >["columns"];
};
const SFWorkerToVerifyTable = forwardRef<DataScrollTableRef, Props>(
  ({ title = "Workers", onRowClickPK, searchString, where, ...props }, ref) => {
    const [selectedRows, setSelectedRows] = useState<
      Array<{ workerId: string; certId: string; imageId: string }>
    >([]);

    const setProfilePicMutation = graphql`
      mutation SFVerifyProfilePhotoMutation(
        $userUpdates: [user_updates!]!
        $certsUpdates: [certificates_to_verify_updates!]!
      ) {
        update_user_many(updates: $userUpdates) {
          affected_rows
        }
        update_certificates_to_verify_many(updates: $certsUpdates) {
          affected_rows
        }
      }
    `;
    const [updateProfilePic] = useAsyncMutation<SFVerifyProfilePhotoMutation>(
      setProfilePicMutation,
    );

    return (
      <DataScrollTable<DConnection, ColumnKeys, SFVerifyProfilePhotoTableQuery>
        {...props}
        ref={ref}
        title={title}
        onRowClick={({ pk }) => onRowClickPK && onRowClickPK(pk)}
        where={where}
        newCustomTableLook
        connectionName={CONNECTION_NAME}
        totalCountConnectionName={"allCertifcatesToVerifyConnection"}
        topBarButtons={
          selectedRows.length == 0
            ? undefined
            : [
                {
                  label: "Confirm Profile Photos",
                  small: true,
                  onClick: async () => {
                    try {
                      const time = dayjs().format();
                      await updateProfilePic({
                        variables: {
                          certsUpdates: selectedRows.map((row) => ({
                            where: { id: { _eq: row.certId } },
                            _set: {
                              verified_at: dayjs().format(),
                              verified_by_uid: auth.currentUser?.uid,
                              document: "profilePhoto",
                            },
                          })),
                          userUpdates: selectedRows.map((row) => ({
                            where: { id: { _eq: row.workerId } },
                            _set: { profile_picture_id: row.imageId },
                          })),
                        },
                      });
                      message.success("Worker Profile Picture updated");
                      if (ref && typeof ref !== "function") {
                        ref.current?.refetch();
                      }
                      setSelectedRows([]);
                    } catch (err) {
                      message.error({
                        content: "Could not update profile pic" + err,
                      });
                    }
                  },
                },
              ]
        }
        columns={[
          {
            title: "Image",
            key: "image",
            size: "lg",

            dataIndex: [],
            render: (_, row) => {
              return (
                <div className="flex flex-row h-8">
                  <div
                    className="mt-2 p-1"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Checkbox
                      key={row.pk}
                      onClick={(e) => e.stopPropagation()}
                      onChange={(e) => {
                        e.stopPropagation();
                        if (e.target.checked)
                          setSelectedRows([
                            ...selectedRows,
                            {
                              certId: row.pk,
                              imageId: row.front_image_id,
                              workerId: row.worker_id,
                            },
                          ]);
                        else
                          setSelectedRows(
                            selectedRows.filter((key) => key.certId !== row.pk),
                          );
                      }}
                    />
                  </div>
                  {row.front_image?.url && (
                    <BSpace className="max-h-2">
                      <ImageCarousel
                        images={[{ url: row.front_image.url }]}
                        profilePhoto
                      />
                    </BSpace>
                  )}
                </div>
              );
            },
          },
          {
            title: "Worker Name",
            key: "worker",
            size: "md",
            sortable: true,
            dataIndex: ["worker", "user", "name"],
            searchDataIndex: ["worker", "user", "name"],
            searchValue: searchString,
          },
          {
            title: "Project",
            key: "project",
            size: "md",
            dataIndex: ["project", "name"],
            searchDataIndex: ["project", "name"],
            render: (_, row) => row.project?.name,
          },
          {
            title: "Date Submitted",
            key: "date",
            size: "md",
            sortable: true,
            defaultSortOrder: "desc",
            dataIndex: ["created_at"],
            render: (a) => dayjs(a).format("MMM DD, YYYY, h:mm a"),
          },
          {
            title: "Action",
            key: "action",
            size: "lg",
            dataIndex: [""],
            render: (_, r) =>
              !r.verified_at && (
                <RejectAndArchiveButtons
                  certId={r.pk}
                  changeType={"profilePhoto"}
                  workerId={r.worker_id}
                  reasons={props.reasons}
                  loggedInUserId={props.loggedInUserId}
                  onSuccess={() => {
                    if (r.worker.user.profile_picture_id === r.front_image_id) {
                      updateProfilePic({
                        variables: {
                          certsUpdates: [],
                          userUpdates: [
                            {
                              where: {
                                id: { _eq: r.worker_id },
                                profile_picture_id: null,
                              },
                            },
                          ],
                        },
                      });
                    }
                    if (ref && typeof ref !== "function")
                      ref.current?.refetch();
                  }}
                />
              ),
          },
        ]}
        queryNode={graphql`
          query SFVerifyProfilePhotoTableQuery(
            $first: Int!
            $after: String
            $where: certificates_to_verify_bool_exp!
            $order_by: [certificates_to_verify_order_by!]!
          ) {
            ...SFVerifyProfilePhotoTable_certificates_to_verify
              @arguments(
                first: $first
                after: $after
                where: $where
                order_by: $order_by
              )
            ...SFVerifyProfilePhotoTable_total @arguments(where: $where)
          }
        `}
        totalCountNode={graphql`
          fragment SFVerifyProfilePhotoTable_total on query_root
          @argumentDefinitions(
            where: { type: "certificates_to_verify_bool_exp!" }
          )
          @refetchable(
            queryName: "SFVerifyProfilePhotoTableTotalRefetchableQuery"
          ) {
            allCertifcatesToVerifyConnection: certificates_to_verify_connection(
              where: $where
            ) {
              edges {
                node {
                  id
                }
              }
            }
          }
        `}
        paginationNode={graphql`
          fragment SFVerifyProfilePhotoTable_certificates_to_verify on query_root
          @argumentDefinitions(
            first: { type: "Int!" }
            after: { type: "String" }
            where: { type: "certificates_to_verify_bool_exp!" }
            order_by: { type: "[certificates_to_verify_order_by!]!" }
          )
          @refetchable(queryName: "SFVerifyProfilePhotoTableRefetchableQuery") {
            certificates_to_verify_connection(
              first: $first
              after: $after
              where: $where
              order_by: $order_by
            )
              @connection(
                key: "SFVerifyProfilePhotoTable_certificates_to_verify_connection"
                filters: ["where", "order_by"]
              ) {
              edges {
                node {
                  pk: id @__clientField(handle: "pk")
                  worker_id
                  project_id
                  created_at
                  front_image_id
                  verified_at
                  front_image {
                    url
                  }
                  worker {
                    user {
                      name
                      profile_picture_id
                    }
                  }
                  project {
                    name
                  }
                }
              }
            }
          }
        `}
      />
    );
  },
);
const query = graphql`
  query SFVerifyProfilePhotoReasonsQuery {
    reason_to_reject_connection(order_by: { reason: asc }) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          reason
          type
        }
      }
    }
  }
`;
const SFVerifyProfilePhotoTable: FC<{ loggedInUserId: string }> = ({
  loggedInUserId,
}) => {
  const workerTableRef = useRef<DataScrollTableRef>(null);
  const data = useLazyLoadQuery<SFVerifyProfilePhotoReasonsQuery>(query, {})
    .reason_to_reject_connection.edges;
  const [inputValue, setInputValue] = useState("");
  const onInputKeyDown = (event: any): any => {
    if (event.key === "Enter") {
      setInputValue(event.target.value);
    }
  };

  return (
    <>
      <BSpace>
        <div className="flex justify-center items-center m-1">
          Profile Photo Verification Table
        </div>
        <div className="flex justify-between float-right">
          <Input
            type="primary"
            onKeyDown={onInputKeyDown}
            className="w-18"
            placeholder="Search"
          />
        </div>
      </BSpace>
      <BSpace>
        <SFWorkerToVerifyTable
          ref={workerTableRef}
          searchString={inputValue}
          loggedInUserId={loggedInUserId}
          reasons={data}
          where={{
            verified_at: { _is_null: true },
            document: { _eq: "profilePhoto" },
            status: { _eq: "submitted" },
          }}
          onRowClickPK={(pk) => {}}
        />
      </BSpace>
    </>
  );
};
const SFVerifyProfilePhotoTableWrapper: FC = () => {
  return auth.currentUser?.uid ? (
    <BasicWrapper scrollable>
      <SFVerifyProfilePhotoTable
        {...{ loggedInUserId: auth.currentUser.uid }}
      />
    </BasicWrapper>
  ) : (
    <Navigate to="sfe" replace />
  );
};
export default SFVerifyProfilePhotoTableWrapper;
