import React, { useEffect, useState, FC } from "react";
import { ColumnFilterItem } from "antd/lib/table/interface";
import { QueryResult } from "@apollo/client";

const helper = (indices: Array<string> | undefined, obj: any): any => {
  if (indices === undefined) {
    return obj;
  }
  if (indices.length === 1) {
    return obj[indices[0]];
  } else {
    const currIndex = indices[0];
    indices.splice(0, 1);
    return helper(indices, obj[currIndex]);
  }
};

function useColumnFilter(
  queryResult: Partial<QueryResult<any, any>> | undefined,
  indices: {
    arrayIndex: Array<string> | undefined;
    labelIndex: Array<string>;
    valueIndex: Array<string>;
  },
) {
  const [filterOptions, setFilterOptions] = useState<Array<ColumnFilterItem>>(
    [],
  );
  const [filters, setFilters] = useState<Array<any> | undefined>();
  // Determine filterOptions based on query result
  useEffect(() => {
    if (
      queryResult &&
      queryResult.loading === false &&
      queryResult.data !== undefined
    ) {
      const data = queryResult.data;
      const arr = helper(indices.arrayIndex, data);
      const options: Array<ColumnFilterItem> = [];
      arr.forEach((obj: any) => {
        const label = helper(indices.labelIndex, obj);
        const value = helper(indices.valueIndex, obj);
        options.push({ text: label, value: value });
      });
      setFilterOptions(options);
    }
  }, [queryResult?.loading]);
  // Return props
  return {
    filterOptions,
    filters,
    setFilters,
  };
}

export default useColumnFilter;
