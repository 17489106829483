import React, { memo, useState } from "react";
import { GetObservationByPkQuery } from "src/common/types/generated/apollo/graphQLTypes";
import { Card, Select } from "antd";
import useUpdateObservationWithCache, {
  UpdateFunctionInputProps,
} from "../../utils/useUpdateObservationWithCache";
type ObservationType = NonNullable<
  GetObservationByPkQuery["observation_by_pk"]
>;
const SelectCard: React.FC<{
  observation: ObservationType;
  required?: boolean;
  options: Array<{
    label: string;
    color?: string;
    value: string | number;
  }>;
  field:
    | "observation_category_id"
    | "risk_level_value"
    | "number_of_findings"
    | "subcontractor_id";
  notEditing?: boolean;
  onChange?: (v: string | number) => void;
  placeholder: string;
  label: string;
}> = memo(
  ({
    notEditing,
    options,
    onChange,
    field,
    label,
    required,
    placeholder,
    observation,
  }) => {
    const [dropdown, setDropdown] = useState(false);
    const [updateObservation, updating] = useUpdateObservationWithCache();
    const update = async (
      set: UpdateFunctionInputProps["set"],
      editComment?: string,
    ) =>
      await updateObservation({
        observation,
        set,
        editComment,
      });
    const title =
      options.find((op) => op.value === observation[field])?.label || null;
    return (
      <Card className="w-2/5">
        <div className="flex gap-1 items-center">
          {required && <span className="text-semantic-negative">* </span>}
          {label}:{" "}
          {notEditing ? (
            <span className="font-accent">{title}</span>
          ) : (
            <Select
              style={{ width: "80%" }}
              placeholder={placeholder}
              // disabled={updating}
              value={observation[field]}
              showSearch
              onDropdownVisibleChange={setDropdown}
              options={options.map((option) => ({
                ...option,
                label: (
                  <div
                    style={{
                      whiteSpace: dropdown ? "normal" : "nowrap",
                      color: option.color || undefined,
                    }}
                  >
                    {option.label}
                  </div>
                ),
              }))}
              // dropdownRender={(menu) => (
              //   <div style={{ whiteSpace: "normal" }}>{menu}</div>
              // )}
              filterOption={(input, option) => {
                console.log(option?.label, String(option?.label));
                if (option)
                  return (
                    String(option.label.props.children)
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                return false;
                //@ts-ignore TODO fix it properly
              }}
              onChange={async (v, op) => {
                if (onChange) {
                  onChange(v);
                  return;
                }
                if (!v) return;
                const prevVal = observation[field];
                update(
                  { [field]: v },
                  `${field} changed from ${
                    options.find(
                      (option) => option.value === observation[field],
                    )?.label
                  } to ${
                    op && "label" in op
                      ? op.label.props.children.toString()
                      : null
                  }`,
                );
              }}
            />
          )}
        </div>
      </Card>
    );
  },
);
export default SelectCard;
