/**
 * @generated SignedSource<<643a6ca6e98c1ef5b23fc712612ced61>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { order_by, project_bool_exp, project_order_by } from './types';
export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectTableRefetchableQuery$variables = {
  after?: string | null | undefined;
  first: number;
  order_by: project_order_by;
  where: project_bool_exp;
};
export type ProjectTableRefetchableQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ProjectTable_projects">;
};
export type ProjectTableRefetchableQuery = {
  response: ProjectTableRefetchableQuery$data;
  variables: ProjectTableRefetchableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "order_by"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
],
v1 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v2 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v3 = {
  "kind": "Variable",
  "name": "where",
  "variableName": "where"
},
v4 = [
  (v1/*: any*/),
  (v2/*: any*/),
  {
    "items": [
      {
        "kind": "Literal",
        "name": "order_by.0",
        "value": {
          "completed": "asc"
        }
      },
      {
        "kind": "Variable",
        "name": "order_by.1",
        "variableName": "order_by"
      }
    ],
    "kind": "ListValue",
    "name": "order_by"
  },
  (v3/*: any*/)
],
v5 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = [
  (v5/*: any*/),
  (v6/*: any*/),
  (v8/*: any*/),
  (v7/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectTableRefetchableQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "kind": "Variable",
            "name": "order_by",
            "variableName": "order_by"
          },
          (v3/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "ProjectTable_projects"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProjectTableRefetchableQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "projectConnection",
        "kind": "LinkedField",
        "name": "project_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "projectEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "completed",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "where",
                        "value": {
                          "status": {
                            "_eq": "Active"
                          }
                        }
                      }
                    ],
                    "concreteType": "project_employee_aggregate",
                    "kind": "LinkedField",
                    "name": "project_employees_aggregate",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "project_employee_aggregate_fields",
                        "kind": "LinkedField",
                        "name": "aggregate",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "count",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "project_employees_aggregate(where:{\"status\":{\"_eq\":\"Active\"}})"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "gc_division",
                    "kind": "LinkedField",
                    "name": "gc_division",
                    "plural": false,
                    "selections": (v9/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "gc_business_unit",
                    "kind": "LinkedField",
                    "name": "gc_business_unit",
                    "plural": false,
                    "selections": (v9/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "gc_office",
                    "kind": "LinkedField",
                    "name": "gc_office",
                    "plural": false,
                    "selections": (v9/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "general_contractor",
                    "kind": "LinkedField",
                    "name": "general_contractor",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "filters": [
          "where",
          "order_by"
        ],
        "handle": "connection",
        "key": "ProjectTable_project_connection",
        "kind": "LinkedHandle",
        "name": "project_connection"
      }
    ]
  },
  "params": {
    "cacheID": "0f4cf84da663b2a857cfba55681de422",
    "id": null,
    "metadata": {},
    "name": "ProjectTableRefetchableQuery",
    "operationKind": "query",
    "text": "query ProjectTableRefetchableQuery(\n  $after: String\n  $first: Int!\n  $order_by: project_order_by!\n  $where: project_bool_exp!\n) {\n  ...ProjectTable_projects_4g04uc\n}\n\nfragment ProjectTable_projects_4g04uc on query_root {\n  project_connection(first: $first, after: $after, where: $where, order_by: [{completed: asc}, $order_by]) {\n    edges {\n      node {\n        pk: id\n        name\n        completed\n        project_employees_aggregate(where: {status: {_eq: \"Active\"}}) {\n          aggregate {\n            count\n          }\n        }\n        gc_division {\n          pk: id\n          id\n          name\n        }\n        gc_business_unit {\n          pk: id\n          id\n          name\n        }\n        gc_office {\n          pk: id\n          id\n          name\n        }\n        general_contractor {\n          name\n          id\n        }\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c638334e801ef11727eeaf606b415499";

export default node;
