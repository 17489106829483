import { useGetIncidentReportAndChartOptionsQuery } from "src/common/types/generated/apollo/graphQLTypes";
import { Flex } from "antd";
import GCAdminIncidentsHeader from "src/root/routes/views/general-contractor/hierarchy/component/incident/header/GCAdminIncidentsHeader";
import useGetIncidentFilters from "src/root/routes/views/general-contractor/hierarchy/component/incident/utils/useGetIncidentFilters";
import GCIncidentCharts from "../components/chart/GCIncidentCharts";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import LargeTableSkeleton from "src/common/components/tables/basic/LargeTableSkeletion";
import { useParams } from "react-router-dom";
import useGetIncidentOptionsQueryVars from "src/root/routes/views/general-contractor/hierarchy/component/incident/utils/useGetIncidentOptionsQueryVars";

const GCProjectIncidentDashboard: React.FC = () => {
  const { projectId } = useParams();
  if (!projectId) throw new Error("projectId not found");

  const optionsQueryVars = useGetIncidentOptionsQueryVars("project", projectId);
  const {
    data: options,
    error: optionsError,
    loading: optionsLoading,
  } = useGetIncidentReportAndChartOptionsQuery({
    variables: optionsQueryVars,
    fetchPolicy: "cache-first",
  });

  if (optionsError) throw optionsError;
  const {
    selectFilters: incidentSelectFilters,
    bodyPartsFilter,
    dateFilter,
    incidentTypesFilter,
    injuryCauseFilter,
    injuryTypesFilter,
    companiesFilter,
    severityFilter,
    setDateFilter,
  } = useGetIncidentFilters(options);
  return (
    <Flex gap={"large"} vertical>
      <GCAdminIncidentsHeader
        reportCreatorButtonProps={{
          options,
          organizationId: projectId,
          organizationLevel: "project",
        }}
        filters={{
          selectFilters: incidentSelectFilters,
          dates: {
            selectedDates: dateFilter,
            onSelectedDatesChange: setDateFilter,
          },
        }}
      />
      <GCIncidentCharts
        projWhere={{ id: { _eq: projectId } }}
        optionsLoading={optionsLoading}
        options={options}
        currentFilters={{
          bodyPartsFilter,
          companiesFilter,
          dateFilter,
          incidentTypesFilter,
          injuryCauseFilter,
          injuryTypesFilter,
          severityFilter,
        }}
      />
    </Flex>
  );
};
export default withCustomSuspense(GCProjectIncidentDashboard, {
  fallback: <LargeTableSkeleton />,
});
