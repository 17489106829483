import { IconTrash } from "@tabler/icons";
import { forwardRef } from "react";
import { Popover } from "antd";
import dayjs from "dayjs";
import DataApolloScrollTable, {
  DataApolloScrollTableImplementorProps,
  DataApolloScrollTableRef,
} from "src/common/components/tables/basic/DataApolloScrollTable";
import Icon from "src/common/components/general/Icon";
import BPopconfirm from "src/common/components/dialogs/BPopconfirm";
import useAuthUser from "src/common/hooks/useAuthUser";
import capitalize from "../../../../common/functions/capitalize";
import {
  GetIncidentsDocument,
  GetIncidentsQuery,
  GetIncidentsQueryVariables,
  useUpdateIncidentMutation,
} from "src/common/types/generated/apollo/graphQLTypes";

export type ColumnKeys =
  | "project"
  | "description"
  | "summary"
  | "incident_time"
  | "status"
  | "action"
  | "days_away"
  | "company"
  | "injured_users"
  | "attachments"
  | "created_by";
type DataType = GetIncidentsQuery["incident"][number];
export type Props = DataApolloScrollTableImplementorProps<
  GetIncidentsQueryVariables,
  ColumnKeys,
  DataType,
  GetIncidentsQuery
> & {
  officeLevelName?: string | null;
};

const IncidentTable = forwardRef<DataApolloScrollTableRef, Props>(
  ({ title = "Project Incidents", onRowClick, where, ...props }, ref) => {
    const [updateIncident] = useUpdateIncidentMutation();
    const authUser = useAuthUser();

    const deleteIncident = (id: string) => {
      updateIncident({
        variables: {
          incidentId: id,
          _set: { deleted_at: dayjs().format() },
          objects: {
            comment: `Incident Deleted`,
            patch: [],
            edited_by_uid: authUser.uid,
            incident_id: id,
            edit_type: "delete",
          },
        },
      });
      if (ref && typeof ref !== "function") {
        ref.current?.refetch();
      }
    };

    const getCompanyOfIncidentUser = (
      user?: DataType["injuredUsers"][number]["user"] | null,
    ) => {
      if (!user) return null;
      return (
        user.worker?.subcontractor?.name ||
        user.employee?.general_contractor?.name ||
        user.general_person?.employer
      );
    };

    return (
      <DataApolloScrollTable<
        GetIncidentsQueryVariables,
        ColumnKeys,
        DataType,
        GetIncidentsQuery
      >
        {...props}
        where={where}
        newCustomTableLook
        ref={ref}
        title={title}
        onRowClick={(item: DataType) => onRowClick?.(item)}
        queryNode={GetIncidentsDocument}
        aggregateCountIndex="incident_aggregate"
        queryDataIndex="incident"
        extraSearchDataIndexes={[
          [{ index: "incident_users" }, { index: "user" }, { index: "name" }],
          [{ index: "project" }, { index: "gc_office" }, { index: "name" }],
          [
            { index: "incident_types" },
            { index: "incident_type" },
            { index: "detail" },
            { index: "en" },
          ],
          [{ index: "submitted_by_user" }, { index: "name" }],
          [{ index: "reported_by_user" }, { index: "name" }],
          [{ index: "supervisor_user" }, { index: "name" }],
          [
            { index: "vehicle_incident" },
            { index: "person_involved" },
            { index: "name" },
          ],
          [
            { index: "vehicle_incident" },
            { index: "vehicle_owner" },
            { index: "name" },
          ],
          [
            { index: "vehicle_incident" },
            { index: "driver" },
            { index: "name" },
          ],
          [
            { index: "property_damage_incident" },
            { index: "property_owner" },
            { index: "name" },
          ],
          [
            { index: "equipment_incident" },
            { index: "operator" },
            { index: "name" },
          ],
          [
            { index: "theft_incident" },
            { index: "stolen_items_owner" },
            { index: "name" },
          ],
        ]}
        columns={[
          {
            title: "Incident Types",
            key: "description",
            dataIndex: ["incident_types"],
            render: (_, row) =>
              row.incident_types
                .map((type) => type.incident_type.detail.en)
                .join(", "),
            size: "lg",
          },
          {
            title:
              "Project" +
              (props.officeLevelName ? `/${props.officeLevelName}` : ""),
            key: "project",
            dataIndex: ["project", "name"],
            render: (v, r) =>
              r.project.gc_office ? `${v}/${r.project.gc_office.name}` : v,
            searchDataIndex: ["project", "name"],
          },
          {
            title: "Incident Date",
            dataIndex: ["incident_time"],
            key: "incident_time",
            size: "lg",
            sortable: true,
            contentType: {
              type: "date",
              renderOptions: () => ({ format: "mmddyyyy" }),
            },
          },
          {
            title: "Created By",
            dataIndex: ["submitted_by_user", "name"],
            key: "created_by",
            sortable: true,
            searchDataIndex: ["submitted_by_user", "name"],
          },
          {
            title: "Injured Persons",
            dataIndex: [],
            key: "injured_users",
            render: (_, row) => {
              const injuredUsers = row.injuredUsers
                .map((incidentUser) => incidentUser.user.name)
                .join(", ");
              return injuredUsers;
            },
          },
          {
            title: "Days Away",
            dataIndex: ["injured_users", "injury_detail"],
            key: "days_away",

            size: "sm",
            render: (_, r) => {
              const maxDaysAway = r.injuredUsers.reduce((maxDaysAway, iu) => {
                const injDetail = iu.injury_detail;
                const daysAway =
                  injDetail?.unable_to_return_to_work &&
                  injDetail.date_back_to_work &&
                  injDetail.date_out_of_work
                    ? Math.abs(
                        dayjs(injDetail.date_out_of_work).diff(
                          dayjs(injDetail.date_back_to_work),
                          "days",
                        ),
                      )
                    : 0;
                return Math.max(daysAway, maxDaysAway);
              }, 0);
              return maxDaysAway || "";
            },
          },
          {
            title: "Company",
            key: "company",
            dataIndex: [],

            render: (_, row) => {
              const operatorCompany = getCompanyOfIncidentUser(
                row.equipment_incident?.operator,
              );
              const propertyOwnerCompany = getCompanyOfIncidentUser(
                row.property_damage_incident?.property_owner,
              );
              const vehicleOwnerCompany = getCompanyOfIncidentUser(
                row.vehicle_incident?.vehicle_owner,
              );
              const driverCompany = getCompanyOfIncidentUser(
                row.vehicle_incident?.driver,
              );
              const personInvolvedCompany = getCompanyOfIncidentUser(
                row.vehicle_incident?.person_involved,
              );
              const supervisorCompany = getCompanyOfIncidentUser(
                row.supervisor_user,
              );

              const injuredUsersCompanies = row.injuredUsers
                .map((injuredUser) => {
                  const userCompany =
                    injuredUser.user.role === "worker"
                      ? injuredUser.user?.worker?.subcontractor?.name
                      : injuredUser.user.role === "employee"
                      ? injuredUser.user.employee?.general_contractor.name
                      : injuredUser.user?.general_person?.employer;
                  return userCompany || "";
                })
                .filter(Boolean);

              const uniqueCompanies = new Set(
                [
                  supervisorCompany,
                  personInvolvedCompany,
                  driverCompany,
                  vehicleOwnerCompany,
                  propertyOwnerCompany,
                  operatorCompany,
                  ...injuredUsersCompanies,
                ].filter(Boolean),
              );

              return uniqueCompanies.size > 1
                ? "Multiple"
                : Array.from(uniqueCompanies).at(0) || "";
            },
          },
          {
            title: "Summary",
            dataIndex: ["summary", "en"],
            key: "summary",
            size: "lg",
            searchDataIndex: ["description", "en"],
          },

          {
            title: "Attachments",
            dataIndex: [],
            key: "attachments",
            render: (_, row) => {
              let attachments =
                row.attached_files_aggregate.aggregate?.count ?? 0;

              attachments += row.incident_types.reduce((agg, incidentType) => {
                agg += incidentType.documents_aggregate.aggregate?.count ?? 0;
                return agg;
              }, 0);

              attachments += row.incident_users.reduce((agg, incidentUser) => {
                agg +=
                  incidentUser.injury_detail?.attached_files_aggregate.aggregate
                    ?.count ?? 0;
                agg +=
                  incidentUser.statement_detail?.attached_files_aggregate
                    .aggregate?.count ?? 0;
                return agg;
              }, 0);

              attachments +=
                row.root_cause_analysis?.document_aggregate.aggregate?.count ??
                0;

              return attachments;
            },
          },
          {
            title: "Status",
            dataIndex: ["status"],
            sortable: true,
            key: "status",
            size: "lg",
            defaultSortOrder: "desc",
            render: (_, row) => {
              const status = capitalize(
                row.status === "pending" ? "In-progress" : row.status ?? "",
              );
              return (
                <Popover
                  trigger={"hover"}
                  content={
                    status === "Open" ? (
                      <div> Additional details or information required</div>
                    ) : status === "In-progress" ? (
                      <div className="w-20">
                        All general details or information have been provided.
                        In-Progress incidents are following cases that have not
                        been closed, like a worker that has not returned to work
                      </div>
                    ) : (
                      ""
                    )
                  }
                >
                  {status}
                </Popover>
              );
            },
            searchDataIndex: ["description", "en"],
          },
          {
            title: "",
            dataIndex: [],
            key: "action",
            render: (_, row) => {
              return (
                <div
                  className="cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <BPopconfirm
                    onConfirm={() => {
                      deleteIncident(row.id);
                    }}
                    title="Delete this Incident"
                  >
                    <Icon icon={IconTrash} color="negative" />
                  </BPopconfirm>
                </div>
              );
            },
          },
        ]}
      />
    );
  },
);

export default IncidentTable;
