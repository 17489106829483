import { Form, Input, message, notification, Select, Spin } from "antd";
import { Dayjs } from "dayjs";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import FModal, { FModalRef } from "src/common/components/dialogs/FModal";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import {
  useInsertWorkerOneMutation,
  GetCrewGroupedWorkerListQuery,
  useInsertProjectWorkersMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import getNormalSelectOptionsFilter from "src/common/functions/getNormalSelectOptionsFilter";

interface FormValues {
  hardHatNumber?: string;
  name?: string;
  workerRole?: string;
  subcontractor?: string;
  birthDate?: Dayjs;
  titleId?: string;
  phoneNumber?: string;
  email?: string;
  username?: string;
}

interface CreateProjectWorkerModalProps {
  projectId: string;
  subcontractorId?: string;
  workersData?: GetCrewGroupedWorkerListQuery;
  roleOptions: Array<{ label: string; value: string }>;
  titleOptions: Array<{ label: string; value: string }>;
  subOptions?: Array<{ label: string; value: string }>;
}

export type CreateProjectWorkerModalRef = FModalRef<FormValues> | null;

const CreateProjectWorkerModal = forwardRef<
  CreateProjectWorkerModalRef,
  CreateProjectWorkerModalProps
>(
  (
    {
      projectId,
      subcontractorId,
      workersData,
      roleOptions,
      titleOptions,
      subOptions,
    },
    ref,
  ) => {
    const modal = useRef<FModalRef<FormValues>>(null);
    const [loading, setLoading] = useState(false);
    const [insertProjectWorkers] = useInsertProjectWorkersMutation();
    const [gettingUsername, setGettingUsername] = useState(false);
    const [insertWorker] = useInsertWorkerOneMutation();

    useImperativeHandle<
      CreateProjectWorkerModalRef,
      CreateProjectWorkerModalRef
    >(ref, () => modal.current);
    const reset = () => {
      setLoading(false);
      modal.current?.form.resetFields();
      modal.current?.close();
    };

    return (
      <FModal
        ref={modal}
        // visible={visible}
        title="Create a new worker"
        okText="Add"
        cancelText="Cancel"
        onCancel={() => {
          reset();
        }}
        onOk={async () => {
          const form = modal.current?.form;
          if (!form) return;
          form.validateFields().then(async (values) => {
            setLoading(true);
            await insertProjectWorkers({
              variables: {
                objects: [
                  {
                    project_id: projectId,
                    worker_role: values.workerRole,
                    title_id: values.titleId,
                    subcontractor_id: subcontractorId ?? values.subcontractor,
                    hard_hat_number: values.hardHatNumber,
                    worker: {
                      data: {
                        current_worker_role: values.workerRole,
                        worker_title_id: values.titleId,
                        subcontractor_id:
                          subcontractorId ?? values.subcontractor,
                        user: {
                          data: {
                            role: "worker",
                            name: values.name,
                            allow_reset_password: true,
                            phone_number_privacy_setting: { data: {} },
                            email_privacy_setting: { data: {} },
                            project_privacy_setting: { data: {} },
                          },
                        },
                      },
                    },
                  },
                ],
              },

              update(cache, result) {
                if (!workersData) return;
                const projectWorker =
                  result.data?.insert_project_worker?.returning.at(0);
                if (!projectWorker) return;

                cache.modify<typeof workersData>({
                  fields: {
                    project_worker: (existingWorkers) => {
                      const newWorkers = [...existingWorkers, projectWorker];
                      return newWorkers;
                    },
                  },
                });
              },
              onCompleted: (data) => {
                reset();
                message.success("Done");
              },
              onError: (e: Error) => {
                notification.error({
                  message: "Error: ",
                  description: e.message,
                  duration: null,
                });
              },
            });
          });
        }}
      >
        {/* <p>
        New workers will be prompted to create a password for themselves on the
        SiteForm app if it is their first time logging in.
      </p> */}
        <Form.Item
          name="name"
          label="First and last name"
          rules={[{ required: true, message: "Enter the name" }]}
        >
          <Input />
        </Form.Item>

        {!subcontractorId && (
          <Form.Item
            name="subcontractor"
            label="Subcontractor"
            rules={[{ required: true, message: `Choose the subcontractor` }]}
          >
            <Select
              style={{ width: "100%" }}
              options={subOptions}
              filterOption={getNormalSelectOptionsFilter}
              showSearch
            ></Select>
          </Form.Item>
        )}

        {/* <Form.Item
          name="hardHatNumber"
          label="Hard Hat Number"
          rules={[{ message: "Enter the Hard Hat Number" }]}
        >
          <Input />
        </Form.Item> */}
        <Form.Item
          name="workerRole"
          label="Worker current role"
          rules={[
            { required: true, message: `Choose the worker's current role` },
          ]}
        >
          <Select style={{ width: "100%" }} options={roleOptions}></Select>
        </Form.Item>
        <Form.Item
          name="titleId"
          label="Worker trade"
          rules={[
            { required: true, message: `Choose the worker's current trade` },
          ]}
        >
          <Select
            style={{ width: "100%" }}
            options={titleOptions}
            filterOption={getNormalSelectOptionsFilter}
            showSearch
          ></Select>
        </Form.Item>
        {gettingUsername && <Spin />}
      </FModal>
    );
  },
);

export default withCustomSuspense(CreateProjectWorkerModal);
