import {
  GetIncidentReportAndChartOptionsQueryVariables,
  Project_Bool_Exp,
} from "src/common/types/generated/apollo/graphQLTypes";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";
import getOrgProjectsWhere from "../../../utils/getOrgProjectsWhere";

const useGetIncidentOptionsQueryVars = (
  organizationLevel: string,
  unitId: string,
): GetIncidentReportAndChartOptionsQueryVariables => {
  const { userData } = useUserData();
  const employee = userData.employee;
  const projWhere = getOrgProjectsWhere(organizationLevel, unitId);
  if (!employee) throw new Error("employee not found");
  const gc = employee.general_contractor;
  switch (organizationLevel) {
    case "gc":
      return {
        projWhere,
        gcBuWhere: gc.hierarchy_business_unit_name
          ? { general_contractor_id: { _eq: gc.id } }
          : { id: { _is_null: true } },
        gcDivisionWhere: gc.hierarchy_division_name
          ? { general_contractor_id: { _eq: gc.id } }
          : { id: { _is_null: true } },
        gcOfficeWhere: gc.hierarchy_office_name
          ? { general_contractor_id: { _eq: gc.id } }
          : { id: { _is_null: true } },
      };
    case "division":
      return {
        projWhere,
        gcDivisionWhere: { id: { _is_null: true } },
        gcBuWhere: gc.hierarchy_business_unit_name
          ? { gc_division_id: { _eq: unitId } }
          : { id: { _is_null: true } },
        gcOfficeWhere: gc.hierarchy_office_name
          ? { gc_division_id: { _eq: unitId } }
          : { id: { _is_null: true } },
      };

    case "business_unit":
      return {
        projWhere,
        gcBuWhere: { id: { _is_null: true } },
        gcDivisionWhere: { id: { _is_null: true } },
        gcOfficeWhere: gc.hierarchy_office_name
          ? { gc_business_unit_id: { _eq: unitId } }
          : { id: { _is_null: true } },
      };
    case "office":
      return {
        projWhere,
        gcBuWhere: { id: { _is_null: true } },
        gcOfficeWhere: { id: { _is_null: true } },
        gcDivisionWhere: { id: { _is_null: true } },
      };
    case "project":
      return {
        projWhere,
        gcBuWhere: { id: { _is_null: true } },
        gcOfficeWhere: { id: { _is_null: true } },
        gcDivisionWhere: { id: { _is_null: true } },
      };
    default:
      throw new Error("proper org level not found");
  }
};
export default useGetIncidentOptionsQueryVars;
