/**
 * @generated SignedSource<<5a5669f6a3a9f14d7ffc2c45d84ef1b0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { delivery_insert_input, user_project_filter_calendar_bool_exp } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SitedeliveryAddNewDeliveryModalMutation$variables = {
  deleteFilterCalendarWhere: user_project_filter_calendar_bool_exp;
  object: delivery_insert_input;
};
export type SitedeliveryAddNewDeliveryModalMutation$data = {
  readonly delete_user_project_filter_calendar: {
    readonly returning: ReadonlyArray<{
      readonly calendar_id: string;
      readonly id: string;
    }>;
  } | null | undefined;
  readonly insert_delivery_one: {
    readonly approved_at: string | null | undefined;
    readonly approved_by: {
      readonly id: string;
      readonly name: string;
      readonly pk: string;
    } | null | undefined;
    readonly calendars: ReadonlyArray<{
      readonly calendar: {
        readonly color_hex: string;
        readonly id: string;
        readonly name: {
          readonly en: string;
        };
        readonly pk: string;
      };
    }>;
    readonly created_at: string;
    readonly detail: {
      readonly en: string;
      readonly id: string;
      readonly pk: string;
    } | null | undefined;
    readonly duration: number;
    readonly id: string;
    readonly name: {
      readonly en: string;
      readonly id: string;
      readonly pk: string;
    };
    readonly pk: string;
    readonly remark: {
      readonly en: string;
      readonly id: string;
      readonly pk: string;
    } | null | undefined;
    readonly start_at: string;
    readonly status: string | null | undefined;
    readonly storage_location: {
      readonly en: string;
      readonly id: string;
      readonly pk: string;
    } | null | undefined;
    readonly subcontractor: {
      readonly id: string;
      readonly name: string;
    } | null | undefined;
    readonly user: {
      readonly id: string;
      readonly name: string;
      readonly pk: string;
    };
  } | null | undefined;
};
export type SitedeliveryAddNewDeliveryModalMutation = {
  response: SitedeliveryAddNewDeliveryModalMutation$data;
  variables: SitedeliveryAddNewDeliveryModalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deleteFilterCalendarWhere"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "object"
},
v2 = [
  {
    "kind": "Variable",
    "name": "object",
    "variableName": "object"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "calendar_id": "asc"
    }
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "en",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "color_hex",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "subcontractor",
  "kind": "LinkedField",
  "name": "subcontractor",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v9/*: any*/)
  ],
  "storageKey": null
},
v11 = [
  (v3/*: any*/),
  (v4/*: any*/),
  (v6/*: any*/)
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "start_at",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "duration",
  "storageKey": null
},
v14 = [
  (v3/*: any*/),
  (v4/*: any*/),
  (v9/*: any*/)
],
v15 = [
  (v3/*: any*/),
  (v6/*: any*/),
  (v4/*: any*/)
],
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "approved_at",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "where",
      "variableName": "deleteFilterCalendarWhere"
    }
  ],
  "concreteType": "user_project_filter_calendar_mutation_response",
  "kind": "LinkedField",
  "name": "delete_user_project_filter_calendar",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "user_project_filter_calendar",
      "kind": "LinkedField",
      "name": "returning",
      "plural": true,
      "selections": [
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "calendar_id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v19 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v20 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
},
v21 = [
  (v3/*: any*/),
  (v19/*: any*/),
  (v20/*: any*/),
  (v6/*: any*/)
],
v22 = [
  (v3/*: any*/),
  (v19/*: any*/),
  (v20/*: any*/),
  (v9/*: any*/)
],
v23 = [
  (v3/*: any*/),
  (v6/*: any*/),
  (v19/*: any*/),
  (v20/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SitedeliveryAddNewDeliveryModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "delivery",
        "kind": "LinkedField",
        "name": "insert_delivery_one",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "delivery_calendar",
            "kind": "LinkedField",
            "name": "calendars",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "calendar",
                "kind": "LinkedField",
                "name": "calendar",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "text_translation",
                    "kind": "LinkedField",
                    "name": "name",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": "calendars(order_by:{\"calendar_id\":\"asc\"})"
          },
          (v8/*: any*/),
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "text_translation",
            "kind": "LinkedField",
            "name": "detail",
            "plural": false,
            "selections": (v11/*: any*/),
            "storageKey": null
          },
          (v12/*: any*/),
          (v13/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "user",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": (v14/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "text_translation",
            "kind": "LinkedField",
            "name": "name",
            "plural": false,
            "selections": (v15/*: any*/),
            "storageKey": null
          },
          (v16/*: any*/),
          (v17/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "user",
            "kind": "LinkedField",
            "name": "approved_by",
            "plural": false,
            "selections": (v14/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "text_translation",
            "kind": "LinkedField",
            "name": "remark",
            "plural": false,
            "selections": (v15/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "text_translation",
            "kind": "LinkedField",
            "name": "storage_location",
            "plural": false,
            "selections": (v11/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v18/*: any*/)
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SitedeliveryAddNewDeliveryModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "delivery",
        "kind": "LinkedField",
        "name": "insert_delivery_one",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "delivery_calendar",
            "kind": "LinkedField",
            "name": "calendars",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "calendar",
                "kind": "LinkedField",
                "name": "calendar",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "text_translation",
                    "kind": "LinkedField",
                    "name": "name",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": "calendars(order_by:{\"calendar_id\":\"asc\"})"
          },
          (v8/*: any*/),
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "text_translation",
            "kind": "LinkedField",
            "name": "detail",
            "plural": false,
            "selections": (v21/*: any*/),
            "storageKey": null
          },
          (v12/*: any*/),
          (v13/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "user",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": (v22/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "text_translation",
            "kind": "LinkedField",
            "name": "name",
            "plural": false,
            "selections": (v23/*: any*/),
            "storageKey": null
          },
          (v16/*: any*/),
          (v17/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "user",
            "kind": "LinkedField",
            "name": "approved_by",
            "plural": false,
            "selections": (v22/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "text_translation",
            "kind": "LinkedField",
            "name": "remark",
            "plural": false,
            "selections": (v23/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "text_translation",
            "kind": "LinkedField",
            "name": "storage_location",
            "plural": false,
            "selections": (v21/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v18/*: any*/)
    ]
  },
  "params": {
    "cacheID": "3213b75064d2e063ffdb85d0b29fef54",
    "id": null,
    "metadata": {},
    "name": "SitedeliveryAddNewDeliveryModalMutation",
    "operationKind": "mutation",
    "text": "mutation SitedeliveryAddNewDeliveryModalMutation(\n  $object: delivery_insert_input!\n  $deleteFilterCalendarWhere: user_project_filter_calendar_bool_exp!\n) {\n  insert_delivery_one(object: $object) {\n    id\n    pk: id\n    calendars(order_by: {calendar_id: asc}) {\n      calendar {\n        id\n        pk: id\n        name {\n          en\n          id\n        }\n        color_hex\n      }\n      id\n    }\n    status\n    subcontractor {\n      id\n      name\n    }\n    detail {\n      id\n      pk: id\n      en\n    }\n    start_at\n    duration\n    user {\n      id\n      pk: id\n      name\n    }\n    name {\n      id\n      en\n      pk: id\n    }\n    created_at\n    approved_at\n    approved_by {\n      id\n      pk: id\n      name\n    }\n    remark {\n      id\n      en\n      pk: id\n    }\n    storage_location {\n      id\n      pk: id\n      en\n    }\n  }\n  delete_user_project_filter_calendar(where: $deleteFilterCalendarWhere) {\n    returning {\n      id\n      calendar_id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d7b92c3e297eb4f6ebdf7f68bb52f53e";

export default node;
