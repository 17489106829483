/**
 * @generated SignedSource<<d07bce4bd0d8d512be69578fa2cf7ca3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { order_by, project_site_notification_bool_exp, project_site_notification_order_by } from './types';
export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
export type GCProjectSiteAlertsQuery$variables = {
  order_by?: ReadonlyArray<project_site_notification_order_by> | null | undefined;
  projectId: string;
  where?: project_site_notification_bool_exp | null | undefined;
};
export type GCProjectSiteAlertsQuery$data = {
  readonly project_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly billing_settings: {
          readonly text_messaging_enabled: boolean;
          readonly use_project_level_price: boolean;
        } | null | undefined;
        readonly project_twilio_numbers: ReadonlyArray<{
          readonly phone_number: string;
        }>;
        readonly timezone: string;
      };
    }>;
  };
  readonly project_site_notification_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly created_at: string;
        readonly id: string;
        readonly images: ReadonlyArray<{
          readonly id: string;
        }>;
        readonly is_twilio_reply: boolean | null | undefined;
        readonly message: {
          readonly clientText: string;
          readonly en: string;
          readonly es: string;
          readonly id: string;
          readonly original: string;
          readonly pk: string;
          readonly pt: string;
        };
        readonly message_text: string;
        readonly project: {
          readonly id: string;
          readonly name: string;
        };
        readonly send_notification: boolean;
        readonly send_sms: boolean;
        readonly sms_send_count: number;
      };
    }>;
  };
};
export type GCProjectSiteAlertsQuery = {
  response: GCProjectSiteAlertsQuery$data;
  variables: GCProjectSiteAlertsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": {},
  "kind": "LocalArgument",
  "name": "order_by"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v2 = {
  "defaultValue": {},
  "kind": "LocalArgument",
  "name": "where"
},
v3 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "projectId"
          }
        ],
        "kind": "ObjectValue",
        "name": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timezone",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text_messaging_enabled",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "use_project_level_price",
  "storageKey": null
},
v7 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "use_type": {
        "_eq": "site-alert"
      },
      "verification_status": {
        "_eq": "twilio_approved"
      }
    }
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phone_number",
  "storageKey": null
},
v9 = [
  {
    "kind": "Variable",
    "name": "order_by",
    "variableName": "order_by"
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where"
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "project",
  "kind": "LinkedField",
  "name": "project",
  "plural": false,
  "selections": [
    (v10/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "is_twilio_reply",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sms_send_count",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "image",
  "kind": "LinkedField",
  "name": "images",
  "plural": true,
  "selections": [
    (v10/*: any*/)
  ],
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "send_sms",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "send_notification",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "en",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "es",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pt",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "original",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message_text",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GCProjectSiteAlertsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "projectConnection",
        "kind": "LinkedField",
        "name": "project_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "projectEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "project_billing_settings",
                    "kind": "LinkedField",
                    "name": "billing_settings",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v7/*: any*/),
                    "concreteType": "twilio_number",
                    "kind": "LinkedField",
                    "name": "project_twilio_numbers",
                    "plural": true,
                    "selections": [
                      (v8/*: any*/)
                    ],
                    "storageKey": "project_twilio_numbers(where:{\"use_type\":{\"_eq\":\"site-alert\"},\"verification_status\":{\"_eq\":\"twilio_approved\"}})"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "project_site_notificationConnection",
        "kind": "LinkedField",
        "name": "project_site_notification_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_site_notificationEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project_site_notification",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "text_translation",
                    "kind": "LinkedField",
                    "name": "message",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/),
                      (v19/*: any*/),
                      (v20/*: any*/),
                      {
                        "alias": "pk",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id_pk",
                        "storageKey": null
                      },
                      {
                        "alias": "clientText",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__original_clientText",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v21/*: any*/),
                  (v22/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "GCProjectSiteAlertsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "projectConnection",
        "kind": "LinkedField",
        "name": "project_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "projectEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "project_billing_settings",
                    "kind": "LinkedField",
                    "name": "billing_settings",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v7/*: any*/),
                    "concreteType": "twilio_number",
                    "kind": "LinkedField",
                    "name": "project_twilio_numbers",
                    "plural": true,
                    "selections": [
                      (v8/*: any*/),
                      (v10/*: any*/)
                    ],
                    "storageKey": "project_twilio_numbers(where:{\"use_type\":{\"_eq\":\"site-alert\"},\"verification_status\":{\"_eq\":\"twilio_approved\"}})"
                  },
                  (v10/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "project_site_notificationConnection",
        "kind": "LinkedField",
        "name": "project_site_notification_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_site_notificationEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project_site_notification",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "text_translation",
                    "kind": "LinkedField",
                    "name": "message",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/),
                      (v19/*: any*/),
                      (v20/*: any*/),
                      {
                        "alias": "pk",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": "pk",
                        "args": null,
                        "filters": null,
                        "handle": "pk",
                        "key": "",
                        "kind": "ScalarHandle",
                        "name": "id"
                      },
                      {
                        "alias": "clientText",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "original",
                        "storageKey": null
                      },
                      {
                        "alias": "clientText",
                        "args": null,
                        "filters": null,
                        "handle": "clientText",
                        "key": "",
                        "kind": "ScalarHandle",
                        "name": "original"
                      }
                    ],
                    "storageKey": null
                  },
                  (v21/*: any*/),
                  (v22/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "78b5ba6956fff05e28771060e2c3056e",
    "id": null,
    "metadata": {},
    "name": "GCProjectSiteAlertsQuery",
    "operationKind": "query",
    "text": "query GCProjectSiteAlertsQuery(\n  $projectId: uuid!\n  $order_by: [project_site_notification_order_by!] = {}\n  $where: project_site_notification_bool_exp = {}\n) {\n  project_connection(where: {id: {_eq: $projectId}}) {\n    edges {\n      node {\n        timezone\n        billing_settings {\n          text_messaging_enabled\n          use_project_level_price\n          id\n        }\n        project_twilio_numbers(where: {use_type: {_eq: \"site-alert\"}, verification_status: {_eq: \"twilio_approved\"}}) {\n          phone_number\n          id\n        }\n        id\n      }\n    }\n  }\n  project_site_notification_connection(order_by: $order_by, where: $where) {\n    edges {\n      node {\n        id\n        project {\n          id\n          name\n        }\n        is_twilio_reply\n        sms_send_count\n        images {\n          id\n        }\n        send_sms\n        send_notification\n        message {\n          id\n          en\n          es\n          pt\n          original\n          pk: id\n          clientText: original\n        }\n        message_text\n        created_at\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "79200a19f72345c2f34e1eab7409541c";

export default node;
