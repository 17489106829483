import {
  General_Contractor_Employee_Bool_Exp,
  GetAdminObservationOptionsQueryVariables,
  Project_Bool_Exp,
} from "src/common/types/generated/apollo/graphQLTypes";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import { OrganizationLevelName } from "../../utils/hierarchyTypes";
import CustomTabs, {
  CustomTabsProps,
} from "src/common/components/layouts/tabs/CustomTabs";
import { useSearchParams } from "react-router-dom";
import GCObsLeaderboard from "./GCObsLeaderboard";
import GCAdminObsDashboard from "./GCAdminObsDashboard";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";
import getOrgEmployeeWhere from "../../utils/getOrgEmployeeWhere";

const GCAdminObservations: React.FC<{
  organizationLevel: Exclude<OrganizationLevelName, "project">;
  organizationId: string;
  projWhere: Project_Bool_Exp;
  onSettingsClick?: () => void;
  optionsQueryVars: Omit<
    GetAdminObservationOptionsQueryVariables,
    "includeProjSetting"
  >;
}> = ({
  organizationLevel,
  organizationId,
  optionsQueryVars,
  projWhere,
  onSettingsClick,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { userData } = useUserData();
  const employee = userData.employee;
  const gcEmployeeWhere = getOrgEmployeeWhere(
    organizationLevel,
    organizationId,
  );

  if (!employee) throw new Error("Logged in employee not found");
  const subview = searchParams.get("subview") || "dashboard";
  const handleReportsTabClick = (tabId: string) => {
    setSearchParams({ subview: tabId });
  };

  const tabs: CustomTabsProps["tabs"] = [
    {
      id: "dashboard",
      content: (
        <GCAdminObsDashboard
          {...{
            optionsQueryVars,
            organizationId,
            organizationLevel,
            projWhere,
            onSettingsClick,
          }}
        />
      ),
      label: "Dashboard",
    },
    {
      id: "leaderboard",
      content: (
        <GCObsLeaderboard
          {...{
            gcEmployeeWhere,
            projWhere,
            organization_level: organizationLevel,
            organization_unit_id: organizationId,
            gcId: employee.general_contractor.id,
          }}
        />
      ),
      label: "Leaderboard",
    },
  ];
  return (
    <BasicWrapper scrollable>
      <CustomTabs
        onTabSelect={handleReportsTabClick}
        tabs={tabs}
        selectedTabId={subview}
      />
    </BasicWrapper>
  );
};
export default GCAdminObservations;
