import { DatePicker } from "antd";
import React, { useState } from "react";
import dayjs from "dayjs";

interface IncidentDatepickerProps {
  label: string;
  value?: string;
  format?: string;
  showTime?: boolean;
  disabled?: boolean;
  onChange: (date: string) => Promise<void>;
}

const IncidentDatepicker: React.FC<IncidentDatepickerProps> = ({
  label,
  value,
  format,
  showTime,
  disabled,
  onChange,
}) => {
  const [newValue, setNewValue] = useState<string | undefined>(value);

  const handleDateChange = async (time: dayjs.Dayjs | null) => {
    if (time) {
      const formattedDate = time.format();
      setNewValue(formattedDate);
      await onChange(formattedDate);
    }
  };

  return (
    <div className="flex gap-1 mt-1 items-center">
      <div className="w-10">{label}</div>
      <div className="w-full relative">
        <DatePicker
          format={format ?? "YYYY-MM-DD"}
          showTime={showTime}
          maxDate={dayjs()}
          value={newValue ? dayjs(newValue) : value ? dayjs(value) : undefined}
          onChange={handleDateChange}
          className="w-full"
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default IncidentDatepicker;
