/**
 * @generated SignedSource<<103038f8d24a320031d0ca92fe501f83>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { project_delivery_sub_block_set_input, sub_block_calendar_bool_exp, sub_block_calendar_insert_input, sub_block_subcontractor_bool_exp, sub_block_subcontractor_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SubBlockEditModalMutation$variables = {
  deleteSubBlockCalendarWhere: sub_block_calendar_bool_exp;
  deleteSubBlockSubWhere: sub_block_subcontractor_bool_exp;
  insertSubBlockCalendarObjects: ReadonlyArray<sub_block_calendar_insert_input>;
  insertSubBlockSubObjects: ReadonlyArray<sub_block_subcontractor_insert_input>;
  sub_block_id: string;
  updateSubBlockSet: project_delivery_sub_block_set_input;
};
export type SubBlockEditModalMutation$data = {
  readonly delete_sub_block_calendar: {
    readonly returning: ReadonlyArray<{
      readonly id: string;
    }>;
  } | null | undefined;
  readonly delete_sub_block_subcontractor: {
    readonly returning: ReadonlyArray<{
      readonly id: string;
    }>;
  } | null | undefined;
  readonly insert_sub_block_calendar: {
    readonly returning: ReadonlyArray<{
      readonly calendar: {
        readonly color_hex: string;
        readonly id: string;
        readonly name: {
          readonly en: string;
        };
        readonly pk: string;
      };
    }>;
  } | null | undefined;
  readonly insert_sub_block_subcontractor: {
    readonly returning: ReadonlyArray<{
      readonly subcontractor: {
        readonly id: string;
        readonly name: string;
        readonly pk: string;
      };
    }>;
  } | null | undefined;
  readonly update_project_delivery_sub_block_by_pk: {
    readonly end_time: string;
    readonly id: string;
    readonly pk: string;
    readonly start_time: string;
    readonly weekday: number;
  } | null | undefined;
};
export type SubBlockEditModalMutation = {
  response: SubBlockEditModalMutation$data;
  variables: SubBlockEditModalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deleteSubBlockCalendarWhere"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deleteSubBlockSubWhere"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "insertSubBlockCalendarObjects"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "insertSubBlockSubObjects"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sub_block_id"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "updateSubBlockSet"
},
v6 = [
  {
    "kind": "Variable",
    "name": "_set",
    "variableName": "updateSubBlockSet"
  },
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "sub_block_id"
      }
    ],
    "kind": "ObjectValue",
    "name": "pk_columns"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "start_time",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "end_time",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "weekday",
  "storageKey": null
},
v12 = [
  {
    "kind": "Variable",
    "name": "objects",
    "variableName": "insertSubBlockSubObjects"
  }
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v14 = [
  {
    "kind": "Variable",
    "name": "objects",
    "variableName": "insertSubBlockCalendarObjects"
  }
],
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "en",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "color_hex",
  "storageKey": null
},
v17 = [
  (v7/*: any*/)
],
v18 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "where",
      "variableName": "deleteSubBlockCalendarWhere"
    }
  ],
  "concreteType": "sub_block_calendar_mutation_response",
  "kind": "LinkedField",
  "name": "delete_sub_block_calendar",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "sub_block_calendar",
      "kind": "LinkedField",
      "name": "returning",
      "plural": true,
      "selections": (v17/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "where",
      "variableName": "deleteSubBlockSubWhere"
    }
  ],
  "concreteType": "sub_block_subcontractor_mutation_response",
  "kind": "LinkedField",
  "name": "delete_sub_block_subcontractor",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "sub_block_subcontractor",
      "kind": "LinkedField",
      "name": "returning",
      "plural": true,
      "selections": (v17/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v20 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v21 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SubBlockEditModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "project_delivery_sub_block",
        "kind": "LinkedField",
        "name": "update_project_delivery_sub_block_by_pk",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v12/*: any*/),
        "concreteType": "sub_block_subcontractor_mutation_response",
        "kind": "LinkedField",
        "name": "insert_sub_block_subcontractor",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "sub_block_subcontractor",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "subcontractor",
                "kind": "LinkedField",
                "name": "subcontractor",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v13/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v14/*: any*/),
        "concreteType": "sub_block_calendar_mutation_response",
        "kind": "LinkedField",
        "name": "insert_sub_block_calendar",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "sub_block_calendar",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "calendar",
                "kind": "LinkedField",
                "name": "calendar",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "text_translation",
                    "kind": "LinkedField",
                    "name": "name",
                    "plural": false,
                    "selections": [
                      (v15/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v16/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v18/*: any*/),
      (v19/*: any*/)
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v5/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "SubBlockEditModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "project_delivery_sub_block",
        "kind": "LinkedField",
        "name": "update_project_delivery_sub_block_by_pk",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v12/*: any*/),
        "concreteType": "sub_block_subcontractor_mutation_response",
        "kind": "LinkedField",
        "name": "insert_sub_block_subcontractor",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "sub_block_subcontractor",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "subcontractor",
                "kind": "LinkedField",
                "name": "subcontractor",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v13/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v14/*: any*/),
        "concreteType": "sub_block_calendar_mutation_response",
        "kind": "LinkedField",
        "name": "insert_sub_block_calendar",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "sub_block_calendar",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "calendar",
                "kind": "LinkedField",
                "name": "calendar",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "text_translation",
                    "kind": "LinkedField",
                    "name": "name",
                    "plural": false,
                    "selections": [
                      (v15/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v16/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v18/*: any*/),
      (v19/*: any*/)
    ]
  },
  "params": {
    "cacheID": "1560f96ae666d23fe0c69aff716c412d",
    "id": null,
    "metadata": {},
    "name": "SubBlockEditModalMutation",
    "operationKind": "mutation",
    "text": "mutation SubBlockEditModalMutation(\n  $sub_block_id: uuid!\n  $updateSubBlockSet: project_delivery_sub_block_set_input!\n  $insertSubBlockSubObjects: [sub_block_subcontractor_insert_input!]!\n  $insertSubBlockCalendarObjects: [sub_block_calendar_insert_input!]!\n  $deleteSubBlockCalendarWhere: sub_block_calendar_bool_exp!\n  $deleteSubBlockSubWhere: sub_block_subcontractor_bool_exp!\n) {\n  update_project_delivery_sub_block_by_pk(pk_columns: {id: $sub_block_id}, _set: $updateSubBlockSet) {\n    id\n    pk: id\n    start_time\n    end_time\n    weekday\n  }\n  insert_sub_block_subcontractor(objects: $insertSubBlockSubObjects) {\n    returning {\n      subcontractor {\n        id\n        pk: id\n        name\n      }\n      id\n    }\n  }\n  insert_sub_block_calendar(objects: $insertSubBlockCalendarObjects) {\n    returning {\n      calendar {\n        id\n        pk: id\n        name {\n          en\n          id\n        }\n        color_hex\n      }\n      id\n    }\n  }\n  delete_sub_block_calendar(where: $deleteSubBlockCalendarWhere) {\n    returning {\n      id\n    }\n  }\n  delete_sub_block_subcontractor(where: $deleteSubBlockSubWhere) {\n    returning {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1af4846aa5fe58fd12a65922884af9a9";

export default node;
