import { DownloadOutlined } from "@ant-design/icons";
import {
  message,
  Button,
  DatePicker,
  Drawer,
  Form,
  Select,
  Card,
  Switch,
} from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";
import MorningManPowerEmail from "src/common/components/MorningManPowerReportEmail";
import downloadFromUrl from "src/common/functions/downloadFromUrl";
import { useManPowerReportDownloadMutation } from "src/common/types/generated/apollo/graphQLTypes";
import { GCProjectReportsDownloadQuery$data } from "src/common/types/generated/relay/GCProjectReportsDownloadQuery.graphql";
import MorningManPowerReportDownloadAuto from "../MorningManPowerReportDownloadAuto";

const MorningManPowerReportCreator: React.FC<{
  visible: boolean;
  handleClose: () => void;
  projectId: string;
  data: GCProjectReportsDownloadQuery$data;
}> = ({ visible, handleClose, projectId, data }) => {
  const [form] = Form.useForm();
  const [date, setDate] = useState<dayjs.Dayjs | null>(dayjs());
  const today = dayjs();

  const [manPowerReportDownload, { loading: manPowerReportLoading }] =
    useManPowerReportDownloadMutation();

  const selectedUsers = data.email_project_user_connection.edges;
  const projectEmployees = data.project_employee_connection.edges;
  const projectData = data.project_connection.edges[0].node;
  const projSetting = projectData.project_setting;
  if (!projSetting) throw new Error("project setting not found");
  const subcontractorData = data.project_subcontractor_connection.edges;
  const subIds = new Array<string>();
  subcontractorData.map((t) => {
    subIds.push(t.node.subcontractor_id);
  });

  const disabledDate = (current: Dayjs) => {
    return current > today;
  };

  const onOpenChange = (open: boolean) => {
    if (open) {
      setDate(null);
    }
  };

  const handleDownload = async () => {
    const values = await form.validateFields();
    let subIdsToSend = values.subId === "all" ? subIds : [values.subId];

    if (!values.downloadDate) {
      message.error("Download Date not found");
      return;
    }
    const res = await manPowerReportDownload({
      variables: {
        input: {
          projectId,
          subcontractorIds: subIdsToSend,
          onsiteDate: values.downloadDate,
          includeAllSubs: values.includeAllSubs,
        },
      },
    });

    if (res.data?.manPowerReportDownload) {
      downloadFromUrl(res.data?.manPowerReportDownload);
    }
    handleClose();
  };

  return (
    <Drawer
      title="Morning Manpower Report"
      width={"60%"}
      open={visible}
      onClose={handleClose}
      destroyOnClose
      className="flex gap-1 flex-col"
    >
      <Card title="Email Report Now" className="mb-1">
        <MorningManPowerEmail />
      </Card>
      <Card title="Automated Daily Email" className="mb-1">
        <MorningManPowerReportDownloadAuto
          defaultUsers={selectedUsers.map((user) => {
            return {
              userId: user.node.user?.pk ?? "No user id",
              emailProjectUserId: user.node.pk,
              name: user.node.user?.name,
            };
          })}
          projectEmployee={projectEmployees.map((employee) => ({
            value: employee.node.employee.user.pk,
            key: employee.node.employee.user.pk,
            label: employee.node.employee.user.name ?? "UNKNOWN",
          }))}
          projectData={projectData}
        />
      </Card>

      <Card title="Download Report">
        <Form
          form={form}
          initialValues={{
            subId: "all",
            downloadDate: dayjs(),
          }}
        >
          <Form.Item
            name="includeAllSubs"
            initialValue={projSetting.include_all_subs_mmr}
            valuePropName="checked"
            label="Include contractors with zero Manpower"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            name="downloadDate"
            label={"Select a Date to download Report"}
            rules={[
              { required: true, message: "Select a Date to Download Report" },
            ]}
          >
            <DatePicker
              className="min-w-12"
              value={date}
              disabledDate={disabledDate}
              onChange={(e) => {
                setDate(e);
              }}
              onOpenChange={onOpenChange}
            />
          </Form.Item>
          <Form.Item name="subId" label={"Select Companies to Include"}>
            <Select className="w-full">
              <Select.Option key="all" value="all">
                All
              </Select.Option>
              {(subcontractorData || []).map((t) => (
                <Select.Option
                  key={t.node.subcontractor_id}
                  value={t.node.subcontractor_id}
                >
                  {t.node.subcontractor.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
        <div className="flex gap-1">
          <Button
            type={"primary"}
            icon={<DownloadOutlined />}
            loading={manPowerReportLoading}
            onClick={handleDownload}
          >
            Download
          </Button>
        </div>
      </Card>
    </Drawer>
  );
};
export default MorningManPowerReportCreator;
