/**
 * @generated SignedSource<<a601b5d343b9eadba09f551df7f4c2b8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { orientation_slide_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GCProjectOrientationDetail_UpdateSlide_Mutation$variables = {
  newId: string;
  now: string;
  slide: orientation_slide_insert_input;
  slideId: string;
};
export type GCProjectOrientationDetail_UpdateSlide_Mutation$data = {
  readonly insert_orientation_slide_one: {
    readonly answer_items: string | null | undefined;
    readonly answer_items_es: string | null | undefined;
    readonly answer_items_fr: string | null | undefined;
    readonly answer_items_it: string | null | undefined;
    readonly answer_items_pt: string | null | undefined;
    readonly answer_items_zh_hans: string | null | undefined;
    readonly answer_required: boolean;
    readonly check_correct_answer: boolean;
    readonly content_type: string | null | undefined;
    readonly correct_answer_note: string | null | undefined;
    readonly correct_answer_note_es: string | null | undefined;
    readonly correct_answer_note_fr: string | null | undefined;
    readonly correct_answer_note_it: string | null | undefined;
    readonly correct_answer_note_pt: string | null | undefined;
    readonly correct_answer_note_zh_hans: string | null | undefined;
    readonly correct_answers: string | null | undefined;
    readonly id: string;
    readonly image_url: string | null | undefined;
    readonly image_url_es: string | null | undefined;
    readonly image_url_fr: string | null | undefined;
    readonly image_url_it: string | null | undefined;
    readonly image_url_pt: string | null | undefined;
    readonly image_url_zh_hans: string | null | undefined;
    readonly order: number;
    readonly orientation_id: string;
    readonly pk: string;
    readonly question_ui_element: string | null | undefined;
    readonly slide_id: string;
    readonly text: string | null | undefined;
    readonly text_es: string | null | undefined;
    readonly text_fr: string | null | undefined;
    readonly text_it: string | null | undefined;
    readonly text_pt: string | null | undefined;
    readonly text_to_speak: string | null | undefined;
    readonly text_to_speak_es: string | null | undefined;
    readonly text_to_speak_fr: string | null | undefined;
    readonly text_to_speak_it: string | null | undefined;
    readonly text_to_speak_pt: string | null | undefined;
    readonly text_to_speak_zh_hans: string | null | undefined;
    readonly text_zh_hans: string | null | undefined;
    readonly title: string;
    readonly title_es: string | null | undefined;
    readonly title_fr: string | null | undefined;
    readonly title_it: string | null | undefined;
    readonly title_pt: string | null | undefined;
    readonly title_zh_hans: string | null | undefined;
    readonly video_url: string | null | undefined;
    readonly video_url_es: string | null | undefined;
    readonly video_url_fr: string | null | undefined;
    readonly video_url_it: string | null | undefined;
    readonly video_url_pt: string | null | undefined;
    readonly video_url_zh_hans: string | null | undefined;
    readonly voice_gender: string | null | undefined;
    readonly voice_name: string | null | undefined;
    readonly voice_name_es: string | null | undefined;
    readonly voice_name_fr: string | null | undefined;
    readonly voice_name_it: string | null | undefined;
    readonly voice_name_pt: string | null | undefined;
    readonly voice_name_zh_hans: string | null | undefined;
    readonly voice_url: string | null | undefined;
    readonly voice_url_es: string | null | undefined;
    readonly voice_url_fr: string | null | undefined;
    readonly voice_url_it: string | null | undefined;
    readonly voice_url_pt: string | null | undefined;
    readonly voice_url_zh_hans: string | null | undefined;
    readonly watch_full_video: boolean;
  } | null | undefined;
  readonly update_orientation_slide: {
    readonly affected_rows: number;
    readonly returning: ReadonlyArray<{
      readonly archived_at: string | null | undefined;
      readonly id: string;
      readonly pk: string;
    }>;
  } | null | undefined;
};
export type GCProjectOrientationDetail_UpdateSlide_Mutation = {
  response: GCProjectOrientationDetail_UpdateSlide_Mutation$data;
  variables: GCProjectOrientationDetail_UpdateSlide_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "newId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "now"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "slide"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "slideId"
},
v4 = [
  {
    "kind": "Variable",
    "name": "object",
    "variableName": "slide"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slide_id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "orientation_id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "order",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "content_type",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "watch_full_video",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "question_ui_element",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "check_correct_answer",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "answer_required",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "correct_answers",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voice_gender",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "image_url",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "video_url",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voice_url",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "answer_items",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text_to_speak",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "correct_answer_note",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voice_name",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title_es",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text_es",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "image_url_es",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "video_url_es",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voice_url_es",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "answer_items_es",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text_to_speak_es",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "correct_answer_note_es",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voice_name_es",
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title_pt",
  "storageKey": null
},
v36 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text_pt",
  "storageKey": null
},
v37 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "image_url_pt",
  "storageKey": null
},
v38 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "video_url_pt",
  "storageKey": null
},
v39 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voice_url_pt",
  "storageKey": null
},
v40 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "answer_items_pt",
  "storageKey": null
},
v41 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text_to_speak_pt",
  "storageKey": null
},
v42 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "correct_answer_note_pt",
  "storageKey": null
},
v43 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voice_name_pt",
  "storageKey": null
},
v44 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title_it",
  "storageKey": null
},
v45 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text_it",
  "storageKey": null
},
v46 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "image_url_it",
  "storageKey": null
},
v47 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "video_url_it",
  "storageKey": null
},
v48 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voice_url_it",
  "storageKey": null
},
v49 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "answer_items_it",
  "storageKey": null
},
v50 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text_to_speak_it",
  "storageKey": null
},
v51 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "correct_answer_note_it",
  "storageKey": null
},
v52 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voice_name_it",
  "storageKey": null
},
v53 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title_fr",
  "storageKey": null
},
v54 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text_fr",
  "storageKey": null
},
v55 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "image_url_fr",
  "storageKey": null
},
v56 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "video_url_fr",
  "storageKey": null
},
v57 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voice_url_fr",
  "storageKey": null
},
v58 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "answer_items_fr",
  "storageKey": null
},
v59 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text_to_speak_fr",
  "storageKey": null
},
v60 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "correct_answer_note_fr",
  "storageKey": null
},
v61 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voice_name_fr",
  "storageKey": null
},
v62 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title_zh_hans",
  "storageKey": null
},
v63 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text_zh_hans",
  "storageKey": null
},
v64 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "image_url_zh_hans",
  "storageKey": null
},
v65 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "video_url_zh_hans",
  "storageKey": null
},
v66 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voice_url_zh_hans",
  "storageKey": null
},
v67 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "answer_items_zh_hans",
  "storageKey": null
},
v68 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text_to_speak_zh_hans",
  "storageKey": null
},
v69 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "correct_answer_note_zh_hans",
  "storageKey": null
},
v70 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voice_name_zh_hans",
  "storageKey": null
},
v71 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "archived_at",
        "variableName": "now"
      }
    ],
    "kind": "ObjectValue",
    "name": "_set"
  },
  {
    "fields": [
      {
        "kind": "Literal",
        "name": "archived_at",
        "value": {
          "_is_null": true
        }
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_neq",
            "variableName": "newId"
          }
        ],
        "kind": "ObjectValue",
        "name": "id"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "slideId"
          }
        ],
        "kind": "ObjectValue",
        "name": "slide_id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v72 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "affected_rows",
  "storageKey": null
},
v73 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "archived_at",
  "storageKey": null
},
v74 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v75 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GCProjectOrientationDetail_UpdateSlide_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "orientation_slide",
        "kind": "LinkedField",
        "name": "insert_orientation_slide_one",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/),
          (v23/*: any*/),
          (v24/*: any*/),
          (v25/*: any*/),
          (v26/*: any*/),
          (v27/*: any*/),
          (v28/*: any*/),
          (v29/*: any*/),
          (v30/*: any*/),
          (v31/*: any*/),
          (v32/*: any*/),
          (v33/*: any*/),
          (v34/*: any*/),
          (v35/*: any*/),
          (v36/*: any*/),
          (v37/*: any*/),
          (v38/*: any*/),
          (v39/*: any*/),
          (v40/*: any*/),
          (v41/*: any*/),
          (v42/*: any*/),
          (v43/*: any*/),
          (v44/*: any*/),
          (v45/*: any*/),
          (v46/*: any*/),
          (v47/*: any*/),
          (v48/*: any*/),
          (v49/*: any*/),
          (v50/*: any*/),
          (v51/*: any*/),
          (v52/*: any*/),
          (v53/*: any*/),
          (v54/*: any*/),
          (v55/*: any*/),
          (v56/*: any*/),
          (v57/*: any*/),
          (v58/*: any*/),
          (v59/*: any*/),
          (v60/*: any*/),
          (v61/*: any*/),
          (v62/*: any*/),
          (v63/*: any*/),
          (v64/*: any*/),
          (v65/*: any*/),
          (v66/*: any*/),
          (v67/*: any*/),
          (v68/*: any*/),
          (v69/*: any*/),
          (v70/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v71/*: any*/),
        "concreteType": "orientation_slide_mutation_response",
        "kind": "LinkedField",
        "name": "update_orientation_slide",
        "plural": false,
        "selections": [
          (v72/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "orientation_slide",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v73/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "GCProjectOrientationDetail_UpdateSlide_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "orientation_slide",
        "kind": "LinkedField",
        "name": "insert_orientation_slide_one",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v74/*: any*/),
          (v75/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/),
          (v23/*: any*/),
          (v24/*: any*/),
          (v25/*: any*/),
          (v26/*: any*/),
          (v27/*: any*/),
          (v28/*: any*/),
          (v29/*: any*/),
          (v30/*: any*/),
          (v31/*: any*/),
          (v32/*: any*/),
          (v33/*: any*/),
          (v34/*: any*/),
          (v35/*: any*/),
          (v36/*: any*/),
          (v37/*: any*/),
          (v38/*: any*/),
          (v39/*: any*/),
          (v40/*: any*/),
          (v41/*: any*/),
          (v42/*: any*/),
          (v43/*: any*/),
          (v44/*: any*/),
          (v45/*: any*/),
          (v46/*: any*/),
          (v47/*: any*/),
          (v48/*: any*/),
          (v49/*: any*/),
          (v50/*: any*/),
          (v51/*: any*/),
          (v52/*: any*/),
          (v53/*: any*/),
          (v54/*: any*/),
          (v55/*: any*/),
          (v56/*: any*/),
          (v57/*: any*/),
          (v58/*: any*/),
          (v59/*: any*/),
          (v60/*: any*/),
          (v61/*: any*/),
          (v62/*: any*/),
          (v63/*: any*/),
          (v64/*: any*/),
          (v65/*: any*/),
          (v66/*: any*/),
          (v67/*: any*/),
          (v68/*: any*/),
          (v69/*: any*/),
          (v70/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v71/*: any*/),
        "concreteType": "orientation_slide_mutation_response",
        "kind": "LinkedField",
        "name": "update_orientation_slide",
        "plural": false,
        "selections": [
          (v72/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "orientation_slide",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v74/*: any*/),
              (v75/*: any*/),
              (v73/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6f1675a55c331347f111a6cb2c8c3974",
    "id": null,
    "metadata": {},
    "name": "GCProjectOrientationDetail_UpdateSlide_Mutation",
    "operationKind": "mutation",
    "text": "mutation GCProjectOrientationDetail_UpdateSlide_Mutation(\n  $slideId: uuid!\n  $newId: uuid!\n  $now: timestamptz!\n  $slide: orientation_slide_insert_input!\n) {\n  insert_orientation_slide_one(object: $slide) {\n    id\n    pk: id\n    slide_id\n    orientation_id\n    order\n    content_type\n    watch_full_video\n    question_ui_element\n    check_correct_answer\n    answer_required\n    correct_answers\n    voice_gender\n    title\n    text\n    image_url\n    video_url\n    voice_url\n    answer_items\n    text_to_speak\n    correct_answer_note\n    voice_name\n    title_es\n    text_es\n    image_url_es\n    video_url_es\n    voice_url_es\n    answer_items_es\n    text_to_speak_es\n    correct_answer_note_es\n    voice_name_es\n    title_pt\n    text_pt\n    image_url_pt\n    video_url_pt\n    voice_url_pt\n    answer_items_pt\n    text_to_speak_pt\n    correct_answer_note_pt\n    voice_name_pt\n    title_it\n    text_it\n    image_url_it\n    video_url_it\n    voice_url_it\n    answer_items_it\n    text_to_speak_it\n    correct_answer_note_it\n    voice_name_it\n    title_fr\n    text_fr\n    image_url_fr\n    video_url_fr\n    voice_url_fr\n    answer_items_fr\n    text_to_speak_fr\n    correct_answer_note_fr\n    voice_name_fr\n    title_zh_hans\n    text_zh_hans\n    image_url_zh_hans\n    video_url_zh_hans\n    voice_url_zh_hans\n    answer_items_zh_hans\n    text_to_speak_zh_hans\n    correct_answer_note_zh_hans\n    voice_name_zh_hans\n  }\n  update_orientation_slide(where: {id: {_neq: $newId}, slide_id: {_eq: $slideId}, archived_at: {_is_null: true}}, _set: {archived_at: $now}) {\n    affected_rows\n    returning {\n      id\n      pk: id\n      archived_at\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "732a9d19b533e45169d30c8ce46ede52";

export default node;
