import React, { useState, useMemo } from "react";
import { Card, Input, Select } from "antd";
import {
  Worker_Set_Input,
  User_Set_Input,
  Incident_User_Set_Input,
  General_Person_Set_Input,
  useUpdateIncidentWorkerByPkMutation,
  useUpdateIncidentUserMutation,
  useCreateGeneralPersonMutation,
  useUpdateGeneralPersonMutation,
  GetIncidentByPkQuery,
  Address_Insert_Input,
  GetIncidentUserInfoQuery,
  GetIncidentUserInfoQueryVariables,
  GetIncidentUserInfoDocument,
} from "src/common/types/generated/apollo/graphQLTypes";
import IncidentAddress from "../IncidentAddress";
import IncidentInputField from "../basic/IncidentInputField";
import IncidentSelectField from "../basic/IncidentSelectField";
import IncidentDatepicker from "../basic/IncidentDatepicker";
import useUpdateIncidentHardHat from "../../utils/useUpdateIncidentHardHat";
import useUpdateAddress from "../../utils/useUpdateAddress";
import useUpdateUser from "../../utils/useUpdateUser";
import createIncidentPatch from "../../utils/createIncidentPatch";
import useAuthUser from "src/common/hooks/useAuthUser";
import { useSuspenseQuery } from "@apollo/client";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import dayjs from "dayjs";
import IncidentCommonUser from "./IncidentCommonUser";
import useUpdateIncidentCommonUser from "../../utils/useUpdateIncidentCommonUser";
import useUpdateIncidentGeneralPerson from "../../utils/useUpdateIncidentGeneralPerson";

interface IncidentUserProps {
  title: string;
  incidentUserId?: string;
  incident: NonNullable<GetIncidentByPkQuery["incident_by_pk"]>;
  onUpdateUser: (
    id: string,
    name: string,
    subcontractorId?: string,
  ) => Promise<void>;
  userType: "witness" | "injured-user";
}

const IncidentUser: React.FC<IncidentUserProps> = ({
  title,
  incidentUserId,
  incident,
  onUpdateUser,
  userType,
}) => {
  const [updateIncidentHardHat] = useUpdateIncidentHardHat();
  const [updateGeneralPerson] = useUpdateGeneralPersonMutation();
  const [updateCommonUserGeneralPerson] = useUpdateIncidentGeneralPerson();
  const [updateIncidentCommonUser] = useUpdateIncidentCommonUser();
  const [updateWorker, { loading: updateWorkerLoading }] =
    useUpdateIncidentWorkerByPkMutation();
  const [updateUser, { loading: updateUserLoading }] = useUpdateUser();
  const [updateAddress] = useUpdateAddress();
  const [updateIncidentUser, { loading: updateIncidentUserLoading }] =
    useUpdateIncidentUserMutation();
  const [createGeneralPerson, { loading: creatingGeneralPerson }] =
    useCreateGeneralPersonMutation();
  const authUser = useAuthUser();

  const { data } = useSuspenseQuery<
    GetIncidentUserInfoQuery,
    GetIncidentUserInfoQueryVariables
  >(GetIncidentUserInfoDocument, {
    variables: { projectId: incident.project_id },
  });

  const incidentUser =
    userType === "injured-user"
      ? incident.injured_users.find(
          (injuredUser) => injuredUser.id === incidentUserId,
        )
      : incident.witnesses.find((witness) => witness.id === incidentUserId);

  const [role, setRole] = useState(
    incidentUser?.user?.role === "subcontractor_employee"
      ? "worker"
      : incidentUser?.user?.role,
  );
  const [subId, setSubId] = useState<string | undefined>(
    incidentUser?.subcontractor_id ?? undefined,
  );

  const [generalPerson, setGeneralPerson] = useState({
    name: incidentUser?.user?.name ?? "",
    email: incidentUser?.user?.email ?? "",
    phone: incidentUser?.user?.phone_number ?? "",
  });

  const generalPersonId = incidentUser?.user?.general_person?.uid;

  const options = useMemo(() => {
    const role = (data?.worker_role ?? []).reduce((acc, role) => {
      acc.push({ label: role.translation.en, value: role.value });
      return acc;
    }, [] as Array<{ label: string; value: string }>);

    const title = (data?.worker_title ?? []).reduce((acc, title) => {
      acc.push({ value: title.id, label: title.translation.en });
      return acc;
    }, [] as Array<{ value: string; label: string }>);

    const subcontractorTradeOptions = (data?.subcontractor_trade ?? []).reduce(
      (acc, trade) => {
        acc.push({ value: trade.id, label: trade.name });
        return acc;
      },
      [] as Array<{ value: string; label: string }>,
    );

    return { role, title, subcontractorTradeOptions };
  }, [data]);

  const { employerOptions, yearOptions } = useMemo(() => {
    const yearOptions = Array.from({ length: 51 }, (_, i) => ({
      label: `${i}`,
      value: `${i}`,
    }));

    const employerOptions = [
      {
        label: `GC Management Employee`,
        value: "gc_management_employee",
      },
      {
        label: `GC Self Perform`,
        value: "gc_self_perform",
      },
      {
        label: "Subcontractor",
        value: "subcontractor",
      },
      {
        label: "Sub-Tier Contractor",
        value: "sub_tier_contractor",
      },
      {
        label: "Owner’s Employee",
        value: "owner_employee",
      },
      {
        label: "Owner’s Contractor",
        value: "owner_contractor",
      },
      {
        label: "Owner’s Vendor",
        value: "owner_vendor",
      },
      {
        label: "Member of Public",
        value: "public_member",
      },
      {
        label: "Vendor",
        value: "vendor",
      },
    ];

    return { employerOptions, yearOptions };
  }, []);

  const userOptions = (type: "worker" | "employee") => {
    if (type === "worker")
      return data?.project_worker
        .filter((pw) => pw.subcontractor.id === subId)
        .map((data) => ({
          label: data.user?.name,
          value: data.user?.id,
          desc: data.hard_hat_number ?? "",
        }));
    else
      return data?.project_employee.map((data) => ({
        label: data.employee.user.name,
        value: data.employee.user.id,
        desc: data.hard_hat_number ?? "",
      }));
  };

  const updateUserInfo = async (
    userId: string,
    key: keyof User_Set_Input,
    value: string,
    comment: string,
  ) => {
    if (!incidentUserId) return;
    await updateUser(
      userId,
      key,
      value,
      comment,
      incident,
      incidentUserId,
      userType,
    );
  };

  const updateAddressInfo = async (
    object: Address_Insert_Input,
    type: "home" | "employer",
  ) => {
    if (!incidentUserId) return;
    await updateAddress(incident, object, type, incidentUserId, userType);
  };

  const updateWorkerInfo = async (
    userId: string,
    key: keyof Worker_Set_Input,
    value: string,
    comment: string,
  ) => {
    if (!incidentUser?.user?.worker) {
      throw new Error("worker not found");
    }

    const incidentUsers =
      userType === "injured-user" ? incident.injured_users : incident.witnesses;

    const updatedIncidentUsers = incidentUsers.map((incidentUser) => {
      if (incidentUser.id === incidentUserId && incidentUser.user.worker) {
        return {
          ...incidentUser,
          user: {
            ...incidentUser.user,
            worker: { ...incidentUser.user.worker, [key]: value },
          },
        };
      } else {
        return incidentUser;
      }
    });

    const updatedIncident = {
      ...incident,
      injured_users:
        userType === "witness" ? incident.injured_users : updatedIncidentUsers,
      witnesses:
        userType === "injured-user" ? incident.witnesses : updatedIncidentUsers,
    };
    const patch = createIncidentPatch(updatedIncident, incident);
    await updateWorker({
      variables: {
        pk_columns: { uid: userId },
        _set: { [key]: value },
        objects: {
          patch: patch,
          edited_by_uid: authUser.uid,
          incident_id: incident.id,
          edit_type: "injury-detail-edit",
          comment: comment,
        },
      },
      optimisticResponse: {
        update_worker_by_pk: {
          ...incidentUser.user.worker,
          [key]: value,
          uid: userId,
        },
        insert_incident_edit: {
          affected_rows: 1,
        },
      },
    });
  };

  const updateIncidentUserInfo = async (
    id: string,
    _set: Omit<
      Incident_User_Set_Input,
      "id" | "incident_id" | "type" | "statement_description_id"
    >,
    comment: string,
  ) => {
    if (!incidentUser) {
      throw new Error("incident user not found");
    }

    const incidentUsers =
      userType === "injured-user" ? incident.injured_users : incident.witnesses;

    const updatedIncidentUsers = incidentUsers.map((incidentUser) => {
      if (incidentUser.id === incidentUserId) {
        return {
          ...incidentUser,
          ..._set,
        };
      } else {
        return incidentUser;
      }
    });

    const updatedIncident = {
      ...incident,
      injured_users:
        userType === "witness" ? incident.injured_users : updatedIncidentUsers,
      witnesses:
        userType === "injured-user" ? incident.witnesses : updatedIncidentUsers,
    };
    const patch = createIncidentPatch(updatedIncident, incident);

    await updateIncidentUser({
      variables: {
        id: id,
        set: _set,
        objects: {
          patch: patch,
          edited_by_uid: authUser.uid,
          incident_id: incident.id,
          edit_type: "incident-user-edit",
          comment: comment,
        },
      },
      optimisticResponse: {
        update_incident_user_by_pk: {
          ...incidentUser,
          id: id,
          ..._set,
        },
        insert_incident_edit: {
          affected_rows: 1,
          returning: [],
        },
      },
    });
  };

  const updateGeneralPersonInfo = async (
    id: string,
    _set: Omit<General_Person_Set_Input, "uid">,
    comment: string,
  ) => {
    const generalPerson = incidentUser?.user?.general_person;
    if (!generalPerson) {
      return;
    }

    const incidentUsers =
      userType === "injured-user" ? incident.injured_users : incident.witnesses;

    const updatedIncidentUsers = incidentUsers.map((incidentUser) => {
      if (incidentUser.id === incidentUserId) {
        return {
          ...incidentUser,
          user: {
            ...incidentUser.user,
            generalPerson: { ...generalPerson, ..._set },
          },
        };
      } else {
        return incidentUser;
      }
    });

    const updatedIncident = {
      ...incident,
      injured_users:
        userType === "witness" ? incident.injured_users : updatedIncidentUsers,
      witnesses:
        userType === "injured-user" ? incident.witnesses : updatedIncidentUsers,
    };
    const patch = createIncidentPatch(updatedIncident, incident);
    await updateGeneralPerson({
      variables: {
        uid: id,
        _set: _set,
        objects: {
          patch: patch,
          edit_type: `user-info-update`,
          edited_by_uid: authUser.uid,
          incident_id: incident.id,
          comment: comment,
        },
      },
      optimisticResponse: {
        update_general_person_by_pk: {
          ...generalPerson,
          uid: id,
          ..._set,
        },
        insert_incident_edit: {
          affected_rows: 1,
        },
      },
    });
  };

  const insertGeneralPerson = async () => {
    if (!generalPerson.name) return;

    const res = await createGeneralPerson({
      variables: {
        name: generalPerson.name,
        projectId: incident.project_id,
      },
    });
    const generalPersonId = res.data?.createGeneralPerson;

    if (generalPersonId) {
      onUpdateUser(generalPersonId, generalPerson.name);
    }
  };

  const userRoleOptions = [
    {
      label: "Worker ",
      value: "worker",
      desc: "- any trade person with a profile on the project",
    },
    {
      label: "Employee ",
      value: "employee",
      desc: " - an operations person that works for your organization",
    },
    {
      label: "Other",
      value: "general_person",
      desc: " - does not have a profile in SiteForm",
    },
  ];

  const userName = incidentUser?.user.name ?? "";

  const hardHatNumber =
    role === "worker"
      ? incidentUser?.project_worker?.hard_hat_number
      : incidentUser?.project_employee?.hard_hat_number;

  return (
    <div className="top-5 flex flex-col">
      <Card className="w-4/5">
        <div className="flex flex-row gap-x-2">{title}</div>
        <div className="mt-1 flex gap-1 items-center">
          <div className="w-10">Select one</div>
          <Select
            style={{ width: "100%" }}
            onChange={(e) => {
              setRole(e);
            }}
            showSearch
            filterOption={(input, option) => {
              const label = option?.label ?? "";
              const desc = option?.desc ?? "";
              return (
                label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                desc.toLowerCase().indexOf(input.toLowerCase()) >= 0
              );
            }}
            value={
              (role
                ? role
                : incidentUser?.user.role === "subcontractor_employee"
                ? "worker"
                : incidentUser?.user.role) ?? undefined
            }
            options={userRoleOptions}
            optionRender={(option) => (
              <span>{option.data.label + " " + option.data.desc} </span>
            )}
          />
        </div>

        {role === "worker" && (
          <div className="mt-1 flex gap-1 items-center">
            <div className="w-10">Select Subcontractor</div>
            <Select
              style={{ width: "100%" }}
              onChange={(e) => {
                setSubId(e);
              }}
              showSearch
              filterOption={(input, option) => {
                if (option?.label) {
                  return (
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  );
                } else {
                  return false;
                }
              }}
              value={subId}
              options={data?.project_subcontractor.map((ps) => ({
                value: ps.subcontractor.id,
                label: ps.subcontractor.name,
              }))}
            />
          </div>
        )}

        {(role === "employee" || (role === "worker" && !!subId)) && (
          <div className=" mt-1 flex gap-1 items-center">
            <div className="w-10">Select Person</div>
            <Select
              className="w-full"
              value={incidentUser?.user?.id ?? undefined}
              options={userOptions(role)}
              showSearch
              filterOption={(input, option) => {
                const label = option?.label ?? "";
                const desc = option?.desc ?? "";
                return (
                  label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                  desc.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
              }}
              optionRender={(option) => (
                <span>{option.data.label + " " + option.data.desc} </span>
              )}
              onChange={(e, option) => {
                const selectedUser = option as {
                  label: string | undefined;
                  value: string | undefined;
                  desc: string;
                };

                if (selectedUser.label && selectedUser.value) {
                  onUpdateUser(selectedUser.value, selectedUser.label, subId);
                }
              }}
            />
          </div>
        )}

        {incidentUser && role !== "general_person" && (
          <IncidentInputField
            label="# Hard Hat"
            onSave={(val) => {
              const id =
                role === "worker"
                  ? incidentUser.project_worker?.id
                  : incidentUser.project_employee?.id;

              if (!id) return;

              const workerOrEmployee =
                role === "worker" ? "project-worker" : "project-employee";

              const incidentUsers =
                userType === "witness"
                  ? incident.witnesses
                  : incident.injured_users;

              const updatedIncidentUsers = incidentUsers.map((incidentUser) => {
                if (incidentUser.id === incidentUserId) {
                  if (role === "worker")
                    return {
                      ...incidentUser,
                      project_workers: [
                        {
                          id: id,
                          hard_hat_number:
                            typeof val === "number" ? val.toString() : val,
                        },
                      ],
                    };
                  else {
                    return {
                      ...incidentUser,
                      project_employee: {
                        id: id,
                        hard_hat_number:
                          typeof val === "number" ? val.toString() : val,
                      },
                    };
                  }
                } else {
                  return incidentUser;
                }
              });

              const updatedIncident = {
                ...incident,
                injured_users:
                  userType === "witness"
                    ? incident.injured_users
                    : updatedIncidentUsers,
                witnesses:
                  userType === "injured-user"
                    ? incident.witnesses
                    : updatedIncidentUsers,
              };

              const patch = createIncidentPatch(updatedIncident, incident);

              if (id && typeof val === "string") {
                updateIncidentHardHat(
                  id,
                  val,
                  workerOrEmployee,
                  patch,
                  `Updated "hard hat number" of ${userName} to ${val}`,
                  incident.id,
                );
              }
            }}
            text={hardHatNumber ?? undefined}
          />
        )}
      </Card>

      {(role === "employee" || (role === "worker" && !!subId)) &&
        incidentUser && (
          <>
            <Card className="w-4/5">
              <IncidentInputField
                label="Phone number"
                onSave={(val) => {
                  if (typeof val === "string") {
                    updateUserInfo(
                      incidentUser.user.id,
                      "phone_number",
                      val,
                      `Updated Injured User Detail of ${userName} - "Phone number" to "${val}"`,
                    );
                  }
                }}
                text={incidentUser.user?.phone_number ?? undefined}
              />

              <IncidentInputField
                label="Email"
                onSave={(val) => {
                  if (typeof val === "string") {
                    updateUserInfo(
                      incidentUser.user.id,
                      "email",
                      val,
                      `Updated Injured User Detail of ${userName} - "Email" to "${val}"`,
                    );
                  }
                }}
                text={incidentUser.user?.email ?? undefined}
              />
            </Card>
            {role === "worker" && (
              <Card className="w-4/5">
                <IncidentSelectField
                  options={options.title}
                  title="Trade"
                  onChange={async (option) => {
                    if (typeof option.value === "string") {
                      await updateWorkerInfo(
                        incidentUser.user.id,
                        "worker_title_id",
                        option.value,
                        `Updated Injured User Detail of ${userName} - "Trade" to "${option.label}"`,
                      );
                    }
                  }}
                  value={
                    incidentUser.user?.worker?.worker_title_id ?? undefined
                  }
                  disabled={
                    updateUserLoading ||
                    updateWorkerLoading ||
                    updateIncidentUserLoading
                  }
                />

                <IncidentSelectField
                  options={options.role}
                  title="Role"
                  onChange={async (option) => {
                    if (typeof option.value === "string") {
                      await updateWorkerInfo(
                        incidentUser.user.id,
                        "current_worker_role",
                        option.value,
                        `Updated Injured User Detail of ${userName} - "Role" to "${option.label}"`,
                      );
                    }
                  }}
                  value={
                    incidentUser.user?.worker?.current_worker_role ?? undefined
                  }
                  disabled={
                    updateUserLoading ||
                    updateWorkerLoading ||
                    updateIncidentUserLoading
                  }
                />
              </Card>
            )}
            <Card className="w-4/5">
              <IncidentDatepicker
                label="Date of Birth"
                onChange={async (date) => {
                  await updateUserInfo(
                    incidentUser.user.id,
                    "birth_date",
                    date,
                    `Updated Injured User Detail of ${userName} - "Date of Birth" to "${dayjs(
                      date,
                    ).format("DD MMM, YYYY")}"`,
                  );
                }}
                value={incidentUser.user.birth_date ?? undefined}
                disabled={
                  updateUserLoading ||
                  updateWorkerLoading ||
                  updateIncidentUserLoading
                }
              />
            </Card>

            <Card className="w-4/5">
              <IncidentSelectField
                title="Gender"
                onChange={async (option) => {
                  if (typeof option.value === "string") {
                    await updateUserInfo(
                      incidentUser.user.id,
                      "gender",
                      option.value,
                      `Updated Injured User Detail of ${userName} - "Gender" to "${option.label}"`,
                    );
                  }
                }}
                options={[
                  { label: "Other", value: "O" },
                  { label: "Male", value: "M" },
                  { label: "Female", value: "F" },
                ]}
                value={incidentUser.user.gender ?? undefined}
                disabled={
                  updateUserLoading ||
                  updateWorkerLoading ||
                  updateIncidentUserLoading
                }
              />
            </Card>

            <Card className="w-4/5">
              <IncidentSelectField
                title="Marital Status"
                onChange={async (option) => {
                  if (typeof option.value === "string") {
                    await updateUserInfo(
                      incidentUser.user.id,
                      "marital_status",
                      option.value,
                      `Updated Injured User Detail of ${userName} - "Marital Status" to "${option.label}"`,
                    );
                  }
                }}
                options={[
                  { label: "Married", value: "married" },
                  { label: "Unmarried", value: "single" },
                ]}
                value={incidentUser.user.marital_status ?? undefined}
                disabled={
                  updateUserLoading ||
                  updateWorkerLoading ||
                  updateIncidentUserLoading
                }
              />
            </Card>

            <IncidentAddress
              title="Home Address"
              updateAddress={updateAddressInfo}
              type="home"
              addressLine1={incidentUser?.home_address?.line_1 ?? undefined}
              cityZipCode={
                incidentUser?.home_address?.city_zip_code_id ?? undefined
              }
              zipCode={incidentUser?.home_address?.zip_code ?? undefined}
            />

            {userType === "injured-user" && (
              <>
                {role === "worker" && (
                  <>
                    <Card className="w-4/5">
                      <IncidentSelectField
                        value={
                          incidentUser.user.worker?.years_in_construction ??
                          undefined
                        }
                        onChange={async (option) => {
                          if (typeof option.value === "string") {
                            await updateWorkerInfo(
                              incidentUser.user.id,
                              "years_in_construction",
                              option.value,
                              `Updated Injured User Detail of ${userName} - "Number of Years Performing Task" to "${option.label}"`,
                            );
                          }
                        }}
                        options={yearOptions}
                        title="Number of Years Performing Task"
                        disabled={
                          updateUserLoading ||
                          updateWorkerLoading ||
                          updateIncidentUserLoading
                        }
                      />
                    </Card>

                    <IncidentCommonUser
                      title="Supervisor"
                      projectId={incident.project_id}
                      user={incidentUser.supervisor ?? undefined}
                      onUpdateUser={async (id, name) => {
                        updateIncidentUserInfo(
                          incidentUser.id,
                          { supervisor_id: id },
                          `Updated Injured User Detail of ${userName} - "Supervisor" to ${name}`,
                        );
                      }}
                      onUpdateUserInfo={async (id, key, val) => {
                        if (!!incidentUser.supervisor) {
                          const updatedIncidentUsers =
                            incident.injured_users.map((user) => {
                              if (
                                user.id === incidentUserId &&
                                user.supervisor
                              ) {
                                return {
                                  ...user,
                                  supervisor: {
                                    ...user.supervisor,
                                    [key]: val,
                                  },
                                };
                              }
                              return user;
                            });

                          const updatedIncident = {
                            ...incident,
                            injured_users: updatedIncidentUsers,
                          };
                          const patch = createIncidentPatch(
                            updatedIncident,
                            incident,
                          );
                          const label =
                            key === "phone_number" ? "phone number" : "email";
                          const comment = `Updated the ${label} of the "Supervisor" of ${userName} to ${val}`;
                          updateIncidentCommonUser(
                            id,
                            { [key]: val },
                            comment,
                            incident.id,
                            patch,
                          );
                        }
                      }}
                      onUpdateGeneralPerson={async (val) => {
                        if (
                          !!incidentUser.supervisor &&
                          !!incidentUser.supervisor.general_person
                        ) {
                          const updatedIncidentUsers =
                            incident.injured_users.map((user) => {
                              if (
                                user.id === incidentUserId &&
                                user.supervisor &&
                                user.supervisor.general_person
                              ) {
                                return {
                                  ...user,
                                  supervisor: {
                                    ...user.supervisor,
                                    general_person: {
                                      ...user.supervisor.general_person,
                                      employer: val,
                                    },
                                  },
                                };
                              }
                              return user;
                            });

                          const updatedIncident = {
                            ...incident,
                            injured_users: updatedIncidentUsers,
                          };
                          const patch = createIncidentPatch(
                            updatedIncident,
                            incident,
                          );
                          const comment = `Updated employer of the "Supervisor" of ${userName} to ${val}`;
                          updateCommonUserGeneralPerson(
                            incidentUser.supervisor.id,
                            { employer: val },
                            comment,
                            incident.id,
                            patch,
                          );
                        }
                      }}
                    />
                  </>
                )}

                <Card className="w-4/5">
                  <IncidentSelectField
                    title="Employer"
                    options={employerOptions}
                    value={incidentUser?.employer ?? undefined}
                    onChange={async (option) => {
                      if (typeof option.value === "string") {
                        await updateIncidentUserInfo(
                          incidentUser.id,
                          { employer: option.value },
                          `Updated Injured User Detail of ${userName} - "Employer" to "${option.label}"`,
                        );
                      }
                    }}
                    disabled={
                      updateUserLoading ||
                      updateWorkerLoading ||
                      updateIncidentUserLoading
                    }
                  />
                </Card>

                <IncidentAddress
                  title="Employer Address"
                  updateAddress={updateAddressInfo}
                  type="employer"
                  addressLine1={
                    incidentUser?.employer_address?.line_1 ?? undefined
                  }
                  cityZipCode={
                    incidentUser?.employer_address?.city_zip_code_id ??
                    undefined
                  }
                  zipCode={
                    incidentUser?.employer_address?.zip_code ?? undefined
                  }
                />

                <Card className="w-4/5">
                  <IncidentSelectField
                    onChange={async (option) => {
                      if (typeof option.value === "string") {
                        await updateIncidentUserInfo(
                          incidentUser.id,
                          { employer_trade_id: option.value },
                          `Updated Injured User Detail of ${userName} - "Employer Trade" to "${option.label}"`,
                        );
                      }
                    }}
                    options={options.subcontractorTradeOptions}
                    title="Employer Trade"
                    value={incidentUser?.employer_trade?.id ?? undefined}
                    disabled={
                      updateUserLoading ||
                      updateWorkerLoading ||
                      updateIncidentUserLoading
                    }
                  />
                </Card>
                <Card className="w-4/5">
                  <IncidentInputField
                    label="Employer Phone Number"
                    onSave={(val) => {
                      if (typeof val === "string") {
                        updateIncidentUserInfo(
                          incidentUser.id,
                          { employer_phone_number: val },
                          `Updated Injured User Detail of ${userName} - "Employer Phone Number" to "${val}"`,
                        );
                      }
                    }}
                    text={incidentUser?.employer_phone_number ?? undefined}
                  />
                </Card>

                {role === "worker" && (
                  <Card className="w-4/5">
                    <IncidentDatepicker
                      label="Hire Date"
                      onChange={async (val) => {
                        if (typeof val === "string") {
                          await updateWorkerInfo(
                            incidentUser.user.id,
                            "hire_date",
                            val,
                            `Updated Injured User Detail of ${userName} - "Hire Date" to "${dayjs(
                              val,
                            ).format("DD MMM, YYYY")}"`,
                          );
                        }
                      }}
                      value={incidentUser.user.worker?.hire_date ?? undefined}
                      disabled={
                        updateUserLoading ||
                        updateWorkerLoading ||
                        updateIncidentUserLoading
                      }
                    />

                    <IncidentDatepicker
                      label="Time person began work"
                      showTime={true}
                      format="YYYY-MM-DD h:mm A"
                      onChange={async (val) => {
                        if (typeof val === "string")
                          await updateIncidentUserInfo(
                            incidentUser.id,
                            { time_person_began_work: val },
                            `Updated Injured User Detail of ${userName} - "Time person began work" to "${dayjs(
                              val,
                            ).format("DD MMM, YYYY h:mm A")}"`,
                          );
                      }}
                      value={incidentUser.time_person_began_work ?? undefined}
                      disabled={
                        updateUserLoading ||
                        updateWorkerLoading ||
                        updateIncidentUserLoading
                      }
                    />
                  </Card>
                )}
              </>
            )}
          </>
        )}

      {role === "general_person" && (
        <Card className="w-4/5">
          <div className="mt-3">
            <div className="flex gap-1 items-center">
              <div className="font-accent">Enter Details</div>
            </div>

            <div className="flex gap-1 items-center mt-0.75">
              <div className="w-10">Name</div>
              <Input
                className="w-full"
                value={generalPerson.name ?? incidentUser?.user.name}
                onChange={(e) =>
                  setGeneralPerson((prev) => {
                    return { ...prev, name: e.target.value };
                  })
                }
                onBlur={insertGeneralPerson}
                disabled={creatingGeneralPerson}
              />
            </div>

            <div className="flex gap-1 items-center mt-0.5">
              <div className="w-10">Phone number</div>
              <Input
                className="w-full"
                value={generalPerson.phone ?? incidentUser?.user.phone_number}
                onChange={(e) =>
                  setGeneralPerson((prev) => {
                    return { ...prev, phone: e.target.value };
                  })
                }
                onBlur={() => {
                  if (!!incidentUser) {
                    updateUserInfo(
                      incidentUser.user.id,
                      "phone_number",
                      generalPerson.phone ?? incidentUser.user.phone_number,
                      `Updated Injured User Detail of ${userName} - "Phone number" to "${generalPerson.phone}"`,
                    );
                  } else {
                    insertGeneralPerson();
                  }
                }}
                disabled={creatingGeneralPerson}
              />
            </div>
            <div className="flex gap-1 items-center mt-0.5">
              <div className="w-10">Email</div>
              <Input
                className="w-full"
                value={generalPerson.email ?? incidentUser?.user.email}
                onChange={(e) =>
                  setGeneralPerson((prev) => {
                    return { ...prev, email: e.target.value };
                  })
                }
                onBlur={() => {
                  if (!!incidentUser) {
                    updateUserInfo(
                      incidentUser.user.id,
                      "email",
                      generalPerson.email ?? incidentUser.user.email,
                      `Updated Injured User Detail of ${userName} - "Email" to "${generalPerson.email}"`,
                    );
                  } else {
                    insertGeneralPerson();
                  }
                }}
                disabled={creatingGeneralPerson}
              />
            </div>

            {generalPersonId && (
              <IncidentInputField
                label="Employer"
                text={incidentUser?.user?.general_person?.employer ?? undefined}
                onSave={(val) => {
                  if (typeof val === "string") {
                    updateGeneralPersonInfo(
                      generalPersonId,
                      {
                        employer: val,
                      },
                      `Updated Injured User Detail of ${userName} - "Employer" to "${val}"`,
                    );
                  }
                }}
              />
            )}
          </div>
        </Card>
      )}
    </div>
  );
};

export default withCustomSuspense(IncidentUser);
