import { FC } from "react";
import {
  useUpdateIncidentMutation,
  Incident_Set_Input,
  Document_Insert_Input,
} from "src/common/types/generated/apollo/graphQLTypes";
import dayjs from "dayjs";
import IncidentTextField from "./basic/IncidentTextField";
import { Card } from "antd";
import { useSearchParams } from "react-router-dom";
import IncidentCommonUser from "./incident-users/IncidentCommonUser";
import IncidentDatepicker from "./basic/IncidentDatepicker";
import IncidentSelectField from "./basic/IncidentSelectField";
import IncidentBottomButtons from "./basic/IncidentBottomButtons";
import IncidentDocViewAndUpload from "./basic/IncidentDocViewAndUpload";
import IncidentWeatherObservation from "./IncidentWeatherObservation";
import IncidentTaskUploader from "./IncidentTaskUploader";
import { DeletedDocument } from "./basic/IncidentDocViewer";
import createIncidentPatch from "../utils/createIncidentPatch";
import useAuthUser from "src/common/hooks/useAuthUser";
import useUpdateIncidentCommonUser from "../utils/useUpdateIncidentCommonUser";
import useUpdateIncidentGeneralPerson from "../utils/useUpdateIncidentGeneralPerson";
import IncidentTypeProps from "../utils/IncidentTypeProps";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import useUploadIncidentDocuments from "../utils/useUploadIncidentDocuments";
import useDeleteIncidentDocument from "../utils/useDeleteIncidentDocument";

const IncidentDetail: FC<IncidentTypeProps> = ({ incident }) => {
  const [_, setSearchParams] = useSearchParams();
  const authUser = useAuthUser();
  const [updateIncidentCommonUser] = useUpdateIncidentCommonUser();
  const [updateGeneralPerson] = useUpdateIncidentGeneralPerson();
  const [updateIncident, { loading: updateIncidentLoading }] =
    useUpdateIncidentMutation();
  const [uploadIncidentDocuments] = useUploadIncidentDocuments();
  const [deleteIncidentDocument] = useDeleteIncidentDocument();

  const updateIncidentFields = async (
    _set: Omit<
      Incident_Set_Input,
      "id" | "project_id" | "created_at" | "description_id"
    >,
    comment: string,
  ) => {
    const updatedIncident = { ...incident, ..._set };
    const patch = createIncidentPatch(updatedIncident, incident);
    await updateIncident({
      variables: {
        incidentId: incident.id,
        _set: _set,
        objects: [
          {
            patch: patch,
            edited_by_uid: authUser.uid,
            incident_id: incident.id,
            edit_type: "incident-detail-edit",
            comment: comment,
          },
        ],
      },
      optimisticResponse: {
        update_incident_by_pk: {
          ...incident,
          ..._set,
          id: incident.id,
        },
        insert_incident_edit: {
          affected_rows: 1,
        },
      },
    });
  };

  const deleteDocument = async (doc: DeletedDocument) => {
    await deleteIncidentDocument({
      doc,
      incidentFieldKey: "incident_detail",
      incident,
    });
  };

  const uploadDocument = async (objects: Document_Insert_Input[]) => {
    await uploadIncidentDocuments({
      incidentFieldKey: "incident_detail",
      incident: incident,
      objects: objects,
    });
  };

  const supervisor = incident.supervisor_user;
  const reportedByUser = incident.reported_by_user;
  const lightingConditions = [
    { key: "well_lit", value: "well_lit", label: "Well lit (no impact)" },
    { key: "dimly_lit", value: "dimly_lit", label: "Dimly lit (no impact)" },
    {
      key: "poor_lighting",
      value: "poor_lighting",
      label: "Poor lighting (impact)",
    },
  ];
  const saveEnabled =
    !!incident?.lighting_conditions &&
    !!incident.summary &&
    !!incident.description.en &&
    !!incident.incident_time &&
    ((incident.emergency_service_called && !!incident.emergency_service) ||
      !incident.emergency_service_called);
  return (
    <>
      <div className="absolute left-24 top-2 text-2">Incident Details</div>
      <div className="w-full mt-6 pl-4 table-fixed overflow-y-auto">
        <div className="flex flex-col flex-1">
          <Card className="w-4/5">
            <IncidentTextField
              label="* Incident title"
              text={incident.summary.en}
              textId={incident.summary.id}
              autoSize={{ minRows: 1, maxRows: 2 }}
              fieldTypeKey="incident_detail"
              field="summary"
              incident={incident}
            />
          </Card>

          <Card className="w-4/5">
            <IncidentTextField
              label="* Full description and details of incident:"
              text={incident.description.en}
              textId={incident.description.id}
              autoSize={{ minRows: 5, maxRows: 10 }}
              fieldTypeKey="incident_detail"
              field="description"
              incident={incident}
            />
          </Card>

          <Card className="w-4/5">
            <IncidentTextField
              label=" Location of Incident:"
              text={incident.location.en}
              textId={incident.location.id}
              fieldTypeKey="incident_detail"
              field="location"
              incident={incident}
            />
          </Card>

          <Card className="w-4/5">
            <IncidentDatepicker
              format="YYYY-MM-DD h:mm A"
              label="* Date and Time of Incident"
              onChange={async (val) => {
                await updateIncidentFields(
                  { incident_time: val },
                  `Updated Incident Detail - "Time of incident" to "${dayjs(
                    val,
                  ).format("DD MMM, YYYY h:mm A")}"`,
                );
              }}
              showTime
              value={incident.incident_time ?? undefined}
              disabled={updateIncidentLoading}
            />
          </Card>
          <Card className="w-4/5">
            <IncidentDatepicker
              format="YYYY-MM-DD h:mm A"
              label="Date and Time Incident was Reported"
              onChange={async (val) => {
                await updateIncidentFields(
                  { reporting_time: val },
                  `Updated Incident Detail - "Reporting time" to "${dayjs(
                    val,
                  ).format("DD MMM, YYYY h:mm A")}"`,
                );
              }}
              showTime
              value={incident.reporting_time ?? undefined}
              disabled={updateIncidentLoading}
            />
          </Card>

          <IncidentCommonUser
            title="Who Reported the Incident"
            user={incident?.reported_by_user ?? undefined}
            onUpdateUser={async (id, name) => {
              updateIncidentFields(
                { reported_by_uid: id },
                `Updated Incident Detail - "Reported by user" to "${name}"`,
              );
            }}
            onUpdateUserInfo={async (id, key, val) => {
              if (!!reportedByUser) {
                const updatedIncident = {
                  ...incident,
                  reported_by_user: { ...reportedByUser, [key]: val },
                };
                const patch = createIncidentPatch(updatedIncident, incident);
                const label = key === "phone_number" ? "phone number" : "email";
                const comment = `Updated ${label} of the "Reported by user" to ${val}`;
                updateIncidentCommonUser(
                  id,
                  { [key]: val },
                  comment,
                  incident.id,
                  patch,
                );
              }
            }}
            onUpdateGeneralPerson={async (val) => {
              if (reportedByUser && reportedByUser.general_person) {
                const updatedIncident = {
                  ...incident,
                  reported_by_user: {
                    ...reportedByUser,
                    general_person: {
                      ...reportedByUser.general_person,
                      employer: val,
                    },
                  },
                };
                const patch = createIncidentPatch(updatedIncident, incident);
                const comment = `Updated employer of the "Reported by user" to ${val}`;
                updateGeneralPerson(
                  reportedByUser.id,
                  { employer: val },
                  comment,
                  incident.id,
                  patch,
                );
              }
            }}
            projectId={incident.project_id}
          />

          <IncidentCommonUser
            title=" Supervisor of the Work when Incident occured   "
            user={supervisor ?? undefined}
            onUpdateUser={async (id, name) => {
              updateIncidentFields(
                { supervisor_user_id: id },
                `Updated Incident Detail - "Supervisor of Work" to "${name}"`,
              );
            }}
            onUpdateUserInfo={async (id, key, val) => {
              if (!!supervisor) {
                const updatedIncident = {
                  ...incident,
                  supervisor_user: { ...supervisor, [key]: val },
                };
                const patch = createIncidentPatch(updatedIncident, incident);
                const label = key === "phone_number" ? "phone number" : "email";
                const comment = `Updated ${label} of the "Supervisor of work" to ${val}`;
                updateIncidentCommonUser(
                  id,
                  { [key]: val },
                  comment,
                  incident.id,
                  patch,
                );
              }
            }}
            onUpdateGeneralPerson={async (val) => {
              if (supervisor && supervisor.general_person) {
                const updatedIncident = {
                  ...incident,
                  supervisor_user: {
                    ...supervisor,
                    general_person: {
                      ...supervisor.general_person,
                      employer: val,
                    },
                  },
                };
                const patch = createIncidentPatch(updatedIncident, incident);
                const comment = `Updated employer of the "Reported by user" to ${val}`;
                updateGeneralPerson(
                  supervisor.id,
                  { employer: val },
                  comment,
                  incident.id,
                  patch,
                );
              }
            }}
            projectId={incident.project_id}
          />
          <Card className="w-4/5">
            <IncidentSelectField
              title={"Was a post incident drug & alcohol test performed"}
              options={[
                { label: "No", value: false },
                { label: "Yes", value: true },
              ]}
              value={incident.drug_test_performed ?? undefined}
              onChange={async (option) => {
                if (typeof option.value === "boolean")
                  await updateIncidentFields(
                    { drug_test_performed: option.value },
                    `Updated Incident Detail - "was drug test performed" to "${option.label}"`,
                  );
              }}
              disabled={updateIncidentLoading}
            />
          </Card>

          <Card className="w-4/5">
            <IncidentSelectField
              title={"Lighting Conditions"}
              options={lightingConditions}
              value={incident.lighting_conditions ?? undefined}
              onChange={async (option) => {
                if (typeof option.value === "string")
                  await updateIncidentFields(
                    {
                      lighting_conditions: option.value,
                    },
                    `Updated Incident Detail -  "lighting conditions" to "${option.label}"`,
                  );
              }}
              disabled={updateIncidentLoading}
            />
          </Card>

          <IncidentTaskUploader incident={incident} />
          <IncidentWeatherObservation incident={incident} />
          <Card className="w-4/5">
            <IncidentSelectField
              title={
                "Was there any equipment that contributed to the cause of the incident?"
              }
              options={[
                { label: "No", value: false },
                { label: "Yes", value: true },
              ]}
              value={incident.equipment_contributed ?? undefined}
              onChange={async (option) => {
                if (typeof option.value === "boolean")
                  await updateIncidentFields(
                    { equipment_contributed: option.value },
                    `Updated Incident Detail -  "was equipment contributed" to "${option.label}"`,
                  );
              }}
              disabled={updateIncidentLoading}
            />
            {incident.equipment_contributed && (
              <IncidentTextField
                label="Equipment Contributed"
                text={incident.equipment_type.en}
                textId={incident.equipment_type.id}
                fieldTypeKey="incident_detail"
                field="equipment_type"
                incident={incident}
              />
            )}
          </Card>
          <Card className="w-4/5">
            <IncidentSelectField
              title={
                "Any project impacts or delays due to incident (monetary, time, deliveries, etc.)?"
              }
              options={[
                { label: "No", value: false },
                { label: "Yes", value: true },
              ]}
              value={incident.project_impacted ?? undefined}
              onChange={async (option) => {
                if (typeof option.value === "boolean")
                  await updateIncidentFields(
                    { project_impacted: option.value },
                    `Updated Incident Detail - "Projects Impacted" to ${option.label}`,
                  );
              }}
              disabled={updateIncidentLoading}
            />

            {incident.project_impacted && (
              <IncidentTextField
                label="Explain project impacts or delays"
                text={incident.project_impacts.en}
                textId={incident.project_impacts.id}
                fieldTypeKey="incident_detail"
                field="project_impacts"
                incident={incident}
              />
            )}
          </Card>

          <Card className="w-4/5">
            <IncidentSelectField
              title={"Emergency Services Called"}
              options={[
                { label: "No", value: false },
                { label: "Yes", value: true },
              ]}
              value={incident.emergency_service_called ?? undefined}
              onChange={async (option) => {
                if (typeof option.value === "boolean")
                  await updateIncidentFields(
                    { emergency_service_called: option.value },
                    `Updated Incident Detail - "Emergency Services Called" to ${option.label}`,
                  );
              }}
              disabled={updateIncidentLoading}
            />

            {incident.emergency_service_called && (
              <IncidentTextField
                label="* Who was called (EMS, Police, etc)?"
                text={incident.emergency_service.en}
                textId={incident.emergency_service.id}
                fieldTypeKey="incident_detail"
                field="emergency_service"
                incident={incident}
              />
            )}
          </Card>

          <Card className="w-4/5">
            <IncidentTextField
              label="If a road vehicle was involved, please provide: Driver(s) Name,
        License # and Company Vehicle:"
              text={incident.road_vehicle_info.en}
              textId={incident.road_vehicle_info.id}
              fieldTypeKey="incident_detail"
              field="road_vehicle_info"
              incident={incident}
            />
          </Card>

          <Card className="w-4/5">
            <IncidentTextField
              label="What was immediately done to rectify the situation and prevent the
        incident from occurring again?"
              text={incident.corrective_action.en}
              textId={incident.corrective_action.id}
              fieldTypeKey="incident_detail"
              field="corrective_action"
              incident={incident}
            />
          </Card>

          <IncidentDocViewAndUpload
            deleteDocument={deleteDocument}
            documents={incident.attached_files}
            groupId={incident.id}
            uploadDocument={uploadDocument}
            type="incident"
          />

          <IncidentBottomButtons
            onNextClick={() => {
              setSearchParams({ subview: "injured_user" });
            }}
            onSave={() => {
              updateIncidentFields(
                { detail_completed_at: dayjs().format() },
                `Incident Detail marked as Completed`,
              );
            }}
            saveDisabled={!saveEnabled}
          />
        </div>
      </div>
    </>
  );
};

export default withCustomSuspense(IncidentDetail);
