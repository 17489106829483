/**
 * @generated SignedSource<<d403a31d14995d967445c49b7d7a31a3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { user_project_filter_calendar_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GCProjectCalendarSitedeliveryDeliveries_insertFilterCalendar_Mutation$variables = {
  object: user_project_filter_calendar_insert_input;
};
export type GCProjectCalendarSitedeliveryDeliveries_insertFilterCalendar_Mutation$data = {
  readonly insert_user_project_filter_calendar_one: {
    readonly calendar: {
      readonly color_hex: string;
      readonly id: string;
      readonly name: {
        readonly en: string;
        readonly id: string;
      };
    };
    readonly id: string;
  } | null | undefined;
};
export type GCProjectCalendarSitedeliveryDeliveries_insertFilterCalendar_Mutation = {
  response: GCProjectCalendarSitedeliveryDeliveries_insertFilterCalendar_Mutation$data;
  variables: GCProjectCalendarSitedeliveryDeliveries_insertFilterCalendar_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "object"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "object",
        "variableName": "object"
      },
      {
        "kind": "Literal",
        "name": "on_conflict",
        "value": {
          "constraint": "user_project_filter_calendar_user_id_project_id_calendar_id_fil",
          "update_columns": ([]/*: any*/)
        }
      }
    ],
    "concreteType": "user_project_filter_calendar",
    "kind": "LinkedField",
    "name": "insert_user_project_filter_calendar_one",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "calendar",
        "kind": "LinkedField",
        "name": "calendar",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "text_translation",
            "kind": "LinkedField",
            "name": "name",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "en",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "color_hex",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GCProjectCalendarSitedeliveryDeliveries_insertFilterCalendar_Mutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GCProjectCalendarSitedeliveryDeliveries_insertFilterCalendar_Mutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "d6b0371ca4da0a26bb3f3883b3174b6e",
    "id": null,
    "metadata": {},
    "name": "GCProjectCalendarSitedeliveryDeliveries_insertFilterCalendar_Mutation",
    "operationKind": "mutation",
    "text": "mutation GCProjectCalendarSitedeliveryDeliveries_insertFilterCalendar_Mutation(\n  $object: user_project_filter_calendar_insert_input!\n) {\n  insert_user_project_filter_calendar_one(object: $object, on_conflict: {constraint: user_project_filter_calendar_user_id_project_id_calendar_id_fil, update_columns: []}) {\n    id\n    calendar {\n      id\n      name {\n        id\n        en\n      }\n      color_hex\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5e92314c1ede0807e0c491d0ff7d22f7";

export default node;
