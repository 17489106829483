/**
 * @generated SignedSource<<230e3866cd7a3b0655d3b01ae8be3e2c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { task_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GCTasksMutation$variables = {
  objects: ReadonlyArray<task_insert_input>;
};
export type GCTasksMutation$data = {
  readonly insert_task: {
    readonly affected_rows: number;
  } | null | undefined;
};
export type GCTasksMutation = {
  response: GCTasksMutation$data;
  variables: GCTasksMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "objects"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "objects",
        "variableName": "objects"
      }
    ],
    "concreteType": "task_mutation_response",
    "kind": "LinkedField",
    "name": "insert_task",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "affected_rows",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GCTasksMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GCTasksMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a4e48339779ea92ffa582f0d0155aeb9",
    "id": null,
    "metadata": {},
    "name": "GCTasksMutation",
    "operationKind": "mutation",
    "text": "mutation GCTasksMutation(\n  $objects: [task_insert_input!]!\n) {\n  insert_task(objects: $objects) {\n    affected_rows\n  }\n}\n"
  }
};
})();

(node as any).hash = "838a8b2f5eede368dc1df23b9347d6c1";

export default node;
