/**
 * @generated SignedSource<<6f65c655531006409b74ad319a569be4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { auto_email_bool_exp } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SCProjectAutoEmailTables_DeleteMutation$variables = {
  where: auto_email_bool_exp;
};
export type SCProjectAutoEmailTables_DeleteMutation$data = {
  readonly delete_auto_email: {
    readonly affected_rows: number;
  } | null | undefined;
};
export type SCProjectAutoEmailTables_DeleteMutation = {
  response: SCProjectAutoEmailTables_DeleteMutation$data;
  variables: SCProjectAutoEmailTables_DeleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where"
      }
    ],
    "concreteType": "auto_email_mutation_response",
    "kind": "LinkedField",
    "name": "delete_auto_email",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "affected_rows",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SCProjectAutoEmailTables_DeleteMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SCProjectAutoEmailTables_DeleteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a150416f4d67db02de504aa115c4f075",
    "id": null,
    "metadata": {},
    "name": "SCProjectAutoEmailTables_DeleteMutation",
    "operationKind": "mutation",
    "text": "mutation SCProjectAutoEmailTables_DeleteMutation(\n  $where: auto_email_bool_exp!\n) {\n  delete_auto_email(where: $where) {\n    affected_rows\n  }\n}\n"
  }
};
})();

(node as any).hash = "4a88bbaf265db01e8899473c9e84360a";

export default node;
