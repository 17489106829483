/**
 * @generated SignedSource<<fc59597ccef15236113a608f0e9a5689>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { certificates_to_verify_bool_exp, user_orientation_bool_exp } from './types';
export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
export type VerifyDrugtestQuery$variables = {
  certId: string;
  userOrientationWhere: user_orientation_bool_exp;
  where: certificates_to_verify_bool_exp;
};
export type VerifyDrugtestQuery$data = {
  readonly certificates_to_verify_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly back_image: {
          readonly lg_url: string | null | undefined;
          readonly md_url: string | null | undefined;
          readonly url: string;
        } | null | undefined;
        readonly back_image_id: string | null | undefined;
        readonly front_image: {
          readonly lg_url: string | null | undefined;
          readonly md_url: string | null | undefined;
          readonly url: string;
        } | null | undefined;
        readonly front_image_id: string;
        readonly id: string;
        readonly pk: string;
        readonly project: {
          readonly drugtest_validity_days: number;
          readonly name: string;
        } | null | undefined;
        readonly project_id: string | null | undefined;
        readonly uploaded_by_uid: string | null | undefined;
        readonly uploaded_by_user: {
          readonly name: string;
          readonly role: string;
        } | null | undefined;
        readonly worker: {
          readonly subcontractor_id: string | null | undefined;
          readonly user: {
            readonly name: string;
          };
        };
        readonly worker_id: string;
      };
    }>;
  };
  readonly reason_to_reject_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly pk: string;
        readonly reason: string;
        readonly type: string;
      };
    }>;
  };
  readonly user_orientation_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly drug_test_id: string | null | undefined;
      };
    }>;
  };
  readonly worker_drug_test_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly pk: string;
      };
    }>;
  };
};
export type VerifyDrugtestQuery = {
  response: VerifyDrugtestQuery$data;
  variables: VerifyDrugtestQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "certId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userOrientationWhere"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "where"
},
v3 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "userOrientationWhere"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "drug_test_id",
  "storageKey": null
},
v5 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "certId"
          }
        ],
        "kind": "ObjectValue",
        "name": "verification_id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v6 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v7 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "worker_id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subcontractor_id",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uploaded_by_uid",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "front_image_id",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "md_url",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lg_url",
  "storageKey": null
},
v18 = [
  (v15/*: any*/),
  (v16/*: any*/),
  (v17/*: any*/)
],
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "back_image_id",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "project_id",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "drugtest_validity_days",
  "storageKey": null
},
v22 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "reason": "asc"
    }
  }
],
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reason",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v25 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v26 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
},
v27 = [
  (v15/*: any*/),
  (v16/*: any*/),
  (v17/*: any*/),
  (v8/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "VerifyDrugtestQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "user_orientationConnection",
        "kind": "LinkedField",
        "name": "user_orientation_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "user_orientationEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "user_orientation",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "worker_drug_testConnection",
        "kind": "LinkedField",
        "name": "worker_drug_test_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "worker_drug_testEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "worker_drug_test",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "certificates_to_verifyConnection",
        "kind": "LinkedField",
        "name": "certificates_to_verify_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "certificates_to_verifyEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "certificates_to_verify",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v6/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "worker",
                    "kind": "LinkedField",
                    "name": "worker",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "user",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v11/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "user",
                    "kind": "LinkedField",
                    "name": "uploaded_by_user",
                    "plural": false,
                    "selections": [
                      (v13/*: any*/),
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v14/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "image",
                    "kind": "LinkedField",
                    "name": "front_image",
                    "plural": false,
                    "selections": (v18/*: any*/),
                    "storageKey": null
                  },
                  (v19/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "image",
                    "kind": "LinkedField",
                    "name": "back_image",
                    "plural": false,
                    "selections": (v18/*: any*/),
                    "storageKey": null
                  },
                  (v20/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "project",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/),
                      (v21/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v22/*: any*/),
        "concreteType": "reason_to_rejectConnection",
        "kind": "LinkedField",
        "name": "reason_to_reject_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "reason_to_rejectEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "reason_to_reject",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v6/*: any*/),
                  (v23/*: any*/),
                  (v24/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "reason_to_reject_connection(order_by:{\"reason\":\"asc\"})"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "VerifyDrugtestQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "user_orientationConnection",
        "kind": "LinkedField",
        "name": "user_orientation_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "user_orientationEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "user_orientation",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "worker_drug_testConnection",
        "kind": "LinkedField",
        "name": "worker_drug_test_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "worker_drug_testEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "worker_drug_test",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v25/*: any*/),
                  (v26/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "certificates_to_verifyConnection",
        "kind": "LinkedField",
        "name": "certificates_to_verify_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "certificates_to_verifyEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "certificates_to_verify",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v25/*: any*/),
                  (v26/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "worker",
                    "kind": "LinkedField",
                    "name": "worker",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "user",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v11/*: any*/),
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "user",
                    "kind": "LinkedField",
                    "name": "uploaded_by_user",
                    "plural": false,
                    "selections": [
                      (v13/*: any*/),
                      (v11/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v14/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "image",
                    "kind": "LinkedField",
                    "name": "front_image",
                    "plural": false,
                    "selections": (v27/*: any*/),
                    "storageKey": null
                  },
                  (v19/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "image",
                    "kind": "LinkedField",
                    "name": "back_image",
                    "plural": false,
                    "selections": (v27/*: any*/),
                    "storageKey": null
                  },
                  (v20/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "project",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/),
                      (v21/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v22/*: any*/),
        "concreteType": "reason_to_rejectConnection",
        "kind": "LinkedField",
        "name": "reason_to_reject_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "reason_to_rejectEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "reason_to_reject",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v25/*: any*/),
                  (v26/*: any*/),
                  (v23/*: any*/),
                  (v24/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "reason_to_reject_connection(order_by:{\"reason\":\"asc\"})"
      }
    ]
  },
  "params": {
    "cacheID": "1c8b00e1f5b9800f75bf48c6288efa76",
    "id": null,
    "metadata": {},
    "name": "VerifyDrugtestQuery",
    "operationKind": "query",
    "text": "query VerifyDrugtestQuery(\n  $where: certificates_to_verify_bool_exp!\n  $certId: uuid!\n  $userOrientationWhere: user_orientation_bool_exp!\n) {\n  user_orientation_connection(where: $userOrientationWhere, first: 1) {\n    edges {\n      node {\n        drug_test_id\n        id\n      }\n    }\n  }\n  worker_drug_test_connection(where: {verification_id: {_eq: $certId}}) {\n    edges {\n      node {\n        pk: id\n        id\n      }\n    }\n  }\n  certificates_to_verify_connection(where: $where) {\n    edges {\n      node {\n        id\n        pk: id\n        worker_id\n        worker {\n          subcontractor_id\n          user {\n            name\n            id\n          }\n          id\n        }\n        uploaded_by_uid\n        uploaded_by_user {\n          role\n          name\n          id\n        }\n        front_image_id\n        front_image {\n          url\n          md_url\n          lg_url\n          id\n        }\n        back_image_id\n        back_image {\n          url\n          md_url\n          lg_url\n          id\n        }\n        project_id\n        project {\n          name\n          drugtest_validity_days\n          id\n        }\n      }\n    }\n  }\n  reason_to_reject_connection(order_by: {reason: asc}) {\n    edges {\n      node {\n        id\n        pk: id\n        reason\n        type\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "29089448b91091643d6b90b78577a5d8";

export default node;
