import { DownloadOutlined } from "@ant-design/icons";
import { Button, Drawer, Form } from "antd";
import dayjs from "dayjs";
import { useMemo } from "react";
import FModal from "src/common/components/dialogs/FModal";
import downloadFromUrl from "src/common/functions/downloadFromUrl";
import getNormalSelectOptionsFilter from "src/common/functions/getNormalSelectOptionsFilter";
import useSelectedIndicies from "src/common/hooks/useSelectedIndices";
import {
  GetIncidentReportAndChartOptionsQuery,
  useGenerateIncidentsSummaryPdfMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { OrganizationLevelName } from "src/root/routes/views/general-contractor/hierarchy/utils/hierarchyTypes";
import IncidentReportFields, {
  IncidentReportFieldsFormVals,
} from "./IncidentReportFields";

interface FormVals extends IncidentReportFieldsFormVals {
  projectIds: Array<string>;
  subIds: Array<string>;
  endDate?: dayjs.Dayjs;
  startDate?: dayjs.Dayjs;
  // includeShortSummary: boolean;
}
const IncidentReportCreator: React.FC<{
  reportModal: boolean;
  organizationId: string;
  organizationLevel: OrganizationLevelName;
  handleClose: () => void;
  options: GetIncidentReportAndChartOptionsQuery;
}> = ({
  reportModal,
  handleClose,
  options,
  organizationId,
  organizationLevel,
}) => {
  const [selectedProjects, handleProjectSelection] =
    useSelectedIndicies<string>();

  const [generatIncidentsSummaryPdf, { loading }] =
    useGenerateIncidentsSummaryPdfMutation();
  const projectOptions = useMemo(
    () => options?.project.map((p) => ({ label: p.name, value: p.id })) || [],
    [options?.project],
  );

  const incidentTypeOptions = useMemo(
    () =>
      options?.incident_type.map((p) => ({
        label: p.translation.en,
        value: p.value,
      })) || [],
    [options?.incident_type],
  );

  const injuryTypeOptions = useMemo(
    () =>
      options?.injury_type.map((p) => ({
        label: p.translation.en,
        value: p.value,
      })) || [],
    [options?.injury_type],
  );

  const injurySeverityOptions = useMemo(
    () =>
      options?.incident_severity
        .filter((s) => s.severity_type === "injury")
        .map((p) => ({
          label: p.name.en,
          value: p.id,
        })) || [],
    [options?.incident_severity],
  );
  const injuryCauseOptions = useMemo(
    () =>
      options?.injury_cause.map((p) => ({
        label: p.translation.en,
        value: p.value,
      })) || [],
    [options?.injury_cause],
  );
  const bodyPartOptions = useMemo(
    () =>
      options?.injury_body_part_affected.map((p) => ({
        label: p.translation.en,
        value: p.value,
      })) || [],
    [options?.injury_body_part_affected],
  );
  const [form] = Form.useForm<FormVals>();
  const subOptions = useMemo(
    () =>
      (selectedProjects.size === 0
        ? options.subcontractor
        : options.subcontractor.filter((sub) =>
            sub.subcontractor_projects.some((ps) =>
              selectedProjects.has(ps.project_id),
            ),
          )
      ).map((sub) => ({ label: sub.name, value: sub.id })),
    [selectedProjects, options.subcontractor],
  );
  const handleDownload = async () => {
    const vals = await form.validateFields();
    const projectIds = vals.projectIds.length
      ? vals.projectIds
      : options.project.map((p) => p.id);
    const timezone = dayjs.tz.guess() || "America/New_York";
    const startTime = vals.startDate
      ? dayjs.tz(vals.startDate.format("YYYY-MM-DD"), timezone).toISOString()
      : null;
    const endTime = vals.endDate
      ? dayjs.tz(vals.endDate.format("YYYY-MM-DD"), timezone).toISOString()
      : null;
    const { data: res } = await generatIncidentsSummaryPdf({
      variables: {
        input: {
          projectIds,
          organizationId,
          organizationLevel,
          timezone: dayjs.tz.guess(),
          subcontractorIds: vals.subIds || [],
          incidentTypes: vals.incident_types || [],
          bodyPartsAffected: vals.body_parts_affected || [],
          injurySeverityIds: vals.injury_severities || [],
          injuryCauses: vals.injury_causes || [],
          injuryTypes: vals.injury_types || [],
          endTime,
          startTime,
          // includeShortSummaryList: vals.includeShortSummary,
        },
      },
    });
    if (res) downloadFromUrl(res.generateIncidentsSummaryPdf);
    handleClose();
  };
  const initialVals: FormVals = {
    projectIds: [],
    injury_causes: [],
    endDate: dayjs(),
    subIds: [],
    body_parts_affected: [],
    incident_statuses: [],
    incident_types: [],
    injury_severities: [],
    injury_types: [],
    // includeShortSummaryList: true,
  };
  return (
    <Drawer
      title="Report Creator"
      width={"60%"}
      open={reportModal}
      onClose={handleClose}
      destroyOnClose
      footer={
        <div className="flex gap-1">
          <Button loading={loading} onClick={handleClose}>
            Close
          </Button>
          <Button
            type={"primary"}
            loading={loading}
            icon={<DownloadOutlined />}
            onClick={handleDownload}
          >
            Download
          </Button>
        </div>
      }
    >
      <Form form={form} initialValues={initialVals}>
        <FModal.Select
          name={"projectIds"}
          label="Projects"
          className={projectOptions.length >= 2 ? undefined : "hidden"}
          props={{
            mode: "multiple",
            showSearch: true,
            filterOption: getNormalSelectOptionsFilter,
            placeholder: "All Projects Included",
            onSelect: (val) =>
              typeof val === "string" && handleProjectSelection(val),
            onDeselect: (val) =>
              typeof val === "string" && handleProjectSelection(val),
            options: projectOptions,
          }}
        />
        <FModal.Select
          name={"subIds"}
          label="Company"
          props={{
            mode: "multiple",
            placeholder: "All Companies Included",
            showSearch: true,
            filterOption: getNormalSelectOptionsFilter,
            options: subOptions,
          }}
        />
        <IncidentReportFields
          incidentTypeOptions={incidentTypeOptions}
          injuryTypeOptions={incidentTypeOptions}
          injurySeverityOptions={incidentTypeOptions}
          injuryCauseOptions={incidentTypeOptions}
          bodyPartOptions={incidentTypeOptions}
        />
        <FModal.Date name="startDate" label="Date Range Start"></FModal.Date>
        <FModal.Date name="endDate" label="Date Range End"></FModal.Date>
        {/* <FModal.Checkbox name="includeShortSummary">
          Include Short Summary List
        </FModal.Checkbox>{" "} */}
      </Form>
    </Drawer>
  );
};
export default IncidentReportCreator;
