import { Navigate, Outlet, useParams } from "react-router-dom";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import CustomTabsRouted, {
  CustomTabsRoutedProps,
} from "src/common/components/layouts/tabs/CustomTabsRouted";

const GCIncidentTabs = () => {
  const { projectId } = useParams();
  const basePath = projectId
    ? `/gce/projects/${projectId}/incidents`
    : `/gce/corporate/incidents`;
  const tabs: CustomTabsRoutedProps["tabs"] = [
    {
      path: "charts",
      label: "Overview",
    },
    {
      path: "table",
      label: "Incident Log",
    },
  ];
  return (
    <BasicWrapper scrollable>
      <CustomTabsRouted tabs={tabs} basePath={basePath} />
    </BasicWrapper>
  );
};
export default GCIncidentTabs;
