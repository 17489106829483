/**
 * @generated SignedSource<<562eb6dcc9160863fa972ebe9d4a5ae3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { email_project_user_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SiteOrientationSettingSwitchCards_Delete_Quiz_Failure_Mutation$variables = {
  objects: ReadonlyArray<email_project_user_insert_input>;
  projectId: string;
  toBeDeletedUserIds: ReadonlyArray<string>;
};
export type SiteOrientationSettingSwitchCards_Delete_Quiz_Failure_Mutation$data = {
  readonly delete_email_project_user: {
    readonly returning: ReadonlyArray<{
      readonly id: string;
      readonly user: {
        readonly name: string;
      };
      readonly user_id: string;
    }>;
  } | null | undefined;
  readonly insert_email_project_user: {
    readonly returning: ReadonlyArray<{
      readonly id: string;
      readonly user: {
        readonly name: string;
      };
      readonly user_id: string;
    }>;
  } | null | undefined;
};
export type SiteOrientationSettingSwitchCards_Delete_Quiz_Failure_Mutation = {
  response: SiteOrientationSettingSwitchCards_Delete_Quiz_Failure_Mutation$data;
  variables: SiteOrientationSettingSwitchCards_Delete_Quiz_Failure_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "objects"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "toBeDeletedUserIds"
  }
],
v1 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "projectId"
          }
        ],
        "kind": "ObjectValue",
        "name": "project_id"
      },
      {
        "kind": "Literal",
        "name": "type",
        "value": {
          "_eq": "quiz_failure"
        }
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_in",
            "variableName": "toBeDeletedUserIds"
          }
        ],
        "kind": "ObjectValue",
        "name": "user_id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "user_id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "email_project_user",
    "kind": "LinkedField",
    "name": "returning",
    "plural": true,
    "selections": [
      (v2/*: any*/),
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v6 = [
  {
    "kind": "Variable",
    "name": "objects",
    "variableName": "objects"
  }
],
v7 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "email_project_user",
    "kind": "LinkedField",
    "name": "returning",
    "plural": true,
    "selections": [
      (v2/*: any*/),
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SiteOrientationSettingSwitchCards_Delete_Quiz_Failure_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "email_project_user_mutation_response",
        "kind": "LinkedField",
        "name": "delete_email_project_user",
        "plural": false,
        "selections": (v5/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "email_project_user_mutation_response",
        "kind": "LinkedField",
        "name": "insert_email_project_user",
        "plural": false,
        "selections": (v5/*: any*/),
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SiteOrientationSettingSwitchCards_Delete_Quiz_Failure_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "email_project_user_mutation_response",
        "kind": "LinkedField",
        "name": "delete_email_project_user",
        "plural": false,
        "selections": (v7/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "email_project_user_mutation_response",
        "kind": "LinkedField",
        "name": "insert_email_project_user",
        "plural": false,
        "selections": (v7/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "98c16f4f96879a6e7d9a4b7c1e4ae7c6",
    "id": null,
    "metadata": {},
    "name": "SiteOrientationSettingSwitchCards_Delete_Quiz_Failure_Mutation",
    "operationKind": "mutation",
    "text": "mutation SiteOrientationSettingSwitchCards_Delete_Quiz_Failure_Mutation(\n  $objects: [email_project_user_insert_input!]!\n  $projectId: uuid!\n  $toBeDeletedUserIds: [uuid!]!\n) {\n  delete_email_project_user(where: {project_id: {_eq: $projectId}, type: {_eq: \"quiz_failure\"}, user_id: {_in: $toBeDeletedUserIds}}) {\n    returning {\n      user_id\n      id\n      user {\n        name\n        id\n      }\n    }\n  }\n  insert_email_project_user(objects: $objects) {\n    returning {\n      user_id\n      id\n      user {\n        name\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a81d1642812763079ee4517941856e42";

export default node;
