import { graphql } from "babel-plugin-relay/macro";
import React, { forwardRef, useState, useEffect } from "react";
import capitalize from "src/common/functions/capitalize";
import { AnonProjectWorkerTableQuery } from "src/common/types/generated/relay/AnonProjectWorkerTableQuery.graphql";
import { AnonProjectWorkerTable_UpdateProjWorkerTitle_Mutation } from "src/common/types/generated/relay/AnonProjectWorkerTable_UpdateProjWorkerTitle_Mutation.graphql";
import { AnonProjectWorkerTable_project_worker$data } from "src/common/types/generated/relay/AnonProjectWorkerTable_project_worker.graphql";

import DataScrollTable, {
  DataScrollTableImplementorProps,
  DataScrollTableProps,
  DataScrollTableRef,
} from "src/common/components/tables/basic/DataScrollTable";
import { IconCalendar } from "@tabler/icons";
import dayjs from "dayjs";
import Icon from "src/common/components/general/Icon";
import { Modal, Select } from "antd";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import GetFullID from "src/common/functions/GetFullId";
export type ColumnKeys =
  | "name"
  | "subcontractor"
  | "hardHatNumber"
  | "title"
  | "corporateDate"
  | "role"
  | "crew"
  | "project"
  | "registeredDate"
  | "extra";
const updateProjWorkerTItleMutation = graphql`
  mutation AnonProjectWorkerTable_UpdateProjWorkerTitle_Mutation(
    $pwId: uuid!
    $_set: project_worker_set_input!
  ) {
    update_project_worker_by_pk(pk_columns: { id: $pwId }, _set: $_set) {
      id
      title_id
    }
  }
`;
interface EditableTitleCellProps {
  value?: string | null;
  projWorkerId: string;
  titlesMap: Map<string, string>;
  workerTitleList?: { label: string; value: string }[];
}

export const EditableTitleCell: React.FC<EditableTitleCellProps> = ({
  projWorkerId,
  workerTitleList,
  titlesMap,
  value,
}) => {
  const [editing, setEditing] = useState(value ? false : true);
  const [updateProjectWorkerTitle] =
    useAsyncMutation<AnonProjectWorkerTable_UpdateProjWorkerTitle_Mutation>(
      updateProjWorkerTItleMutation,
    );

  const onSave = async (value: string) => {
    // Modal.confirm({
    //   title: "Are you sure?",
    //   content: "Confirm changing title for selected worker?",
    //   okText: "Yes",
    //   cancelText: "No",
    //   onCancel: () => setEditing(false),
    //   onOk: async () => {
    try {
      setEditing(false);
      await updateProjectWorkerTitle({
        variables: {
          pwId: projWorkerId,
          _set: { title_id: value },
        },
        optimisticResponse: {
          update_project_worker_by_pk: {
            id: GetFullID("project_worker", projWorkerId),
            title_id: value,
          },
        },
      });
    } catch (err) {
      setEditing(true);
      throw err;
    }
    //   },
    // });
  };

  if (editing) {
    return (
      <Select
        className="w-full min-w-12"
        autoFocus
        defaultOpen={!!value}
        options={workerTitleList}
        defaultValue={value}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        onSelect={onSave}
      />
    );
  }
  return (
    <div
      className="mt-0.25 editable-cell-value-wrap"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        setEditing(true);
      }}
    >
      {value ? titlesMap.get(value) : ""}
    </div>
  );
};
const CONNECTION_NAME = "project_worker_connection";
export type DConnection =
  AnonProjectWorkerTable_project_worker$data[typeof CONNECTION_NAME];

type Props = DataScrollTableImplementorProps<
  DConnection,
  ColumnKeys,
  AnonProjectWorkerTableQuery,
  { pk: string }
> & {
  searchString?: string;
  extraColumns?: DataScrollTableProps<
    DConnection,
    ColumnKeys,
    AnonProjectWorkerTableQuery
  >["columns"];
  headerComponent?: React.ReactElement;
  datePickerDataTitle?: string;
  datePickerDataIndex?: string[];
  gcId?: string;
  editableTitle?: boolean;
  workerTitleList?: { label: string; value: string }[];
  projectsOptions?: Array<{ id: string; name: string }>;
  defaultSortColumnKey?: ColumnKeys;
};

// declare getSuffix to avoid depricated substr

const AnonProjectWorkerTable = forwardRef<DataScrollTableRef, Props>(
  (
    {
      title = "Workers",
      onRowClick,
      searchString,
      datePickerDataTitle,
      datePickerDataIndex,
      headerComponent,
      extraColumns,
      where,
      defaultSortColumnKey,
      ...props
    },
    ref,
  ) => {
    const titlesMap = props.workerTitleList
      ? new Map(props.workerTitleList.map((t) => [t.value, t.label]))
      : new Map<string, string>();
    return (
      <DataScrollTable<DConnection, ColumnKeys, AnonProjectWorkerTableQuery>
        {...props}
        newCustomTableLook
        ref={ref}
        title={title}
        onRowClick={
          onRowClick
            ? ({ pk }) => {
                onRowClick({ pk });
              }
            : undefined
        }
        extraQueryVariables={{ gcId: props.gcId }}
        where={{ ...where }}
        connectionName={CONNECTION_NAME}
        filterNotVisibleByDefault
        headerComponent={headerComponent}
        totalCountConnectionName={"allProjectWorkersConnection"}
        datePickerDataIndex={datePickerDataIndex}
        datePickerDataTitle={datePickerDataTitle}
        columns={[
          {
            title: "Name",
            key: "name",
            size: "md",
            sortable: true,
            contentType: {
              type: "row-title",
            },
            defaultSortOrder:
              defaultSortColumnKey === undefined ||
              defaultSortColumnKey === "name"
                ? "asc"
                : undefined,
            dataIndex: ["user", "name"],
            searchDataIndex: ["user", "name"],

            render: (_, a) => {
              return <div className="flex">{a.user?.name}</div>;
            },
          },
          {
            title: "Project",
            key: "project",
            size: "md",
            dataIndex: ["project", "name"],
            ...(props.projectsOptions && props.projectsOptions.length > 0
              ? {
                  filters: {
                    dataIndex: ["project_id"],
                    options: props.projectsOptions.map((r) => ({
                      text: r.name,
                      value: r.id,
                    })),
                  },
                }
              : {
                  searchDataIndex: ["project", "name"],
                  includeInMainSearchBox: false,
                }),
          },
          {
            title: "Subcontractor",
            key: "subcontractor",
            size: "md",
            dataIndex: ["subcontractor", "name"],
            searchValue: searchString,
            includeInMainSearchBox: false,
          },
          {
            title: "Hard Hat #",
            key: "hardHatNumber",
            dataIndex: ["hard_hat_number"],
            searchDataIndex: ["hard_hat_number"],
            size: "sm",
            render: (a) => a ?? "",
          },
          {
            title: "Crew Name",
            key: "crew",
            queryIncludeVarKey: "includeCrew",
            dataIndex: ["project_crew", "name"],
            searchDataIndex: ["project_crew", "name"],
            size: "sm",
            render: (a) => a ?? "",
          },
          {
            title: "Title",
            key: "title",
            sortable: true,
            size: props.editableTitle ? "ml" : "sm",
            dataIndex: ["title", "translation", "en"],
            includeInMainSearchBox: false,
            render: (v, r) =>
              !props.editableTitle || !props.workerTitleList ? (
                v
              ) : (
                <EditableTitleCell
                  {...{
                    titlesMap,
                    workerTitleList: props.workerTitleList,
                    value: r.title_id,
                    initiallyOpen: false,
                    projWorkerId: r.pk,
                  }}
                />
              ),
          },
          {
            width: 150,
            title: "Role",
            key: "role",
            sortable: true,
            dataIndex: ["worker_role"],
            size: "sm",
            contentType: {
              type: "tag",
              renderOptions: (value) => ({
                label: capitalize(value),
              }),
            },
            filters: {
              dataIndex: ["worker_role"],
              options: ["apprentice", "foreman", "journeyman"].map((r) => ({
                text: capitalize(r),
                value: r,
              })),
            },
          },

          {
            sortable: true,
            title: "Registered Date",
            key: "registeredDate",
            size: "md",
            defaultSortOrder:
              defaultSortColumnKey === "registeredDate" ? "desc" : undefined,
            dataIndex: ["created_at"],
            // dateRangeSearchIndex: ["created_at"],
            contentType: {
              type: "date",
              renderOptions: () => ({
                format: "mmddyyyy",
              }),
            },
          },

          {
            title: "Corporate Video Date",
            key: "corporateDate",
            size: "sm",
            queryIncludeVarKey: "includeOrientation",
            dataIndex: ["user_orientation", "orientated_at"],

            render: (_, a) => {
              if (!a.user_orientation) return <>not complete</>;
              const corpOrient = a.user_orientation.corporateResults;
              let corporateCompleted: boolean | undefined = undefined;

              corpOrient.forEach((or) => {
                corporateCompleted =
                  corporateCompleted !== undefined
                    ? corporateCompleted &&
                      (!!or.completed_at || or.status === "completed")
                    : !!or.completed_at || or.status === "completed";
              });
              const date = corporateCompleted
                ? a.user_orientation.orientated_at ?? corpOrient[0].completed_at
                : undefined;

              return (
                <div className="flex">
                  {date === undefined ? (
                    "not complete"
                  ) : date === null ? (
                    "not logged"
                  ) : (
                    <>
                      <Icon icon={IconCalendar} />
                      {dayjs(date).format("M/DD/YYYY")}
                    </>
                  )}
                </div>
              );
            },
          },
          ...(extraColumns || []),
        ]}
        queryNode={graphql`
          query AnonProjectWorkerTableQuery(
            $first: Int!
            $after: String
            $where: project_worker_bool_exp!
            $order_by: [project_worker_order_by!]!
            $includeCrew: Boolean!
            $includeOrientation: Boolean!
            $gcId: uuid
          ) {
            ...AnonProjectWorkerTable_project_worker
              @arguments(
                first: $first
                after: $after
                where: $where
                order_by: $order_by
                includeCrew: $includeCrew
                includeOrientation: $includeOrientation
                gcId: $gcId
              )
            ...AnonProjectWorkerTable_total @arguments(where: $where)
          }
        `}
        totalCountNode={graphql`
          fragment AnonProjectWorkerTable_total on query_root
          @argumentDefinitions(where: { type: "project_worker_bool_exp!" })
          @refetchable(
            queryName: "AnonProjectWorkerTableTotalRefetchableQuery"
          ) {
            allProjectWorkersConnection: project_worker_connection(
              where: $where
            ) {
              edges {
                node {
                  id
                }
              }
            }
          }
        `}
        paginationNode={graphql`
          fragment AnonProjectWorkerTable_project_worker on query_root
          @argumentDefinitions(
            first: { type: "Int!" }
            after: { type: "String" }
            where: { type: "project_worker_bool_exp!" }
            order_by: { type: "[project_worker_order_by!]!" }
            includeCrew: { type: "Boolean!" }
            includeOrientation: { type: "Boolean!" }
            gcId: { type: "uuid" }
          )
          @refetchable(queryName: "AnonProjectWorkerTableRefetchableQuery") {
            project_worker_connection(
              first: $first
              after: $after
              where: $where
              order_by: $order_by
            )
              @connection(
                key: "AnonProjectWorkerTable_project_worker_connection"
                filters: ["where", "order_by"]
              ) {
              edges {
                node {
                  pk: id @__clientField(handle: "pk")
                  worker_role
                  hard_hat_number
                  created_at
                  title_id
                  worker_id
                  user {
                    name
                    email
                    role
                  }
                  project_crew @include(if: $includeCrew) {
                    name
                  }
                  project {
                    name
                  }
                  subcontractor {
                    name
                  }
                  title {
                    translation {
                      en
                    }
                  }
                  user_orientation @include(if: $includeOrientation) {
                    corporateResults: all_user_orientation_results(
                      where: {
                        orientation: {
                          type: { _eq: "corporate" }
                          general_contractor_id: { _eq: $gcId }
                        }
                        _or: [
                          { status: { _eq: "completed" } }
                          { completed_at: { _is_null: false } }
                        ]
                      }
                      order_by: [
                        { completed_at: asc_nulls_last }
                        { created_at: asc }
                      ]
                    ) {
                      completed_at
                      status
                    }
                    orientated_at
                  }
                }
              }
            }
          }
        `}
      />
    );
  },
);

export default AnonProjectWorkerTable;
