/**
 * @generated SignedSource<<423676a782fcde24207379ffebc765f5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { parent_child_project_subcontractor_bool_exp, parent_child_project_subcontractor_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ProjectSubChildSubs_ChangeChildSubs_Mutation$variables = {
  objects: ReadonlyArray<parent_child_project_subcontractor_insert_input>;
  toDeleteWhere: parent_child_project_subcontractor_bool_exp;
};
export type ProjectSubChildSubs_ChangeChildSubs_Mutation$data = {
  readonly delete_parent_child_project_subcontractor: {
    readonly affected_rows: number;
    readonly returning: ReadonlyArray<{
      readonly id: string;
    }>;
  } | null | undefined;
  readonly insert_parent_child_project_subcontractor: {
    readonly returning: ReadonlyArray<{
      readonly child_subcontractor: {
        readonly id: string;
        readonly name: string;
        readonly pk: string;
      };
      readonly id: string;
    }>;
  } | null | undefined;
};
export type ProjectSubChildSubs_ChangeChildSubs_Mutation = {
  response: ProjectSubChildSubs_ChangeChildSubs_Mutation$data;
  variables: ProjectSubChildSubs_ChangeChildSubs_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "objects"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "toDeleteWhere"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "where",
      "variableName": "toDeleteWhere"
    }
  ],
  "concreteType": "parent_child_project_subcontractor_mutation_response",
  "kind": "LinkedField",
  "name": "delete_parent_child_project_subcontractor",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "affected_rows",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "parent_child_project_subcontractor",
      "kind": "LinkedField",
      "name": "returning",
      "plural": true,
      "selections": [
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "objects",
    "variableName": "objects"
  },
  {
    "kind": "Literal",
    "name": "on_conflict",
    "value": {
      "constraint": "parent_child_project_subcontractor_parent_subcontractor_id_chil",
      "update_columns": ([]/*: any*/)
    }
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectSubChildSubs_ChangeChildSubs_Mutation",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "parent_child_project_subcontractor_mutation_response",
        "kind": "LinkedField",
        "name": "insert_parent_child_project_subcontractor",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "parent_child_project_subcontractor",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "subcontractor",
                "kind": "LinkedField",
                "name": "child_subcontractor",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": "pk",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__id_pk",
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ProjectSubChildSubs_ChangeChildSubs_Mutation",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "parent_child_project_subcontractor_mutation_response",
        "kind": "LinkedField",
        "name": "insert_parent_child_project_subcontractor",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "parent_child_project_subcontractor",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "subcontractor",
                "kind": "LinkedField",
                "name": "child_subcontractor",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": "pk",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": "pk",
                    "args": null,
                    "filters": null,
                    "handle": "pk",
                    "key": "",
                    "kind": "ScalarHandle",
                    "name": "id"
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a6644b004fe90b9cfc3357679f01c7cb",
    "id": null,
    "metadata": {},
    "name": "ProjectSubChildSubs_ChangeChildSubs_Mutation",
    "operationKind": "mutation",
    "text": "mutation ProjectSubChildSubs_ChangeChildSubs_Mutation(\n  $toDeleteWhere: parent_child_project_subcontractor_bool_exp!\n  $objects: [parent_child_project_subcontractor_insert_input!]!\n) {\n  delete_parent_child_project_subcontractor(where: $toDeleteWhere) {\n    affected_rows\n    returning {\n      id\n    }\n  }\n  insert_parent_child_project_subcontractor(objects: $objects, on_conflict: {constraint: parent_child_project_subcontractor_parent_subcontractor_id_chil, update_columns: []}) {\n    returning {\n      id\n      child_subcontractor {\n        id\n        pk: id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ad80cec2fd672579c470d5df4f1759fb";

export default node;
