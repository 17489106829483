/**
 * @generated SignedSource<<0dfd0e6c08971f4fdf4f8f9c2e787cb8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { project_employee_bool_exp } from './types';
export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrientationProjectEmployeeTableTotalRefetchableQuery$variables = {
  where: project_employee_bool_exp;
};
export type OrientationProjectEmployeeTableTotalRefetchableQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"OrientationProjectEmployeeTable_total">;
};
export type OrientationProjectEmployeeTableTotalRefetchableQuery = {
  response: OrientationProjectEmployeeTableTotalRefetchableQuery$data;
  variables: OrientationProjectEmployeeTableTotalRefetchableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrientationProjectEmployeeTableTotalRefetchableQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "OrientationProjectEmployeeTable_total"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrientationProjectEmployeeTableTotalRefetchableQuery",
    "selections": [
      {
        "alias": "allProjectEmployeesConnection",
        "args": (v1/*: any*/),
        "concreteType": "project_employeeConnection",
        "kind": "LinkedField",
        "name": "project_employee_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_employeeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project_employee",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5431483b1ff87385cbd88d97e76e3beb",
    "id": null,
    "metadata": {},
    "name": "OrientationProjectEmployeeTableTotalRefetchableQuery",
    "operationKind": "query",
    "text": "query OrientationProjectEmployeeTableTotalRefetchableQuery(\n  $where: project_employee_bool_exp!\n) {\n  ...OrientationProjectEmployeeTable_total_3FC4Qo\n}\n\nfragment OrientationProjectEmployeeTable_total_3FC4Qo on query_root {\n  allProjectEmployeesConnection: project_employee_connection(where: $where) {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "351c22c154ce4e2827d4f2db09a8c6e8";

export default node;
