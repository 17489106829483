import React, { useState } from "react";
import { useParams } from "react-router-dom";
import TimePicker from "src/common/components/general/TimePicker";
import dayjs from "dayjs";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import * as uuid from "uuid";
import { ConnectionHandler } from "relay-runtime";
import { Button, Form, Select, Switch, Tag } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { MorningManPowerReportDownloadAutoMutation } from "src/common/types/generated/relay/MorningManPowerReportDownloadAutoMutation.graphql";
import getNormalSelectOptionsFilter from "src/common/functions/getNormalSelectOptionsFilter";
import compareTwoLists from "src/common/components/ComparingTwoLists";
import { GCProjectReportsDownloadQuery$data } from "src/common/types/generated/relay/GCProjectReportsDownloadQuery.graphql";
import { MorningManPowerReportDownloadAuto_UpdateProjSetting_Mutation } from "src/common/types/generated/relay/MorningManPowerReportDownloadAuto_UpdateProjSetting_Mutation.graphql";
import GetFullID from "src/common/functions/GetFullId";
import { TimePicker as TimePickerAntD } from "antd";

interface SelectEmailUserFormValues {
  userIds: Array<string>;
  email_time: dayjs.Dayjs;
  enableAutoEmail: boolean;
  includeAllSubs: boolean;
}

interface SelectEmailUserDefaultData {
  userId: string;
  emailProjectUserId: string;
  name?: string;
}

interface ProjectEmployeeData {
  value: string;
  key: string;
  label: string;
}

interface MorningManPowerreportProps {
  defaultUsers?: Array<SelectEmailUserDefaultData>;
  projectEmployee: Array<ProjectEmployeeData>;
  projectData: GCProjectReportsDownloadQuery$data["project_connection"]["edges"][number]["node"];
}
const updateProjSettingMutation = graphql`
  mutation MorningManPowerReportDownloadAuto_UpdateProjSetting_Mutation(
    $projectSettingSet: project_setting_set_input!
    $projectId: uuid!
  ) {
    update_project_setting_by_pk(
      _set: $projectSettingSet
      pk_columns: { project_id: $projectId }
    ) {
      id
      auto_email_mmr
      include_all_subs_mmr
    }
  }
`;
const morningManPowerReportDownloadAutoMutation = graphql`
  mutation MorningManPowerReportDownloadAutoMutation(
    $objects: [email_project_user_insert_input!]!
    $where: email_project_user_bool_exp!
    $projectId: uuid!
    $_set: project_set_input
  ) {
    insert_email_project_user(objects: $objects) {
      returning {
        id
        pk: id @__clientField(handle: "pk")
        user {
          id
          pk: id @__clientField(handle: "pk")
          name
          email
        }
      }
    }
    delete_email_project_user(where: $where) {
      returning {
        id
      }
    }
    update_project_by_pk(pk_columns: { id: $projectId }, _set: $_set) {
      morning_man_power_report_email_time
      timezone
    }
  }
`;

const MorningManPowerReportDownloadAuto: React.FC<
  MorningManPowerreportProps
> = ({ projectData, defaultUsers, projectEmployee }) => {
  const { projectId } = useParams();
  if (!projectId) {
    throw new Error("Project Id not found");
  }
  const [form] = Form.useForm<SelectEmailUserFormValues>();
  const [loading, setLoading] = useState(false);
  const projSetting = projectData.project_setting;
  if (!projSetting)
    throw new Error("project setting not found for the project");

  const [editing, setEditing] = useState(false);

  const [updateProjectSetting] =
    useAsyncMutation<MorningManPowerReportDownloadAuto_UpdateProjSetting_Mutation>(
      updateProjSettingMutation,
    );

  const [updateMorningManPowerSettings] =
    useAsyncMutation<MorningManPowerReportDownloadAutoMutation>(
      morningManPowerReportDownloadAutoMutation,
    );

  const confirmTimeAndUsers = async () => {
    const values = await form.validateFields().catch(() => null);
    if (!values) return;
    console.log(values);
    setLoading(true);
    try {
      const [toInsert, toDelete] = compareTwoLists(
        values.userIds,
        (defaultUsers || []).map((u) => u.userId),
      );
      const insertEmailProjectUsers = toInsert.map((userId) => ({
        id: uuid.v4(),
        project_id: projectId,
        user_id: userId,
        type: "morning_man_power_report",
      }));
      const time = values.email_time
        ? dayjs(values.email_time).format("HH:mm")
        : projectData.morning_man_power_report_email_time;
      await updateProjectSetting({
        variables: {
          projectId,
          projectSettingSet: {
            auto_email_mmr: values.enableAutoEmail,
            include_all_subs_mmr: values.includeAllSubs,
          },
        },
        optimisticResponse: {
          update_project_setting_by_pk: {
            id: GetFullID("project_setting", projectId),
            auto_email_mmr: values.enableAutoEmail,
            include_all_subs_mmr: values.includeAllSubs,
          },
        },
      });
      await updateMorningManPowerSettings({
        variables: {
          objects: insertEmailProjectUsers,
          projectId: projectId,
          where: {
            project_id: { _eq: projectId },
            type: { _eq: "morning_man_power_report" },
            user_id: { _in: toDelete },
          },
          _set: { morning_man_power_report_email_time: time },
        },
        updater: (store) => {
          const insertedEmailProjectUsers = store.getRootField(
            "insert_email_project_user",
          );

          const deletedEmailProjectUser = store.getRootField(
            "delete_email_project_user",
          );

          const conn = ConnectionHandler.getConnection(
            store.getRoot(),
            "MorningManPowerReport_email_project_user_connection",
          );

          if (conn) {
            insertedEmailProjectUsers
              .getLinkedRecords("returning")
              .forEach((c) => {
                const edge = store.create(uuid.v4(), "edge");
                edge.setLinkedRecord(c, "node");
                ConnectionHandler.insertEdgeAfter(conn, edge);
              });

            deletedEmailProjectUser
              .getLinkedRecords("returning")
              .forEach((c) => {
                ConnectionHandler.deleteNode(conn, c.getDataID());
              });
          }
        },
      });
      form.resetFields();
    } finally {
      setLoading(false);
    }
    setEditing(false);
  };
  const labelValList: [string, React.ReactNode][] = [
    ["Auto Email enabled", projSetting.auto_email_mmr ? "Yes" : "No"],
    [
      "Include contractors with zero Manpower",
      projSetting.include_all_subs_mmr ? "Yes" : "No",
    ],
    [
      "Send To",
      <>
        {(defaultUsers || []).map((user) => (
          <Tag key={user.userId}>{user.name}</Tag>
        ))}
      </>,
    ],
    ["Send Time", <Tag>{projectData.morning_man_power_report_email_time}</Tag>],
  ];
  return (
    <div className="flex flex-col gap-1">
      <div className="flex-col">
        {!editing ? (
          <div>
            {labelValList.map(([label, val]) => (
              <div key={label} className="mb-1">
                {label}: {val}
              </div>
            ))}
            <Button
              onClick={() => setEditing(true)}
              loading={false}
              type="primary"
            >
              Edit Time or Recipients
            </Button>
          </div>
        ) : (
          <Form
            form={form}
            onFinish={() => confirmTimeAndUsers()}
            name="form_in_modal"
            initialValues={{
              enableAutoEmail: projSetting.auto_email_mmr,
              includeAllSubs: projSetting.include_all_subs_mmr,
              userIds: (defaultUsers || []).map((user) => user.userId),
              email_time: dayjs(
                dayjs().format("YYYY-MM-DD") +
                  projectData.morning_man_power_report_email_time,
              ),
            }}
          >
            <Form.Item
              name="enableAutoEmail"
              label="Enable Auto Email"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              name="includeAllSubs"
              valuePropName="checked"
              label="Include contractors with zero Manpower"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              name="userIds"
              label={"Recipients to receive daily"}
              rules={[{ required: true, message: "Select users" }]}
            >
              <Select
                className="min-w-12"
                listItemHeight={10}
                mode="multiple"
                showSearch
                filterOption={getNormalSelectOptionsFilter}
                options={projectEmployee.map((employee) => ({
                  value: employee.value,
                  key: employee.key,
                  label: employee.label,
                }))}
              />
            </Form.Item>
            <Form.Item
              name="email_time"
              label={<div className="text-1">Choose Time to email Report</div>}
              valuePropName="value"
            >
              <TimePickerAntD
                allowClear={false}
                showNow={false}
                minuteStep={15}
                showSecond={false}
              />
            </Form.Item>
            <Button loading={loading} htmlType="submit" type="primary">
              Comfirm New Settings
            </Button>
          </Form>
        )}
      </div>
    </div>
  );
};
export default MorningManPowerReportDownloadAuto;
